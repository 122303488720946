.swipeCalendar
	text-align center
	margin-bottom 15px
	padding-top 15px
	.lbCh
		//-padding-right 40px
		margin-right 0
		.flCh
			border 1px solid lightgray
			border-radius 10px
			width 28px
			background lightgray
			box-shadow 0 0 0px 2px gray
			top 2px
			margin-left 5px
			transition 0.3s all ease
			position relative
			display inline-block
			cursor pointer
			&:after
				display none
			&:before
				width 12px
				border-radius 50%
				background gray
				content ''
				height 12px
				padding 0
				content ''
				display block
				left 0px
				position absolute
				transition 0.3s all ease
		:checked 
			& + .flCh
				box-shadow 0 0 0px 2px marine
				border 1px solid marine
				background marine
				&:before
					left 14px
					background white
			

.calendar
	width 100%
.boxCalendar
	box-sizing border-box
	padding 20px 10px
	box-shadow 0 0 10px rgba(black, 0.2)
.calendar-container
	margin-bottom 40px
	.color-event
		display inline-block
		vertical-align bottom
		padding-top 10px
		
		li
			color medgray
			display inline-block
			font-size 12px
			position relative
			padding-left 20px
			margin-left 12px
			// &:first-child
			// 	margin-left 0
			&.all
				margin-left 0
				padding-left 0
			&:before
				border-radius 10px
				content ''
				width 15px
				height 9px
				position absolute
				left 0
				vertical-align middle
			&.acad
				&:before
					background academC
			&.admin
				&:before
					background adminC
			&.art
				&:before
					background arteC
			&.aspir
				&:before
					background aspC
			&.inst
				&:before
					background instC
			&.camp
				&:before
					background campusC
			a
				color medgray
				position relative
				display block
				&:after
					content ''
					display block
					width 0
					position absolute
					height 2px
					top 100%
					left 0
					background red
				&:hover
					color red
				&.active
					color marine
					&:after
						width 100%
	
	.combo
		float right
	fieldset
		text-align center
	.frmField
		margin-top 30px
	.form-list
		margin-top 15px
	.msjError
		display none
		font-size 12px
		padding-left 20px
		text-align left
		margin-top 5px
		color red
	.customSelect.error 
		border-color red
		.customSelect-label
			color marine
		.customSelect-arrow
			color red
		& + .msjError
			display block




.eventsCalendar
	padding 100px 0 50px

	
	.headEvents
		font-size 0
		.subtitle
			display inline-block
			width 80%
			vertical-align top
		.frmMes
			width 20%
			max-width 200px
			display inline-block
			vertical-align top
			padding-top 10px

.calendarList
	overflow hidden
	margin-bottom 40px
	>li
		column(1/3, $cycle:3)
	
.cardCalendar
	position relative
	padding-top 42px
	&.open
		.title
			.btnMoreDetail
				transform rotate(0)
				right -3px
				bottom -2px

			
	.date
		width 85px
		height 85px
		background red
		border-radius 50%
		position absolute
		top 0
		left 0
		color white
		text-align center
		box-sizing border-box
		padding-top 18px
		strong
			display block
			font-size 46px
			font-family 'indivisaItalicLight'
			line-height 36px
		small
			font-size 13px
			margin 0
			font-family 'indivisaBold'
	.title
		position relative
		h3
			margin 0 0 45px
			font-size 18px
			line-height 24px
			position relative
			min-height 45px
			width 90%
			&:after
				content ''
				display block
				width 50px
				height 2px
				background red
				position absolute
				top 100%
				left 0
			a
				&:hover
					&:after
						display none
		.btnMoreDetail
			color red
			position absolute
			bottom 0
			right 0
			font-size 28px
			transform rotate(135deg)
			display none
			transition 0.6s all ease
			&:after
				display none

	.info
		min-height 270px
		background smoke
		box-sizing border-box
		padding 60px 30px 5px
	
	.resume
		p
			margin 5px 0
			font-size 16px
			color marine
			&:last-child
				margin 0
			a
				font-family 'indivisaRegular'
		.linkArrow
			color red
			display block
			width 40px
			text-align right
			margin 0 0 0 auto
			font-size 22px
			transition 0.6s all ease
			&:after
				display none
			&:hover
				transform translateX(10px)


.downloadCalendar
	margin-bottom 30px
	h4,
	ul
		display inline-block
		font-size 16px
		color marine
		margin 0
	ul
		li
			display inline
			border-right 1px solid marine
			padding 0 5px
			&:last-child
				border none

			a
				font-family 'indivisaRegular'

.fc .fc-toolbar > * > *
	float none
.fc-daygrid-event
	font-size 11px
	color white
	border none
	border-radius 15px !important
	display block
	margin 2px 0 !important
	box-sizing border-box
	padding 2px 10px 1px !important
	width 100% !important
	text-align left !important
	&:after
		display none
	&.acad
		background academC
	&.admin
		background adminC
	&.art
		background arteC
	&.aspir
		background aspC
	&.inst
		background instC
	&.camp
		background campusC
	&.default
		background none
		color gray
		padding-left 10px
		position relative
		&:before
			content ''
			border-radius 50%
			width 5px
			height 5px
			background red
			display block
			position absolute
			top 50%
			transform translateY(-50%)
			left 0
.fc-header-toolbar
	position relative
	padding 10px 0
	>div
		display inline-block
		width auto
		vertical-align middle
		margin 0 10px
		button
			position absolute
			top 0
			width 30px
			height 30px
			border none
			background white
			transition 0.6s all ease
			outline none
			&:after
				display block
				width 100%
				height 100%
				position absolute
				top 0
				font-size 24px
				left 0
				text-align center
				line-height 24px
				color red
				font-family 'lasalle'
			&:disabled
				&:after
					color medgray
				&:hover
					transform none !important
					cursor default

		&:first-child
			button
				left 15px
				&:after
					content '\e942'
				&:hover
					transform translateX(-15px)
		&:last-child
			button
				right 15px
				&:after
					content '\e929'
				&:hover
					transform translateX(15px)
	h2
		color marine
		font-size 18px
		text-transform capitalize
.fc-event-container
	.fc-event
		border none
		padding 3px 5px 1px 12px
		border-radius 30px
		font-size 12px
		background none
		color gray
		&.academico
			background academC 
			color white
		&.administrativo
			background adminC 
			color white
		&.arte
			background arteC 
			color white
		&.aspirantes
			background aspC 
			color white
		&.institucional
			background instC 
			color white
		&.campus
			background campusC 
			color white
		&.default
			background none 
			color gray
			position relative
			&:after
				content ''
				position absolute
				left 2px
				top 5px
				width 6px
				height 6px
				border-radius 50%
				background red

.fc-col-header-cell
	background none
	a
		text-transform uppercase
		font-size 16px
		color gray
		&::last-letter
			color pink

.fc-toolbar
	&.fc-header-toolbar
		position relative
	.fc-left
		position absolute
		left 0
		transition 0.6s all ease
		button
			box-shadow none
			background none
			border none
			outline none
			span
				&:after
					font-family 'lasalle'
					color red
					content '\e942'
					font-size 24px
		&:hover
			left -10px
	.fc-right 
		position absolute
		right 0
		transition 0.6s all ease
		button
			box-shadow none
			background none
			border none
			outline none
			span
				&:after
					font-family 'lasalle'
					color red
					content '\e929'
					font-size 24px
			&:hover
				margin-left 10px
		&:hover
			right -10px

.fc
	table,
	thead,
	tbody,
	tr,
	th,
	td
		border none
		box-sizing border-box
	.fc-scrollgrid-section-body
		>td
			background white
	.fc-day-other
		a
			color lightgray
	.fc-day-today
		a.fc-daygrid-day-number
			background marine
			color white
			width 24px
			text-align center
			line-height 26px
			margin-left auto
			margin-right 0
			height 24px
			border-radius 50%
			&.fc-daygrid-event
				padding 3px 10px 2px !important
				height auto
				line-height unset
	tbody
		td
			background smoke
			border 4px solid white
			height 70px
			box-sizing border-box
			padding 5px
			.fc-day-number
				margin 10px
				color marine
			&.fc-today
				background smoke
				border none
				.fc-day-number
					background marine
					border-radius 50%
					display inline-block
					width 20px
					height 20px
					color white
					text-align center
					line-height 22px
	tbody
		>td
			border none
	.fc-daygrid-day-number
		display block
		text-align right
		&:after
			display none
	.fc-widget-header
		td,
		th
			background white
			color lightgray
			text-transform uppercase
			padding-bottom 15px

.fc-center
	text-align center

.fc-scrollgrid-sync-table
	height 100% !important

.fc-agenda-slots td div,
.fc-agendaDay-view tr,
.fc-agendaWeek-view tr
	height 40px !important

@media (max-width tablet)
	.swipeCalendar
		text-align center
	.calendar-container 
		.combo
			float none
			width 100%
			max-width 300px
			margin 20px auto 0
	.fc
		.fc-day-today
			a
				text-overflow ellipsis
				&.fc-daygrid-event
					padding 3px 5px 2px !important
	
	.fc-daygrid-event
		padding 2px 5px 1px !important
	.fc-daygrid-event-harness
		overflow hidden
		white-space nowrap
	.form-list.third 
		>li
			&:last-child
				width 60%
	.frmField
		margin-top 0px !important

@media (max-width mobile)
	.form-list.third 
		>li
			display block
			width 100%
			&:last-child
				width 100%
	.frmField
		margin-top 10px !important