.templates
	display none

.masonryGrid
	overflow hidden
	&.notVisible
		visibility none
	.grid-item
		width 46%
		margin-right 3%
		margin-bottom 20px
	&.masonry-3x3
		.grid-item
			width 30%
			margin-right: 3%
			margin-bottom: 20px
	a.btn
		font-size initial
		display inline-block
		width initial
		&:before
			width 0
			display block
			z-index -1
		&:after
			display block
		&:hover
			&:before
				width 100%
.load-moreArrow
	text-align center
	font-size 16px
	margin 30px 0 50px
	a
		color oxford

.bgGray
	background smoke
	&.withPadding
		padding 50px 0

.masonryFilter
	padding-top 60px
	.container
		position relative
.filterRight
	position absolute
	top 10px
	right 20px
	.field
		border 1px solid marine
		border-radius 5px
		padding-left 10px
		>label
			display inline-block
			vertical-align middle
			font-family 'indivisaBold'
			color marine
	.customSelect
		width 150px
		border none
		display inline-block
		vertical-align middle


.cardMasonry
	position relative
	overflow hidden
	>a
		position relative
		display block
		width 100%
		height 100%
		font-size 0
		img
			transition 0.3s all ease
		&:before
			content ''
			width 100%
			height 100%
			left 0
			top 0
			background: linear-gradient(to bottom,  rgba(black,0.0) 65%,rgba(black,0.65) 100%)
			z-index 2
			position absolute
		&:after
			display none
		&:hover
			img
				transform scale(1.2)
	h3
		position absolute
		left 0px
		padding 0 20px
		bottom 20px
		margin 0
		z-index 3
		a
			color white
			font-size 24px
			line-height 34px
			.icon
				color red
				display inline-block
				margin-left 5px
				font-size 34px
				position absolute
				margin-top -2px
	h2.enfasis
		padding-bottom 0
	&.description
		padding 20px 0
		p
			color medGray	
@media (max-width mobile)
	.masonryGrid
		.grid-item
			width 100%
			margin-right 0
	.filterRight
		position static
		text-align center
		margin-bottom 30px