.coursesDiplomas 
	.online-consultation 
		margin 5.5rem auto
.studentGroups-detail 
	.fichaTecnica 
		margin 2.65rem 0 10.3rem
.fichaTecnica 
	position relative
	margin 2.65rem 0 6.3rem
	.imgBg 
		width 49%
		height 516px
		position absolute
		top 37px
		right 0
		font-size 0
		img 
			width 100%
			height 100%
			object-fit cover
	.container 
		position relative
		z-index 2
	.textBg 
		width 100%
		height 516px
		max-width 535px
		color white
		background marine
		padding 70px 30px 33px 0
		position relative
		top 0
		box-sizing border-box
		&:before 
			content ''
			display block
			width 100%
			height 100%
			background marine
			position absolute
			top 0
			right 100%
			margin-right -1px
		ul 
			li 
				margin-bottom 17px
		p 
			line-height 21px
			margin 0 0 1.3rem
		h4 
			font-size 16px
			line-height 21px
			margin 0
		.iconDescripcion 
			display inline-block
			width 40px
			height 40px
			background red 
			border-radius 50%
			position relative
			margin-right 27.5px
			vertical-align top
		.descripcion 
			display inline-block
			width 71%
			vertical-align top
		.icon 
			font-size 22px
			position absolute
			top 50%
			left 50%
			transform translate(-50%, -50%)
			&.danza-arabe 
				font-size 16px
.registrationSection 
	text-align center
	padding 0 12.5rem 6rem
	.btn 
		padding 15px 65px
	p 
		font-size 14px
		line-height 18px
		color gray
.listTemario 
	padding 1.5rem 0 3rem .7rem
	font-size 18px
	line-height 24px
	color darkmarine
	display grid
	grid-template-columns repeat(2, 1fr)
	grid-gap 0 3%
	li 
		position relative
		&:before 
			content ''
			display block
			width 4px
			height 4px
			background darkmarine
			border-radius 50%
			position absolute
			top 50%
			left -10px

.listInversion 
	max-width 895px
	text-align center
	padding 50px 0 6.5rem
	margin 0 auto
	display grid
	grid-template-columns repeat(2, 1fr)
	grid-gap 2rem 3%
	
.cardInversion
	p 
		font-size 14px
		line-height 18px
		color darkmarine
		margin 1.4rem 0
	strong 
		font-family indivisaBold
	.headerInversion 
		color white
		background marine
		padding 40px 44px
		height 185px
		display flex
		flex-flow column wrap
		justify-content center
		h3 
			font-size 48px
			line-height 30px
			color white
			display block
			margin 0
			strong 
				display block
				font-family indivisaRegular
				font-weight regular
				font-size 24px
				margin-bottom 20px
		p 
			color white
			margin 2rem 0 0
	.bodyIversion 
		padding 6px 30px 12px
		li 
			font-size 14px
			line-height 18px
			color darkmarine
			margin-bottom 0
	&.horizontal
		display flex
		>div
			flex 0.5
			padding 20px 30px
			
		.headerInversion
			text-align center
			display flex
			flex-flow column nowrap
			h3
				margin 0
				line-height 45px
		.bodyIversion
			background smoke
.leyenda 
	p 
		font-size 14px
		line-height 18px
		color #4D4D4D
.listRegistration 
	padding 3rem 0 1.5rem
	display grid
	grid-template-columns repeat(2, 1fr)
	grid-gap 1rem 5%
	li 
		margin-bottom 0
	.iconRegistration 
		display inline-block
		width 54px
		height 54px
		background red
		border-radius 50%
		position relative
		margin-right 21px
		vertical-align top
		.icon 
			font-size 31px
			color white
			position absolute
			top 50%
			left 50%
			transform translate(-50%, -50%)
	.descripcion 
		display inline-block
		width 81%
		vertical-align top
	p 
		font-size 18px
		line-height 24px
		margin 0 0 .8rem
		span 
			font-size 14px
.contactCard 
	position relative
	margin 5.7rem 0 9.2rem
	.imgBg 
		width 60.5%
		height 346px
		position absolute
		top 36px
		right 0
		font-size 0
		img 
			width 100%
			height 100%
			object-fit cover
	.container 
		position relative
		z-index 2
	.textBg 
		width 100%
		max-width 365px
		color white
		background marine
		padding 48px 40px 40px 0
		position relative
		box-sizing border-box
		&:before 
			content ''
			display block
			width 600px
			height 100%
			background marine
			position absolute
			top 0
			right 100%
			margin-right -1px
		li 
			margin-bottom 20px
		h3 
			font-size 24px
			line-height 30px
			margin 0 0 2.7rem
		p 
			line-height 21px
			margin 0
			strong 
				display block
				font-family indivisaBold
			a 
				font-family indivisaRegular
				color white
@media(max-width tablet-portrait) 
	.cardInversion 
		.headerInversion 
			padding 25px 10px 15px
			h3
				font-size 44px
				line-height 26px
				strong 
					font-size 22px
	.listRegistration 
		grid-gap 1rem 2.5%
@media(max-width tablet) 
	.fichaTecnica 
		margin 3.65rem 0 6.3rem
		.imgBg 
			width 100%
			height auto
			position relative
			top 0
		.textBg 
			margin-top -300px
	.registrationSection 
		padding 0 6rem 6rem
	.listTemario 
		padding 1.5rem 0 6rem 0.7rem
		grid-template-columns 100%
		grid-gap 0
	.listInversion 
		width 450px
		padding 50px 0 10rem
		margin 0 auto
		grid-template-columns 100%
		grid-gap 2rem
	.listRegistration 
		grid-template-columns 100%
		grid-gap 1.3rem
	.cardInversion
		&.horizontal 
			.headerInversion
				p
					margin-top 1rem
@media(max-width mobile) 
	.fichaTecnica 
		background marine
		.textBg 
			height auto
			padding 63px 0 33px
			margin-top 0
	.registrationSection 
		padding 0 0 6rem
	.listInversion 
		width 100%
	.cardInversion
		.headerInversion 
			padding 40px 10px 50px
			height auto
		&.horizontal
			flex-direction column
	.contactCard 
		background marine
		.imgBg 
			width 100%
			height auto
			position relative
			top 0
		.textBg 
			max-width 100%
			padding 48px 30px 40px 0
@media(max-width mobile-landscape) 
	.listRegistration 
		.iconRegistration 
			margin-bottom .5rem
@media(max-width mobile-landscape - 59) 
	.listInversion 
		padding 50px 0 7rem
	.cardInversion
		.headerInversion 
			h3 
				font-size 40px
				line-height 40px
				strong 
					font-size 20px
					margin-bottom 8px
		.bodyIversion 
			padding 6px 20px
	.listRegistration 
		text-align center
		.iconRegistration 
			width 48px
			height 48px
			display block
			margin 0 auto .5rem
			.icon 
				font-size 28px