*,
	*::after,
	*::before
	-webkit-box-sizing border-box
	box-sizing border-box

.postersC
	font-family 'indivisaReguular', sans-serif
	background #cecece
	//height 100vh
	height 100%
	overflow hidden
	position relative
	.hidden
		position absolute
		overflow hidden
		width 0
		height 0
		pointer-events none
	// .icon
	// 	display block
	// 	width 1.5em
	// 	height 1.5em
	// 	margin 0 auto
	// 	fill currentColor
	.boton
		position relative
		border-radius 0
		width 50px
		height 50px
		text-align center
		line-height 50px
		box-sizing border-box
		padding 0
		margin 0
		color #fff
		border none
		transition 0.3s all ease
		cursor pointer
		&:focus
			outline none
	.btn--nav-left
		background #969696
		&:hover
			background #6C6C6C
			transform translateX(-10px)
	.btn--nav-right
		background #D21034
		&:hover
			background #CC0529
			transform translateX(10px)
	.btn--nav
		font-size 2em
		pointer-events auto
		&:hover
			.nav__triangle
				transform translate3d(-54px, 0, 0)
			.nav__line
				transform translate3d(17px, 0, 0)
	.btn--info
		margin 0 2em 0 auto
	.btn--toggle
		&.btn--active
			z-index 100
			color #fff
			&:hover
				color #1f1f21
			.icon
				&:nth-child(2)
					display block
	.poster-container
		position relative
		overflow hidden
		width 100vw
		height 100vh
		perspective 2000px
		&:after
			content ''
			display block
			width 100%
			height 100%
			background rgba(255, 255, 255, 0.3)
			position absolute
			top 0
			left 0
	.scroller
		height 100%
		transform-style preserve-3d
	.room
		position absolute
		top 50%
		left 50%
		width 100vw
		height 100vh
		margin -50vh 0 0 -50vw
		pointer-events none
		opacity 0
		transform-style preserve-3d
	.room--current
		pointer-events auto
		opacity 1
	.room__side
		position absolute
		display flex
		justify-content center
		align-items center
		transform-style preserve-3d
	.room__side--back
		width 100vw
		height 100vh
		background #e9e9e9
		box-shadow 0 0 0 2px #e9e9e9
		transform translate3d(0, 0, -4000px) rotate3d(1, 0, 0, 0.1deg) rotate3d(1, 0, 0, 0deg)
	.room__side--right
		right 0
		justify-content flex-end
		transform rotate3d(0, 1, 0, -90.03deg)
		transform-origin 100% 50%
	.room__side--left
		justify-content flex-start
		transform rotate3d(0, 1, 0, 90deg)
		transform-origin 0 50%
	.room__side--bottom
		width 100vw
		height 4000px
		background #d0d0d0
		transform rotate3d(1, 0, 0, 90deg) translate3d(0, -4000px, 0)
		transform-origin 50% 0%
		top 100%
	.room__img
		flex none
		max-width 40%
		max-height 60%
		margin 0 5%
		transform translate3d(0, 0, 10px)
		backface-visibility hidden
	.content
		position absolute
		top 0
		left 0
		display flex
		flex-direction column
		width 100%
		//height 100vh
		height 100%
		padding 2vw
	.codrops-header
		display flex
		align-items center
	.codrops-header__title
		font-size 1.165em
		font-weight normal
		margin 0.5em 0 0 0
	.codrops-links
		position relative
		display flex
		justify-content space-between
		align-items center
		height 2.75em
		margin 0 2em 0 0
		text-align center
		white-space nowrap
		border 2px solid
		background #fff
		&::after
			content ''
			position absolute
			top -5%
			left calc(50% - 1px)
			width 2px
			height 110%
			background currentColor
			transform rotate3d(0, 0, 1, 22.5deg)
	.codrops-icon
		display inline-block
		padding 0 0.65em
	.subject
		font-size 1.5em
		margin 0 auto
		color #c2292e
	.location
		font-size 1.165em
		font-weight normal
		position absolute
		top 0
		right 0
		height 100%
		margin 0 1.5em
		padding 6em 0
		text-align center
		pointer-events none
		color #c2292e
		-webkit-writing-mode vertical-rl
		writing-mode vertical-rl
	.slides
		position relative
		flex 1
		//padding-top 80px
	.slide
		position absolute
		left 50%
		display flex
		flex-direction column
		justify-content center
		width 100%
		height 100%
		margin 0 auto
		pointer-events none
		opacity 0
		max-width 960px
		transform translate(-50%, 0%)
		padding-top 45px
	.slide--current
		pointer-events auto
		opacity 1
	.slide__name
		font-size 15vw
		line-height 0.8
		margin 0
		padding 0 0 0 5vw
		text-indent -5vw
		letter-spacing -0.05em
		text-transform lowercase
		color #fff
		position absolute
		width 50%
		img
			width 100%
			max-width auto
	.slide__title
		font-size 20px
		font-family 'indivisaBold'
		margin 0.75em 0 0 0
		color #001D68
		h3
			font-size 48px
		p
			font-size 24px
			line-height 32px
			font-family 'indivisaRegular'
	.slide__date
		font-size 1.5vw
		font-weight bold
		margin 1.15em 0 0 0
	.slide__number
		font-size 0.4em
		display inline-block
		margin 0.5em 0 0 0
		padding 0.4em 0.5em 0.25em 0.5em
		color #fff
		background #c2292e
	.poster-nav
		display flex
		justify-content space-between
		width 100%
		margin auto 0 0 0
		pointer-events none
		padding-top 20px
	.overlay
		//position fixed
		position absolute
		z-index 10
		top 0
		left 0
		display flex
		justify-content center
		width 100%
		height 100%
		pointer-events none
		opacity 0
		//padding-top 175px
	.overlay--loader
		z-index 1000
		background #fff
	.overlay--info
		background rgba(23, 94, 236, 0.4)
	.overlay--menu
		background rgba(9, 25, 65, 0.45)
		align-items center
		.container
			position relative
		.btn--close
			color white
			position absolute
			right 0
			width 40px
			height 40px
			font-size 0
			top -40px
			&:after
				display none
			&:before
				position absolute
				content '\e928'
				font-family 'lasalle'
				line-height 40px
				text-align center
				font-size 32px
	.poster-info-detail
		h2
			color #fff
			font-size 42px
			margin 0 0 40px
			font-family 'indivisaBold'
		p
			color #fff
			font-size 16px
			line-height 24px
			font-family 'indivisaRegular'
			strong
				font-family 'indivisaBold'
	.js
		.overlay--active
			pointer-events auto
			opacity 1
	.menu
		margin 0
		padding 0
		list-style none
	.menu__item
		font-size 7vh
		margin 0.25em 0
		padding 0
		text-transform lowercase
	.menu__item--current
		font-weight bold
	.menu__link
		color #fff
	.info
		color white
		font-size 1.5em
		line-height 1.4
		width 60vw
		min-width calc(320px - 2em)
		max-width 900px
		margin 0
		padding 1em
	.loader
		display flex
		div
			width 30px
			height 30px
			margin -30px 0.2em 0
			border 4px solid
			background #e9e9e9
			animation anim-loader 0.8s alternate infinite forwards
			animation-timing-function cubic-bezier(0.215, 0.61, 0.355, 1)
			&:nth-child(2)
				border-radius 50%
				background #c2292e
				animation-delay 0.15s
			&:nth-child(3)
				animation-delay 0.3s

html.js header,
	html.js .breadWrap,
	html.js footer
	position relative
	z-index 12

.postersC .nav__triangle,
	.postersC .nav__line
	transition transform 0.3s
	fill currentColor

.postersC .btn--toggle .icon:nth-child(2),
	.postersC .btn--toggle.btn--active .icon:first-child
	display none

.postersC .room__side--left,
	.postersC .room__side--right
	width 4000px
	height 100vh
	background #dbdbdb

.postersC .slide__title,
	.postersC .slide__date
	text-align left
	width 50%
	margin-left 50% !important

.list-poster-detail
	li
		display none
		&.active
			display block

.postersC .menu__link:focus,
	.postersC .menu__link:hover
	color #1f1f21

@keyframes anim-loader
	100%
		transform translate3d(0, 30px, 0) scale3d(0.9, 0.9, 1)

@media (max-width tablet)
	.postersC 
		.slide__title 
			h3
				font-size 44px
				margin 10px 0
			p
				font-size 20px
				line-height 28px


@media (max-width mobile)
	.postersC 
		.slide__title,
		.slide__date
			width 100%
			margin-left 0 !important
		.slide__title 
			h3
				font-size 28px
				margin 10px auto
				width 70%
				
				text-align center
			p
				//display none
				font-size 18px
				line-height 21px
				width 70%
				text-align center
				margin 10px auto !important
				
		.slide__date
			margin 10px auto
			text-align center

		.slide__name
			width 80%
			margin 10px auto
			position relative
			top 10px
		.boton
			width 40px
			height 40px
			line-height 40px
			.icon
				width 40px
				height 40px
				line-height 40px
				margin 0

@media screen and (max-width: 50em)
	.postersC
		.subject
			display none

@media screen and (max-width: 50em)
	.postersC
		.codrops-links
			margin 0 0.5em 0 0
		.btn--info
			margin-right 1.25em
		.location
			font-size 0.85em
			position relative
			height auto
			margin 1em 0 0 0
			padding 0
			text-align left
			-webkit-writing-mode horizontal-tb
			writing-mode horizontal-tb
		.slide
			width 100%
		.slide__name
			padding 0 0 0 12vw
		.slide__title
			font-size 1.5em
		.slide__date
			font-size 0.65em
		.nav
			position absolute
			top 7em
			left 0
			width 100%
			padding 1em
		.nav__triangle
			transform translate3d(-54px, 0, 0)
		.nav__line
			transform translate3d(17px, 0, 0)
		.btn--nav
			&:hover
				color currentColor
		.info
			font-size 0.95em
			width 100vw

@media (max-width 375px)
	.postersC 
		.slide__title
			h3
				font-size 24px
			p
				font-size 16px
				line-height 20px
				width 95%