.tabsContent 
	.titulacionPerfiles 
		h4 
			margin 2rem 0 1.8rem !important
		p 
			strong 
				font-style normal !important
		.download-list 
			a
				background smoke
				&.circled
					&:before 
						color marine
						background lightgray
					&:hover 
						background red
						color white