@-webkit-keyframes rebota 
	100% 
		top 35% 
.titleNota 
	font-size 36px
	line-height 45px
	color marine
	padding-right 3rem
	margin 0
	span 
		display table
		font-family indivisaItalicLight
		font-size 16px
		line-height 24px
		color white
		text-align center
		padding 1px 11px
		border-radius 18px
		margin-bottom 15px
		&.blue 
			background blue
		&.green 
			background green 
		&.orange 
			background orange
		&.red 
			background red
	.icon 
		&.marcador 
			font-size 14px
.detail-topics-interest 
	.main 
		padding 0 0 50px
	.date-publication 
		p 
			font-size 18px
	.info-topic 
		margin-bottom 3rem
.full-img 
	width 100%
	height 380px
	position relative
	padding 0
	margin 0 0 3.4rem
	overflow hidden
	box-sizing border-box
	img 
		width 100%
		height 100%
		object-fit cover
.socialMedia-list 
	margin 2.7rem 0
	&.noTop
		margin 0.7rem 0 2.7rem
	p 
		font-size 12px
		line-height 26px
		color medgray
		margin 0
	ul 
		overflow hidden
		height 70px
		li 
			width 45px
			height 45px
			background darkmarine
			border-radius 50%
			float left
			margin-right 14px
			margin-bottom 0
			position relative
			// bottom -15px
			top 50%
			transform translate(0, -50%)
			&:hover 
				animation rebota .5s alternate infinite ease-out
			a 
				display block
				width 100%
				height 100%
				font-size 24px
				color white
				position relative
				&:after 
					display none
				i 
					position absolute
					top 50%
					left 50%
					transform translate(-50%, -50%)
.btnCopyLink
	position relative
	.copied
		position absolute
		left 100%
		top 50%
		transform translateY(-50%)
		background red
		border-radius 4px
		display inline-block
		margin-left 15px
		padding 5px 10px
		font-size 14px
		font-family 'indivisaRegular'
		&:after
			content ''
			border 6px solid transparent
			border-right-color red
			top 50%
			transform translateY(-50%)
			right 100%
			display block
			position absolute
.socialMediaVertical-list 
	text-align center
	p 
		font-size 12px
		line-height 26px
		color #b7b7b7
		margin 0 0 28px
	li 
		width 45px
		height 45px
		background darkmarine
		border-radius 50%
		margin 0 auto 15px
		transition 0.3s all ease
		&:hover 
			transform scale(1.2)
		a 
			display block
			width 100%
			height 100%
			font-size 24px
			color #fff
			position relative
			i 
				position absolute
				top 50%
				left 50%
				transform translate(-50%, -50%)
			&:after 
				display none
.info-topic 
	padding-right 3rem
	h3 
		font-size 24px
		line-height 30px
		color darkmarine
		margin 0
	h4
		font-size 16px 
		line-height 30px
		color darkmarine
	h6 
		font-weight regular
		font-size 18px
		line-height 24px
		color darkmarine
		margin 1.4rem 0
		a 
			font-family indivisaRegular
			color lightBlue
	p 
		line-height 30px
		color darkmarine
		margin 1.4rem 0
		a 
			color #0191c6
		em 
			display block
			font-weight bold
			line-height 30px
			color darkmarine
	hr 
		border-top 2px solid #e98698
	&.registration 
		h3 
			padding-top 6rem
		.leyenda 
			padding-bottom 5rem
	&.temario 
		margin-bottom 5.5rem
		h3 
			padding-top 3rem 
		
.block-backgroundSmoke 
	background smoke
.slider-relatedPublications
	padding 20px 0
	.slick-list 
		width 2000px
	.slick-slide 
		margin 0 15px
	.item 
		.description 
			h2
				min-height 75px
@media(max-width tablet-portrait)
	.titleNota 
		padding-right 0
	.info-topic 
		padding-right 0

@media(max-width mobile) 
	.full-img
		margin 0 0 2.4rem
		height auto
		font-size 0
		img
			height auto
			object-fit unset 
	.titleNota 
		font-size 32px
		line-height 38px
	.slider-relatedPublications 
		.item 
			max-width 280px
			.description 
				h2
					min-height 85px
	.socialMedia-list 
		ul 
			height auto
			li 
				margin-bottom 12px
				top unset
				transform translate(0, 0)
	.socialMediaVertical-list 
		text-align left
		li 
			float left
			margin-right 14px
		p 
			margin 0 0 10px
@media(max-width 375px)
	.socialMedia-list 
		ul 
			li
				width 40px
				height 40px

@media(max-width mobile-portrait)
	.socialMedia-list 
		ul 
			li
				width 35px
				height 35px
				margin-right 10px
@media(max-width mobile-portrait) 
	.slider-relatedPublications 
		.item 
			.description 
				h2
					min-height auto