.content-img-full 
	.img 
		height 350px
		margin 1.5rem 0 0
		position relative
		img 
			display block
			width 100%
			height 100%
			object-fit cover
			margin 0 auto
		h1 
			position absolute
			clip rect(0, 0, 0, 0)
.tabs-faculty 
	margin 2rem 0 
	.titles-tabs 
		.menuMobile  
			font-family indivisaSerif
			font-size 13px
			color marine
			border 2px solid medgray
			border-left-color red
			padding 10px 30px 10px 10px
			display block
			position relative
			display none
			&:after 
				transition 0.6s all ease
				content '\e902'
				color #d21034
				position absolute
				top 50%
				right 5px
				font-family 'lasalle'
				transform translateY(-50%)
				font-size 20px;
		ul 
			li 
				display inline-block
				padding-right 58px
				h2 
					line-height 0
					margin 0
				a  
					font-family indivisaItalicLight
					font-weight regular
					font-size 22px
					line-height 57px
					color lightgray
				&:hover 
					a 
						color marine
				&.active 
					a 
						color marine
						&:after 
							width 60%
	.info-tab 
		.content-tab 
			display none
			&:first-child 
				display block
			p 
				line-height 24px
				color marine
			h4 
				font-family indivisaRegular
				font-size 16px
				line-height 24px
				margin 1rem 0
				strong 
					font-style italic
					color darkmarine
			h5 
				font-family indivisaBold
				font-size 16px
				line-height 24px
				color darkmarine
				margin 1rem 0 1.3rem
			ol, ul 
				padding 0
				counter-reset li	// resetea la lista
				list-style none
				margin-bottom 3.5rem
				li 
					font-size 16px 
					line-height 24px
					color marine
					// agregamos un before 
					&:before 
						counter-increment li  // especificamos que elemento se ira incrementando
						content '' counter(li) '. '  // agregamos el contenido segido del contador
						font-family indivisaBold
						font-size 16px
						line-height 24px
						color red

@media(max-width tablet) 
	.tabs-faculty 
		.titles-tabs 
			ul 
				li
					padding-right 38px
@media(max-width mobile) 
	.content-img-full 
		.img 
			height auto
	.tabs-faculty 
		.titles-tabs 
			.menuMobile 
				display block
				&.open 
					+ ul 
						height auto
						border 2px solid medgray
						border-top none
					&:after 
						transform translateY(-50%) rotate(180deg)
			ul 
				border none
				height 0
				overflow hidden
				li 
					display block
					padding-right 0
					margin-bottom 0
					a 
						display block
						border none
						border-bottom 1px solid #969696
						padding 10px
						font-size 13px
						line-height initial
						&:after 
							display none