.tabs.lateralTabs.socialTabs
	margin-top 0
	display none
.sticky-container
	height 58px
	.sticky-Navbar
		top 0
		z-index 100
		transition all .3s ease-in
		background-color white
		height 58px
		ul
			display flex
			justify-content space-evenly
			li
				margin 20px 0
				a
					font-family 'indivisaRegular'
				&.active 
					a 
						&:after
							width 60%
.toFixed
	position fixed
	background-color rgb(248, 247, 247)
	transition: all .3s ease-in
	width 100%
	animation altura .5s ease-in both
.tabFijo-que-es
	position relative
	.imgWithText-der
		.textBg
			padding 80px 14.5rem 80px 0px
.tabFijo-que-necesitas
	position relative
	margin-bottom 180px
	h2
		color white
		font-size 30px
		line-height 36px
	li
		position relative
		display flex
		align-items center
		min-height 50px
		padding-left 70px
		p
			color white
			margin 0
			font-size 16px 
	.imgBg
		left 0
		width 50%
		height 100%
		position absolute
		font-size 0
		overflow hidden
		top 50px
		img
			width 100%
			height 100%
			object-fit cover
	.textBlue
		background marine
		width 50%
		-webkit-box-sizing border-box
		box-sizing border-box
		padding 70px 20px 60px 20px
		position relative
		z-index 2
		top 50px
		margin-right 0
		margin-left auto
		top 0
		&::before
			content ''
			display block
			width 50px
			height 100%
			position absolute
			top 0
			background marine
			right 100%
		&::after
			content ''
			display block
			width 600px
			height 100%
			position absolute
			top 0
			background marine
			left 100%
	.icon
		position absolute
		width 50px
		height 50px
		text-align center
		line-height 50px
		font-size 32px
		color #fff
		background red
		border-radius 50%
		top 50%
		transform translateY(-50%)
		left 0
.tabFijo-informacion
	position relative
	margin 100px 0 110px 0
	h2
		margin 60px 0
	h3
		color marine
		margin-bottom 80px
		font-family indivisaItaliclight
		font-size 30px
	ul
		li
			margin 30px 0 30px 0
			padding 0 0 30px 0
			border-bottom 1px solid lightgray
		a
			position relative
			display block
			padding-left 70px
			color marine
			font-size 18px
			font-family indivisaItalicLight
			&:after
				display none
			&:hover
				span.icon
					top 20%
				small.icon
					right 5px
		span.icon
			position absolute
			width 50px
			height 50px
			text-align center
			line-height 50px
			font-size 32px
			color #fff
			background red
			border-radius 50%
			top 50%
			transform translateY(-50%)
			transition 0.3s all ease
			left 0
		small.icon
			position absolute
			right 15px
			top 50%
			transform translateY(-50%)
			color red
			font-size 25px
			font-weight bold
			transition all .3s ease
	.download-banner
		display flex
		align-items center
		justify-content space-evenly
		background marine
		border-radius 10px
		padding 30px 30px
		color white
		width 80%
		margin 80px auto
		text-align center
		h3
			margin 0
			color white
			font-size 24px
			font-family indivisaRegular
		a
			margin 0
			padding 15.5px 3.2rem
.tabFijo-modalidades
	color marine
	margin-bottom 100px
	position relative
	p
		line-height 24px
.list-with-images
	margin 40px 0
	.slide
		display flex
		margin 20px 0
		.img
			width 22%
			margin-right 3%
			overflow hidden
			a, .js-link
				&:hover
					img
						transform scale(1.2)
					&:after
						display none
			img
				transition all .3s ease-in
			img.js-link
				&:hover
					transform scale(1.2)

		.list-text
			width 75%
			align-self center
			h4	
				margin 0
				padding-bottom 5px
				border-bottom 1px solid lightgray
			p
				margin 20px 0
				line-height 21px
	.disclaimer
		strong
			font-family indivisaBold
.tabFijo-pasos
	margin 50px 0 120px 0
	color marine
	background smoke
	padding 80px 0 60px 0
	position relative
	h2
		max-width 550px
	ol
		padding-left 10%
		li
			font-size 18px
			line-height 24px
			margin 30px 0
			&::marker
				font-family: 'indivisaSerif'
				font-size: 24px
	.lista
		overflow hidden
		height auto
		visibility visible
		transition all .3s ease-out
	.lista-toggler
		text-align center
		display none
		align-items center
		justify-content center
		color oxford
		font-size 16px
		cursor pointer
		font-weight bold
		.icon.down
			color oxford
			font-weight bold
			display block
			margin 0 5px
			font-size 20px
			transition all .3s ease-out
			margin-bottom 4px
			&.rotate
				transform rotate(180deg)
.tabFijo-sempress
	position relative
	h2 
		color white
	li
		position relative
		display flex
		align-items center
		min-height 50px
		a
			position relative
			display block
			color white
			font-size 18px
			padding-left: 60px
			&:after
				display none
			&:hover
				span.icon
					top 20%
				small.icon
					right -35px
		span.icon
			position absolute
			width 45px
			height 45px
			text-align center
			line-height 45px
			font-size 28px
			color #fff
			background red
			border-radius 50%
			top 50%
			transform translateY(-50%)
			transition 0.3s all ease
			left 0
			z-index 1
		small.icon
			position absolute
			right -30px
			top 50%
			transform translateY(-50%)
			color red
			font-size 25px
			font-weight bold
			transition all .3s ease
	.imgWithText-der
		.textBg
			padding 70px 11rem 60px 0px
			top 0
		.imgBg
			top 80px
			width 45%
	.btn.red
		padding 15px 70px
.tabFijo-directorio
	margin 220px 0 50px 0
	position relative
	.directory-grid
		margin-top 100px
		display grid
		grid-template-columns repeat(4,1fr)
		row-gap 1.5rem
		.mailTo
			h5
				display inline-block	
		
		.cardPeople
			margin 0 10px 50px 10px
			.img
				h3
					min-height 52px
@media(max-width tablet-landscape)
	.tabFijo-directorio
		.directory-grid
			grid-template-columns repeat(3,1fr)
@media(max-width tablet)
	.tabFijo-sempress
		.imgWithText-der
			.textBg
				padding 70px 4rem 60px 0px
				width 70%
				box-sizing border-box
				margin-top 0
			.imgBg
				right 0
				width 50%
				height 100%
				position absolute
				font-size 0
				overflow hidden
				top 50px
	.tabFijo-informacion
		.download-banner
			flex-flow column
			text-align center
			padding 20px 30px
			margin 40px auto
			h3
				font-size 16px
				margin-bottom 10px
	.tabFijo-directorio
		margin 	150px 0 50px 0
@media(max-width mobile)
	.sticky-container
		display none
	.tabs.lateralTabs.socialTabs
		display block
	.tabFijo-que-es
		.imgWithText-der
			display flex
			flex-flow column-reverse
			.textBg
				padding 40px 1rem 60px 0
				p
					margin 60px 0 10px 0
			.imgBg
				height 225px
	.tabFijo-informacion
		margin 50px 0
		ul
			li 
				margin 0 0 30px 0
				padding 0 0 30px 0
			a
				padding-left 60px
				padding-right 50px
				font-size 16px
				line-height 18px
			span.icon
					width: 45px
					height: 45px
					line-height: 45px
					font-size: 26px
		h3
			font-size 24px
			line-height 30px
			margin-top 70px
			margin-bottom 60px
	.tabFijo-que-necesitas
		margin-bottom 60px
		.imgBg
			position relative
			width auto
			height 225px
			top 0
		.textBlue
			width 100%
			padding 40px 0px 40px 0px
		h2
			font-size 24px
		li
			padding-left 60px
			p
				line-height 24px
		.icon
			width 45px
			height 45px
			line-height 45px
			font-size 26px
	.tabFijo-sempress
		.imgWithText-der
			.imgBg
				display none
			.textBg
				padding 40px 0 40px 0
				width 100%
		li
			a
				&:hover
					small.icon
						transform translate(20px, 5px)
			small.icon
				display inline-block
				position initial
				transform translate(10px, 5px)
		.btn.red
			margin auto
	.tabFijo-directorio
		margin 70px 0 50px 0
		.directory-grid
			display block
			.slick-list 
				width 2000px
			.slick-slide
				width 230px
			.cardPeople
				padding 0 20px
				margin 0 0 50px 0
				box-sizing border-box
	.tabFijo-modalidades
		margin-bottom 0
		.list-with-images 
			.slick-list 
				width 2000px
			.slick-slide
				width 280px
			.slide
				display block 
				margin 20px 30px 20px 0
				width 280px 
				.img
					margin auto
					width auto
				.list-text
					width auto
					h4
						margin 15px 0
						padding-bottom 15px
		.disclaimer 
			display none
	.tabFijo-pasos
		margin 50px 0 50px 0
		padding 60px 0 60px 0
		.lista
			overflow hidden
			height 0
			visibility hidden
			transition all .3s ease-out
			&.open
				height auto
				visibility visible
		.lista-toggler
			display flex
