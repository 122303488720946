.paginate-container
	text-align right
	clear both
	position relative
	width 235.5px
	margin 25px 0 25px auto
	a
		display block
		font-family indivisaBold
		font-style italic
		font-size 14px
		line-height 16px
		text-align center
		color lightgray
		padding 2px 4px
		border-bottom 1px solid transparent
		&:after 
			display none
		&.active
			color marine
		&.btn
			width 40px
			height 40px
			background marine
			border 0
			border-radius 0
			position absolute
			top 50%
			transform translate(0, -50%)
			span 
				font-size 16px
				font-weight bold
				color white
				position absolute
				top 50%
				left 50%
				transform translate(-50%, -50%)
			&:first-child 
				left 0
			&:last-child
				right 0
	.paginate
		list-style none
		padding 6px 0
		max-width 145px
		margin auto
		overflow hidden
		li
			float left
			width 20px
			margin 0 4px
@media(max-width mobile)
	.paginate-container 
		margin 25px auto