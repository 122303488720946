.content-accordion
	.emphasis 
		margin 70px 0 40px !important
	.accordion 
		width auto
		font-family indivisaItalicLight
		font-size 18px
		font-weight regular
		line-height 21px
		color darkmarine
		padding 7px 35px 9px 7px
		margin 0 auto 35px
		border-bottom 1px solid lightgray
		cursor pointer
		position relative
		transition 0.4s
		&:after 
			content '\e902'
			display block
			font-size 20px
			color red
			position absolute
			top 50%
			right 20px
			font-family 'lasalle' !important
			speak none
			font-style normal
			font-weight bold
			font-variant normal
			text-transform none
			line-height 1
			-webkit-font-smoothing antialiased
			-moz-osx-font-smoothing grayscale
			transform translateY(-50%) rotate(0deg)
			transition all 0.2s
		&:hover 
			border-bottom 1px solid red
		&.active 
			border-bottom 1px solid red
			&:after 
				transform translateY(-50%) rotate(180deg)
.accordion-panel 
	width auto
	display none
	position relative
	margin -1.8rem 0 1rem
	overflow hidden
	.download-list 
		padding .5rem 0 0.7rem !important