.main 
	&.achievements 
		padding 50px 0 70px
	&.achievementsDetail 
		padding 0 0 50px
.titleAndSelect 
	display grid
	grid-template-columns 50% 50%
	.title
		align-self center
	.formSelect 
		margin-top 1.3rem
		justify-self end
		.customSelect 
			width 180px
			font-family indivisaBold
	~ .listDirectorHistory 
		&.descriptive
			margin-top 1.5rem
.listCards-info 
	padding 1.5rem 0 2.5rem
	display flex
	flex-wrap wrap
	justify-content space-between
	li 
		width 48.5%
		margin-bottom 3.1rem
	.cardNote 
		.description 
			h2 
				min-height 73px
.pieNota 
	padding 3.7rem 0
	ul 
		width 100%
		display flex
		flex-wrap wrap
		justify-content space-between
	li 
		align-self center
		margin 0
		p 
			font-size 14px
			line-height 20px
			color marine
		.img 
			width 230px
			height 230px
			font-size 0
			background white
			border 1px solid red
			position relative
			border-radius 50%
			img 
				display block
				width 130px
				position absolute
				top 50%
				left 50%
				transform translate(-50%, -50%)
		&:nth-child(1)  
			width 35%
		&:nth-child(2) 
			width 65%
@media(max-width tablet-portrait) 
	.pieNota 
		ul 
			justify-content center
		li 
			&:nth-child(1) 
				width 100%
			&:nth-child(2) 
				width 100%
			.img 
				margin 0 auto 2rem
@media(max-width tablet) 
	.listCards-info 
		.cardNote 
			.description 
				h2 
					min-height 96px
	.titleAndSelect 
		grid-template-columns 65% 35%
		.formSelect 
			margin-top 1rem
@media(max-width mobile) 
	.listCards-info 
		li 
			width 100%
		.cardNote 
			.description 
				h2 
					min-height auto
	.titleAndSelect 
		grid-template-columns 100%
		.formSelect 
			margin-top .5rem
			margin-bottom 1rem
			justify-self left
			.customSelect 
				width 277px
