.hiddenTitle
	clip rect(1px 1px 1px 1px)
	position absolute

.bannerNews
	padding 0
	.firstNew
		position relative
		height 380px
		padding 0
		margin 0
		overflow hidden
		width 100%
		box-sizing border-box
		margin-bottom -120px
		>a
			position absolute
			top 0
			left 0
			display block
			width 100%
			height 100%
			font-size 0
			margin 0
			padding 0
			&:after
				display none
			&:before
				width 100%
				height 100%
				background rgba(marine, 65%)
				position absolute
				display block
				content ''
				top 0
				left 0
			img
				width 100%
				height 100%
				object-fit cover
				transition 0.6s all ease
		.container
			position relative
			z-index 1
		h3
			margin-top 110px
			margin-bottom 5px
			a
				color white
				font-size 60px
				font-family 'indivisaSerif'
		.date
			font-size 0
			p
				margin 0 10px 0 0
				display inline-block
				font-size 13px
				vertical-align middle
				color white
				border-right 1px solid white
				padding 2px 10px 2px 0
				&:last-child
					border none
	.newsHome
		margin-top 50px
		h2
			margin 0 0 15px
			a
				color white
				font-family 'indivisaItalicLight'
				font-size 26px
	.listMoreNews
		overflow hidden
		width 100%
		// .slide
		// 	column(1/3)
	
	.slick-slide
		margin: 0 9px
		box-sizing border-box
		padding 0 2px

	.slick-list
		margin: 0 -9px

.cardNews
	border 1px solid lightgray
	box-sizing border-box
	.img
		height 160px
		overflow hidden
		a
			display block
			width 100%
			height 100%
			img
				width 100%
				height 100%
				object-fit cover
				transition 0.6s all ease
			&:hover
				img
					transform scale(1.1)
	.info
		padding 5px 15px
		background white
		h3
			margin 10px 0
			font-family 'indivisaBold'
			font-size 18px
			min-height 75px
			line-height 22px
			width 90%
		.date
			border-top 1px solid lightgray
			p
				display inline-block
				margin 10px 10px 0 0
				border-right 1px solid lightgray
				color gray
				font-size 13px
				padding-right 10px
				&:last-child
					border none

.subtitle
	color marine
	font-size 60px
	line-height 60px
	font-family 'indivisaItalicLight'
	margin 0 0 60px
	position relative
	&:after
		content ''
		display block
		width 80px
		height 2px
		background red
		bottom -10px //-18px
		left 0
		position absolute
	&.light
		font-size 40px
		&:after
			bottom 0
	&.center
		display inline-block
		left 50%
		transform translateX(-50%)

.avisosHome
	padding 60px 0

.containerCutSliderWrapper
	.container
		overflow-x visible !important
		padding 0
	.slick-list
		width 2000px
		margin: 0 -9px
	.slick-slide
		margin: 0 10px

	.slideAvisos
		.slide
			max-width 500px
	.sliderFull
		.slide
			max-width 300px
	.innerTitle
		font-size 24px
		color marine
		margin 0 0 20px

.cardAviso
	overflow hidden
	a
		display block
		font-size 0
		img
			transition 0.6s all ease !important
		&:hover
			img
				transform scale(1.1)

.calendarioHome
	padding 60px 0	
.aboutHome
	position relative
	.imgBg
		width 70%
		position absolute
		left 0
		top 0
		height 560px
		z-index 1
		&:after
			content ''
			width 100%
			height 100%
			position absolute
			display block
			top 0
			left 0
			background rgba(marine, 0.7)
		img
			width 100%
			height 100%
			object-fit cover
	.container
		position relative
		z-index 1
		overflow hidden
		display flex
		align-items center
		justify-content center
		min-height 560px
	.info
		flex 60%
		box-sizing border-box
		padding-right 80px
		h2
			color white
			font-size 60px
			font-family 'indivisaItalicLight'
			width 50%
			position relative
			margin-bottom 40px
			&:after
				content ''
				display block
				width 50px
				height 2px
				background red
				position absolute
				top 100%
				left 0
		p
			color white
			font-size 18px
			line-height 24px
			margin-bottom 30px
	.enlaces
		flex 40%
		box-sizing border-box
		li
			margin-bottom 40px
			&:last-child
				margin-bottom 0
.imgLink
	overflow hidden
	height 190px
	max-width 315px
	
	a
		position relative
		display block
		width 100%
		height 100%
		z-index 1
		position relative
		&:after
			content ''
			display block
			width 100%
			height 100%
			position absolute
			top 0
			left 0
			background linear-gradient(to bottom,  rgba(black,0) 0%,rgba(black,0.65) 100%)
			z-index 1
		img
			position absolute
			top 50%
			left 50%
			width 100%
			height auto
			transform translate(-50%, -50%)
			transition 0.6s all ease
		small
			position absolute
			width 100%
			text-align center
			display block
			top 50%
			transform translateY(-50%)
			color white
			font-size 33px
			font-family 'indivisaSerif'
			z-index 2
			&.play
				font-size 80px
		&:hover
			img
				transform translate(-50%, -50%) scale(1.1)
		
.ofertaHome
	padding 90px 0
	position relative
	min-height 500px
	.imgBg
		width 50%
		height 350px
		position absolute
		overflow hidden
		img
			width 100%
			height 100%
			object-fit cover
	.container
		z-index 2
	.sliderOfertaWrapper
		position relative
	.sliderOfertaImg
		position absolute
		top 0
		left 0
		width 50%
	.sliderOfertaTxt
		display block
		vertical-align middle
		width 50%
		margin-left auto
		margin-right 0
		background marine
		height 320px
		box-sizing border-box
		position relative
		top 20px
		&:after,
		&:before
			content ''
			width 40px
			height 100%
			display block
			position absolute
			top 0
			right 100%
			background marine
		&:before
			right unset
			left 100%
			width 600px
			margin-left -1px

.sliderOfertaTxt
	.item
		padding 20px
		box-sizing border-box
	h3 
		color white
		font-family 'indivisaItalicLight'
		font-size 40px
		position relative
		margin-bottom 60px
		&:after
			content ''
			display block
			width 80px
			height 2px
			background white
			top 100%
			left 0
			position absolute
	.slick-dots
		li
			display inline-block
			margin: 0 5px;
			width: 40px;
			transition: 0.6s all ease;

			&.slick-active
				width: 70px;
				button
					border-bottom: 2px solid white
			&:hover
				button
					border-bottom: 2px solid white
		button
			font-size 0
			outline none
			display inline-block
			height 10px
			border none
			border-bottom 2px solid rgba(white,0.5)
			background transparent
			width 100%
			cursor pointer
			transition 0.6s all ease


.interesHome
	padding 60px 0
	.imgBg
		width 60%
		height 458px
		position absolute
		right 0
		img
			width 100%
			height 100%
			object-fit cover
	.container
		position relative
		z-index 2
	.ulArrows
		background smoke
		max-width 685px
		box-sizing border-box
		overflow hidden
		padding 50px 50px 20px 100px
		margin 125px 0 0
		>li
			column(1/2, $cycle:2)
			margin-bottom 30px
		a
			font-size 18px
			display block
			width 65%
	.otrosLinks
		margin-top 200px
	.moreItems
		display block
		max-width 685px
		background smoke
		border-top 1px solid lightgray
		margin 0
		padding 10px 0
		text-align center
		display none
		a
			color gray
			position relative
			&:after
				display none
			&:before
				content '\e902'
				font-family 'lasalle'
				position absolute
				left 100%
				margin-left 3px
				transition 0.6s all ease
			&.open
				&:before
					transform rotate(180deg)
		


.sliderLinks
	.slide
		border 1px solid lightgray
		.img
			overflow hidden
			height 180px
			a, .js-link
				position relative
				height 100%
				display block
				font-size 0
				img
					width 100%
					height 100%
					object-fit cover
					// position absolute
					// top 50%
					//transform translateY(-50%)
					transition 0.6s all ease
				&:hover
					img
						transform scale(1.1)
			img.js-link
				width 100%
				height 100%
				object-fit cover
				transition 0.6s all ease
				&:hover
					transform scale(1.1)
		>p
			padding 25px 20px
			margin 0

.digitalHome
	padding 60px 0
	.redes
		display flex
		text-align center
		.half
			flex 50%
			box-sizing border-box
			margin-right 50px
			&:last-child
				margin 0
	h3
		color marine
		font-size 30px
		position relative
		text-align left
		&:after
			content ''
			width 100%
			background red
			height 2px
			bottom 8px
			left 0
			position absolute
			display block
			z-index -1
		span
			display inline-block
			background white
			position relative
			padding-right 20px
			z-index 1
			&:after
				content ''
				width 100%
				background white
				height 3px
				bottom 8px
				left 0
				position absolute
				display block
				z-index -1
	.fbFeed
		text-align center
		height calc(100%- 110px)
		width 100%
		margin 0 auto
		border none
	iframe
		height 100%
		border none
		overflow hidden

.cardDigitalNew
	text-align left
	.img,
	.info
		display inline-block
		vertical-align middle
	.img
		width 160px
		height 160px
		a
			display block
			width 100%
			height 100%
			overflow hidden
			position relative
			img
				width 100%
				position absolute
				top 50%
				left 50%
				transform translate(-50%, -50%)
				transition 0.6s all ease
			&:hover
				img
					transform translate(-50%, -50%) scale(1.1)
			
	.info
		width calc(100% - 200px)
		margin-left 20px
	h4
		font-size 16px
		line-height 24px
		margin 5px 0
	.date
		color gray
		text-transform uppercase
		margin 0
		font-size 12px
	.likes
		>li
			display inline-block
			width 40px
			color blue
		.icon
			margin-right 5px

.cardAlive
	position relative
	a
		display block
		width 100%
		height 100%
		overflow hidden
		position relative
		img
			width 100%
			height 100%
			object-fit cover
			transition 0.6s all ease !important
			position relative
		&:hover
			img
				transform scale(1.1)
	.liveLogo
		position absolute
		top 4px
		right -24px
		transform scale(0.6)
		max-width 145px
		a
			overflow visible
		.icon
			animation none
	&.left
		.liveLogo
			right unset
			left -24px
			
	&.alive
		.liveLogo
			.icon
				animation pulse 1.5s infinite

@keyframes pulse
	0%
		transform scale(1)
	70%
		box-shadow 0 0 0 20px rgba(0, 0, 0, 0)
		transform scale(1.1)
	100% 
		box-shadow 0 0 0 0 rgba(0, 0, 0, 0)
		transform scale(1)

// @keyframes pulse
// 	0%
// 		transform scale(1)
// 	50%
// 		transform scale(1.1)
// 	0%
// 		transform scale(1)

.numeraliaHome
	padding 60px 0 120px
	h2
		width 50%
	.container
		position relative
	.bgNums
		display block
		background marine
		width 100%
		height 200px
		margin-top -160px
		position relative
		z-index -1
	.slideNumeros
		width 90%
		margin 0 auto
		position relative
		z-index 1
		overflow hidden
		.slick-slide 
			&:last-child
				.cardNumber 
					p 
						border-right 0
		.cardNumber
			text-align center
			position relative
			background smoke
			margin-top 94px
			padding 50px 0 30px
			>strong
				color marine
				font-size 40px
				margin 20px 0
				display block
				font-family 'indivisaItalicLight'
			p
				border-right 1px dashed marine
				color marine
				box-sizing border-box
				padding 10px 16px 10px
				font-size 16px
				line-height 21px
		.icono
			border-radius 50%
			width 94px
			height 94px
			line-height 94px
			color white
			text-align center
			margin 0 auto
			position absolute
			top -47px
			left 50%
			transform translateX(-50%)
			background red
			span
				font-size 60px
				position absolute
				width 100%
				top 50%
				left 0
				transform translateY(-50%)
		.slick-arrow
			width 40px
			height 40px
			border none
			position absolute
			outline none
			background none
			top 50%
			font-size 0
			display block
			z-index 2
			&:before
				content ''
				font-family 'lasalle'
				color red
				font-size 24px

			&.slick-prev
				left 0
				&:before
					content '\e942'
			&.slick-next
				right 0
				&:before
					content '\e929'
		
@media (max-width tablet-landscape)
	.aboutHome 
		.imgBg
			width 75%

@media (max-width tablet)
	.aboutHome 
		.imgBg
			width 80%
		.info
			padding-right 45px
			h2
				width 80%
	
	.bannerNews 
		.listMoreNews
			.slide
				max-width 300px
		.slick-list
			margin: 0 1px 0 0px
	
	.newsHome
		.container
			padding-right 0
	
	.ofertaHome 
		.sliderOfertaImg
			width 70%
		.sliderOfertaTxt
			top 150px
			width 60%
	.containerCutSliderWrapper
		.slick-list
			margin: 0 0px 0 20px
		.innerTitle
			margin-left 20px
	// .numeraliaHome
	// 	.slideNumeros
	// 		width 80%

@media (max-width mobile)
	.cardDigitalNew 
		.img 
			display block
			width 50%
			height auto
			margin 0 auto
			a 
				img 
					height 100%
					object-fit cover
					position relative
					top 0
					left 0
					transform translate(0, 0)
				&:hover 
					img 
						transform translate(0, 0) scale(1.1)
		.info 
			display block
			width 100%
			margin 1.5rem 0 2rem
	.subtitle
		font-size 40px
		line-height 46px
		margin-bottom 40px

	.bannerNews 
		.firstNew 
			h3
				margin-top 65px
	
	.aboutHome 
		.imgBg
			width 100%
		.container
			display block
		.info,
		.enlaces
			width 100%
		.info
			padding-right 0
			margin-top 70px
			.btn
				display block
				width 100%
				text-align center
			h2
				width 100%
				font-size 40px
				margin-bottom 60px
		.enlaces
			margin-top 60px
	.imgLink
		max-width 100%
	
	.bannerNews 
		.listMoreNews
			.slide
				max-width 300px
		
	
	.newsHome
		.container
			padding-right 0
	
	.calendarList
		>li
			stack()

	.cardCalendar
		.title
			h3
				margin-bottom 25px
			.btnMoreDetail
				display block
		.info
			min-height auto
			.resume
				display none
	.containerCutSliderWrapper
		.slick-list
			margin: 0 
		.slideAvisos
			margin-left 20px
			.slide
				max-width 300px

	.calendarioHome
		.a-center
			.btn
				display block
	
	.ofertaHome
		.sliderOfertaWrapper
			.container
				padding 0
		.sliderOfertaImg
			position relative
			width 100%
		.sliderOfertaTxt
			top -10px
			width 100%
			margin-top 0
			width 100%
			&:after
				margin-right -1px
			.slick-dots
				text-align center
			.btn
				display block
				text-align center
	
	.sliderLinks
		margin-left 20px
		.slide
			max-width 300px
	
	.digitalHome
		padding 60px 0
		.redes
			display block
			.half
				width 100%
		.fbFeed
			height 500px
	.slideAlive
		margin-left 20px
		.slide
			max-width 300px
	
	.interesHome
		.imgBg
			display none
		.ulArrows
			margin-top 0
			padding-left 50px
			>li
				stack()
				&:nth-last-child(-n+5)
					display none
			&.open
				>li
					&:nth-last-child(-n+5)
						display block
		.moreItems
			display block
		.otrosLinks
			margin-top 60px
	
	.numeraliaHome 
		h2
			width 100%
		.slideNumeros 
			.cardNumber 
				p
					padding 10px 35px 10px
					border none
@media (max-width mobile-landscape) 
	.subtitle
		&.light 
			font-size 36px
			line-height 42px

@media (max-width mobile-portrait)
	.bannerNews
		.listMoreNews 
			.slide
				max-width 280px