.imgWithText-der 
	min-height 610px
	position relative
	.imgBg 
		width 65%
		height 100%
		position absolute
		right 0
		font-size 0
		img 
			width 100%
			height 100%
			object-fit cover
	.container 
		position relative
		z-index 2
	.textBg 
		width 100%
		max-width 685px
		color white
		background marine
		padding 63px 14.5rem 50px 0px
		position relative
		top 80px
		box-sizing border-box
		h1 
			color white
			&:after 
				background white
		p 
			line-height 24px
		a 
			display table
			margin 1.6rem 0
			&.circled 
				padding 8px 55px 8px 15px
				&:hover 
					padding 8px 65px 8px 15px
			&.arrow 
				font-size 18px 
				color white
				&:before
					color white
					font-size 20px
		&:after 
			content ''
			display block
			width 600px
			height 100%
			background marine
			position absolute
			top 0
			right 100%
			margin-right -1px
.activityAspiante 
	width 97%
	text-align center
	margin 4.7rem auto
	overflow hidden
	.slider-item 
		max-width 430px
		margin 0 12px
	.cardActivity 
		text-align center
		background smoke
		position relative
		padding 20px 0 0
		margin 94px 0 47px
		.icon 
			width 76px
			height 76px
			line-height 76px
			color white
			text-align center
			border-radius 50%
			margin 0 auto
			position absolute
			top -40px
			left 50%
			transform translateX(-50%)
			background red
			span 
				width 100%
				font-size 50px
				position absolute
				top 50%
				left 0
				transform translateY(-50%)
		.info
			padding 1.3rem 2.2rem 2.4rem
			h4 
				display inline-block
				font-size 30px
				line-height 50px
				color marine
				margin 20px 0
				&:after 
					width 60px
			p 
				line-height 24px
				color marine
.imgWithRegistration
	min-height 320px
	position relative
	margin-bottom 4.3rem
	.imgSmall 
		width 42%
		height 100%
		position absolute
		right 0
		font-size 0
		img 
			width 100%
			height 100%
			object-fit cover
	.container 
		position relative
		z-index 2
	.textSmall 
		width 100%
		max-width 685px
		color white
		background marine
		padding 63px 14.5rem 50px 0px
		position relative
		top 30px
		box-sizing border-box
		h3 
			font-family indivisaItalicLight
			font-weight regular
			font-size 20px
			line-height 32px
			color white
			margin .5rem 0 1.7rem .1rem
			strong 
				display block
				font-size 40px
				line-height 58px
		a 
			padding 15px 30px
			margin-bottom 1.6rem
		&:after 
			content ''
			display block
			width 600px
			position absolute
			top 0
			right 100%
			height 100%
			background marine
			margin-right -1px
.contactAspirante 
	width 97%
	text-align center
	margin 0 auto 6.5rem
	overflow hidden
	.slider-item 
		max-width 430px 
		margin 0 12px
	.cardContact 
		min-height 128px
		text-align center
		background marine
		border-radius 20px
		position relative
		padding 20px 0 0
		margin 94px 0 47px
		.icon 
			width 76px
			height 76px
			line-height 76px
			text-align center
			color white
			background red
			border-radius 50%
			position absolute
			top -40px
			left 50%
			margin 0 auto
			transform translateX(-50%)
			span 
				width 100%
				font-size 45px
				position absolute
				top 50%
				left 0
				transform translateY(-50%)
				&.wp 
					font-size 65px
		.info 
			padding 1.3rem 2.1rem 0.3rem 
			h6 
				margin 0
			a 
				display inline-block
				font-family indivisaItalicLight
				font-size 30px
				line-height 42px
				color white
				margin 1.5rem 0
				&:before
					font-size 20px
					color white
.wrapSliderTop 
	position relative
	margin 0rem 0 1.3rem
	.navSlider
		a
			display none
			position absolute
			width 40px
			height 40px
			top 50%
			transform translateY(-50%)
			font-size 24px
			text-align center
			line-height 40px
			&:after
				display none
			&.btnPrev-applicant
				left -15px
			&.btnNext-applicant
				right -15px
.sliderTabs-applicant 
	width 70%
	margin 0 auto
	.slick-arrow
		display none
		position absolute
		font-size 0
		padding 0
		opacity 0
	.slide
		max-width 110px
		p
			width 60px
			height 60px
			box-sizing border-box
			position relative
			margin 1rem auto
		a
			border-radius 50%
			display block
			width 21px
			height 21px
			background medgray
			position relative
			left 50%
			top 50%
			transform translate(-50%, -50%)
			&:after
				display none
			&:before
				content '\e953'
				font-family 'lasalle'
				font-size 60px
				left 100%
				top 50%
				margin-left 10px
				transform translateY(-50%)
				position absolute
			&:hover
				background red
				&:before
					color marine
					transition none
			span
				position absolute
				top 55%
				left 50%
				transform translate(-50%, -50%)
				display block
				margin 0
				font-size 0
				color white
		&:last-child 
			a 
				&:before
					display none
	.slick-current
		a
			background red
			width 60px
			height 60px
			&:before
				margin-left 5px
			span
				font-size 40px
				line-height 32px
.contentTabs-applicant 
	padding-bottom 60px
	.slide 
		p 
			line-height 24px
			color marine
			text-align center
			padding 0 10rem
.imgWithVirtual 
	min-height 420px
	position relative
	margin 11rem 0 8rem
	.imgMedium 
		width 46.5%
		height 100%
		position absolute
		top 35px
		right 0
		font-size: 0
		img 
			width 100%
			height 100%
			object-fit cover
	.container 
		position relative
		z-index 2
	.textMedium 
		width 100%
		max-width 685px
		color white
		background marine
		padding 85px 90px 72px 0px
		position relative
		box-sizing border-box
		h2 
			color white
		a 
			padding 15px 30px
			margin-top 5px
		&:after 
			content ''
			display block
			width 600px
			position absolute
			top 0
			right 100%
			height 100%
			background marine
			margin-right -1px
@media(max-width tablet-portrait)
	.contentTabs-applicant 
		.slide 
			p 
				padding 0 7rem
@media(max-width tablet)
	.imgWithText-der 
		.textBg 
			margin-top -300px
			top 0
		.imgBg 
			width 100%
			height auto
			position relative
	.contactAspirante 
		width 100%
		.slider-item 
			max-width 380px
	.activityAspiante
		width 360px
		.slider-item 
			margin 0
		.slick-center 
			text-align center
		.slick-arrow 
			width 40px
			height 40px
			border none
			position absolute
			outline none
			background none
			top 50%
			font-size 0
			display block
			z-index 2
			&:before 
				content ''
				font-family 'lasalle'
				color red
				font-size 24px
			&.slick-prev 
				left -10px
				&:before 
					content '\e942'
			&.slick-next 
				right -10px
				&:before 
					content '\e929'
	.imgWithRegistration 
		.imgSmall 
			width 100%
			height auto
			position relative
		.textSmall 
			padding 63px 3rem 50px 0
			margin-top -200px
			top 0
	.sliderTabs-applicant 
		width 90%
	.contentTabs-applicant 
		.slide 
			p 
				padding 0
	.imgWithVirtual 
		margin 3rem 0 6rem
		.imgMedium 
			width 100%
			height auto
			position relative
			top 0
		.textMedium 
			margin-top -200px
			top 0
@media(max-width lgmobile)
	.imgWithText-der 
		.textBg
			padding 63px 3rem 50px 0
	.imgWithVirtual 
		.textMedium 
			padding 85px 20px 72px 0
@media(max-width mobile) 
	.contactAspirante 
		.slider-item 
			width 100%
			margin 0 40px
		.slick-center 
			text-align center
		.slick-arrow 
			width 40px
			height 40px
			border none
			position absolute
			outline none
			background none
			top 50%
			font-size 0
			display block
			z-index 2
			&:before 
				content ''
				font-family 'lasalle'
				color red
				font-size 24px
			&.slick-prev 
				left -10px
				&:before 
					content '\e942'
			&.slick-next 
				right -10px
				&:before 
					content '\e929'
	.imgWithText-der 
		background marine
		.textBg 
			padding 63px 1rem 50px 0
			margin-top 0
	.imgWithRegistration 
		background marine
		.textSmall 
			padding 63px 1rem 50px 0
			margin-top 0
	.imgWithVirtual 
		background marine
		margin 3rem 0
		.textMedium 
			margin-top 0
	.wrapSliderTop 
		.navSlider
			a
				display block
	.sliderTabs-applicant 
		.slide
			a 
				&:before
					font-size 50px
		.slick-current 
			a 
				span 
					font-size 34px
@media(max-width mobile-landscape)
	.contactAspirante 
		.slider-item
			max-width 300px
@media(max-width mobile-landscape - 59) 
	.activityAspiante
		width 280px
	.contactAspirante 
		.slider-item
			max-width 235px