@font-face
	font-family 'indivisaSerif'
	src url('assets/fonts/indivisa/IndivisaDisplaySans-RegularItalic.eot')
	src url('assets/fonts/indivisa/IndivisaDisplaySans-RegularItalic.eot?#iefix') format('embedded-opentype'), url('assets/fonts/indivisa/IndivisaDisplaySans-RegularItalic.woff') format('woff'), url('assets/fonts/indivisa/IndivisaDisplaySans-RegularItalic.ttf') format('truetype'), url('assets/fonts/indivisa/IndivisaDisplaySans-RegularItalic.svg#IndivisaDisplaySans-RegularItalic') format('svg')
	font-weight normal
	font-style normal

@font-face
	font-family 'indivisaItalicLight'
	src url('assets/fonts/indivisa/IndivisaTextSans-BoldItalic.eot')
	src url('assets/fonts/indivisa/IndivisaTextSans-BoldItalic.eot?#iefix') format('embedded-opentype'), url('assets/fonts/indivisa/IndivisaTextSans-BoldItalic.woff') format('woff'), url('assets/fonts/indivisa/IndivisaTextSans-BoldItalic.ttf') format('truetype'), url('assets/fonts/indivisa/IndivisaTextSans-BoldItalic.svg#IndivisaTextSans-BoldItalic') format('svg')
	font-weight normal
	font-style normal


@font-face
	font-family 'indivisaRegular'
	src url('assets/fonts/indivisa/IndivisaTextSans-Regular.eot')
	src url('assets/fonts/indivisa/IndivisaTextSans-Regular.eot?#iefix') format('embedded-opentype'), url('assets/fonts/indivisa/IndivisaTextSans-Regular.woff') format('woff'), url('assets/fonts/indivisa/IndivisaTextSans-Regular.ttf') format('truetype'), url('assets/fonts/indivisa/IndivisaTextSans-Regular.svg#IndivisaTextSans-Regular') format('svg')
	font-weight normal
	font-style normal

@font-face
	font-family 'indivisaBold'
	src url('assets/fonts/indivisa/IndivisaTextSans-Bold.eot')
	src url('assets/fonts/indivisa/IndivisaTextSans-Bold.eot?#iefix') format('embedded-opentype'), url('assets/fonts/indivisa/IndivisaTextSans-Bold.woff') format('woff'), url('assets/fonts/indivisa/IndivisaTextSans-Bold.ttf') format('truetype'), url('assets/fonts/indivisa/IndivisaTextSans-Bold.svg#IndivisaTextSans-Bold') format('svg')
	font-weight normal
	font-style normal

@font-face
	font-family 'lasalle'
	src url('assets/fonts/lasalle/lasalle.eot')
	src url('assets/fonts/lasalle/lasalle.eot?#iefix') format('embedded-opentype'), url('assets/fonts/lasalle/lasalle.woff') format('woff'), url('assets/fonts/lasalle/lasalle.ttf') format('truetype'), url('assets/fonts/lasalle/lasalle.svg#lasalle') format('svg')
	font-weight normal
	font-style normal


.icon
	font-family 'lasalle' !important
	speak none
	font-style normal
	font-weight normal
	font-variant normal
	text-transform none
	line-height 1
	/* Better Font Rendering =========== */
	-webkit-font-smoothing antialiased
	-moz-osx-font-smoothing grayscale
	&.mas
		&:before 
			content "\e991"
	&.directorio
		&:before 
			content "\e98c"
	&.font
		&:before 
			content "\e98d"
	&.mailInst
		&:before 
			content "\e98e"
	&.pagos
		&:before 
			content "\e98f"
	&.portalWeb
		&:before 
			content "\e990"
	&.comments
		&:before 
			content "\e98a"
	&.heart
		&:before 
			content "\e98b"
	&.isotipo
		&:before 
			content "\e985"
	&.cultural
		&:before 
			content "\e986"
	&.academico
		&:before 
			content "\e987"
	&.produccion
		&:before 
			content "\e988"
	&.deportivo
		&:before 
			content "\e989"
	&.boleto
		&:before 
			content "\e965"
	&.certificado
		&:before 
			content "\e966"
	&.llamada
		&:before 
			content "\e967"
	&.marcador
		&:before 
			content "\e968"
	&.medalla
		&:before 
			content "\e969"
	&.reloj
		&:before 
			content "\e96a"
	&.factura
		&:before 
			content "\e96b"
	&.calendario
		&:before 
			content "\e96c"
	&.click
		&:before 
			content "\e96d"
	&.presentacion
		&:before 
			content "\e96e"
	&.pin
		&:before 
			content "\e96f"
	&.misioneros
		&:before 
			content "\e970"
	&.iglesia
		&:before 
			content "\e971"
	&.descargar
		&:before 
			content "\e972"
	&.ojo
		&:before 
			content "\e973"
	&.estrella
		&:before 
			content "\e974"
	&.calificaciones
		&:before 
			content "\e975"
	&.gestion
		&:before 
			content "\e976"
	&.gestion-escolar
		&:before 
			content "\e977"
	&.live
		&:before 
			content "\e978"
	&.configuracion
		&:before 
			content "\e979"
	&.danza-arabe
		&:before 
			content "\e97a"
	&.lentes
		&:before 
			content "\e97b"
	&.acreditar
		&:before 
			content "\e97c"
	&.alumno-regular
		&:before 
			content "\e97d"
	&.programas-check
		&:before 
			content "\e97e"
	&.megafono
		&:before 
			content "\e97f"
	&.guia
		&:before 
			content "\e980"
	&.iniciativa
		&:before 
			content "\e981"
	&.sobre
		&:before 
			content "\e982"
	&.porcentaje
		&:before 
			content "\e983"
	&.trato
		&:before 
			content "\e984"
	&.line-dots
		&:before 
			content "\e953"
	&.posgrado
		&:before 
			content "\e954"
	&.prepa
		&:before 
			content "\e955"
	&.depositos
		&:before 
			content "\e956"
	&.facturacion
		&:before 
			content "\e957"
	&.pagos-online
		&:before 
			content "\e958"
	&.lic
		&:before 
			content "\e959"
	&.padre-familia
		&:before 
			content "\e95a"
	&.organizador
		&:before 
			content "\e95b"
	&.emprendedor
		&:before 
			content "\e95c"
	&.integracion
		&:before 
			content "\e95d"
	&.negocio
		&:before 
			content "\e95e"
	&.alumnos
		&:before 
			content "\e95f"
	&.doctorado
		&:before 
			content "\e960"
	&.especialidad
		&:before 
			content "\e961"
	&.libros
		&:before 
			content "\e962"
	&.maestria
		&:before 
			content "\e963"
	&.clases
		&:before 
			content "\e964"
	&.fe
		&:before 
			content "\e952"
	&.compras
		&:before 
			content "\e94d"
	&.arte
		&:before 
			content "\e94e"
	&.restaurante
		&:before 
			content "\e94f"
	&.cafe
		&:before 
			content "\e950"
	&.biblioteca
		&:before 
			content "\e951"
	&.markFull
		&:before 
			content "\e94c"
	&.telephone
		&:before 
			content "\e943"
	&.onSpeaking
		&:before 
			content "\e944"
	&.offSpeaking
		&:before 
			content "\e945"
	&.audio
		&:before 
			content "\e946"
	&.comment
		&:before 
			content "\e947"
	&.faith
		&:before 
			content "\e948"
	&.justice
		&:before 
			content "\e949"
	&.compromise
		&:before 
			content "\e94a"
	&.fraternity
		&:before 
			content "\e94b"
	&.play
		&:before 
			content "\e91c"
	&.link
		&:before 
			content "\e936"
	&.ym
		&:before 
			content "\e937"
	&.wp
		&:before 
			content "\e938"
	&.read
		&:before 
			content "\e939"
	&.certificate
		&:before 
			content "\e93a"
	&.school
		&:before 
			content "\e93b"
	&.speaker
		&:before 
			content "\e93c"
	&.atom
		&:before 
			content "\e93d"
	&.bag
		&:before 
			content "\e93e"
	&.carbuy
		&:before 
			content "\e93f"
	&.idea
		&:before 
			content "\e940"
	&.hands
		&:before 
			content "\e941"
	&.arrowprev
		&:before 
			content "\e942"
	&.mouse
		&:before 
			content "\e900"
	&.mail
		&:before 
			content "\e901"
	&.down
		&:before 
			content "\e902"
	&.up
		&:before 
			content "\e903"
	&.right
		&:before 
			content "\e904"
	&.left
		&:before 
			content "\e905"
	&.headphones
		&:before 
			content "\e906"
	&.download
		&:before 
			content "\e907"
	&.chat
		&:before 
			content "\e908"
	&.books
		&:before 
			content "\e909"
	&.calculator
		&:before 
			content "\e90a"
	&.wrong
		&:before 
			content "\e90b"
	&.conversation
		&:before 
			content "\e90c"
	&.correct
		&:before 
			content "\e90d"
	&.error
		&:before 
			content "\e90e"
	&.interchange
		&:before 
			content "\e90f"
	&.conectivity
		&:before 
			content "\e910"
	&.video
		&:before 
			content "\e911"
	&.desktop
		&:before 
			content "\e912"
	&.document
		&:before 
			content "\e913"
	&.stethoscope
		&:before 
			content "\e914"
	&.student
		&:before 
			content "\e915"
	&.smartphone
		&:before 
			content "\e916"
	&.pencil
		&:before 
			content "\e917"
	&.sitemap
		&:before 
			content "\e918"
	&.medal
		&:before 
			content "\e919"
	&.microphone
		&:before 
			content "\e91a"
	&.wireless
		&:before 
			content "\e91b"
	&.fountain
		&:before 
			content "\e91d"
	&.feather
		&:before 
			content "\e91e"
	&.pen
		&:before 
			content "\e91f"
	&.pentwo
		&:before 
			content "\e920"
	&.watercolor
		&:before 
			content "\e921"
	&.search
		&:before 
			content "\e922"
	&.security
		&:before 
			content "\e923"
	&.consult
		&:before 
			content "\e924"
	&.sound
		&:before 
			content "\e925"
	&.files
		&:before 
			content "\e926"
	&.upload
		&:before 
			content "\e927"
	&.close
		&:before 
			content "\e928"
	&.arrow
		&:before 
			content "\e929"
	&.mark
		&:before 
			content "\e92a"
	&.time
		&:before 
			content "\e92b"
	&.phone
		&:before 
			content "\e92c"
	&.share
		&:before 
			content "\e92d"
	&.seeker
		&:before 
			content "\e92e"
	&.fb
		&:before 
			content "\e92f"
	&.tw
		&:before 
			content "\e930"
	&.yt
		&:before 
			content "\e931"
	&.ig
		&:before 
			content "\e932"
	&.in
		&:before 
			content "\e933"
	&.sc
		&:before 
			content "\e934"
	&.chk
		&:before 
			content "\e935"