/**
* 1. Remove the gray background on active links in IE 10.
* 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
*/

a
	background-color transparent /* 1 */
	-webkit-text-decoration-skip objects /* 2 */
	text-decoration none
	transition 0.6s all ease

/**
* Remove the outline on focused links when they are also active or hovered
* in all browsers (opinionated).
*/

a:active, 
a:hover
	outline-width 0

