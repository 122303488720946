.modulesMain 
	.enfasis
		color Navy
		padding-bottom 2rem
	section
		margin 50px 0
		&.cleanFloat
			clear both
			padding-top 2rem
	.content-fullSlider,
	.bannerAguilas
		margin 2.5rem 0
	.sectionComplete,
	.history-us,
	.imagesWithText
		margin 2.5rem 0 4rem
	.digitalHome 
		padding 0
	.contentConsultas 
		padding 2rem 0rem 5rem
	.cardNote
		margin 2.5rem auto
	.bannerNews 
		margin 5rem 0
	.floatingSlider
		margin 2.6rem 0 7rem
		&.withBullets
			&.missionary
				&.__alma
					margin 2.6rem 0 8.5rem
	.ofertaHome
		padding 30px 0
	.listImagesWithText,
	.containerCutSliderWrapper,
	.imgWithText-der,
	.porraAguilas
		margin 2.5rem 0
	.digitalHome
		.fbFeed
			min-height 500px
	.digitalHome
		.redes
			width 50%
			margin 0 auto
	.principios-container
		margin 50px auto
	.descriptionGroup
		margin 0 0 4rem
	.listTabs-accreditations
		padding-top 1.5rem
	.infoBlock-tab
		padding-top 1.7rem
	.full-img
		margin 2.5rem 0 3.4rem
	.prizeContent
		margin-top 2rem
	.activityAspiante
		margin 0 auto
	.content-presentation
		margin 2.5rem 0 5rem
		margin-top 2.5rem
	.aboutHome
		margin-bottom 4.5rem
	.our-story
		padding 4rem 0 5.9rem
	.inscriptionSeccion
		margin 50px 0 4rem
	.bannerFull-imagen
		margin 0 0 4.5rem
	.listCards-info
		.cardNote
			margin 0 auto
	.inversionSeccion
		padding 0
	.scheduleSection
		padding 0
	.contentClasificaciones
		margin 2.5rem 0px 0.5rem
	.tabsProfiles
		.infoBlock-tab
			padding-top 0
	.tabFijo-que-es
		.imgWithText-der
			margin 0
	.videoLibros
		margin 0 auto
	.cardSliderMenu 
		margin 5rem 0 10rem
.dropSelect
	display grid
	grid-template-columns 31% 69%
	.searchForm
		margin 0
		width 100%
		.searchInput
			line-height 1.15
			border 1px solid marine !important
			input
				padding 11px 20px

	.customSelect
		border-radius 0
		background marine
		color #fff
		.customSelect-arrow
			&:after
				color #fff
.dropSelectGrid
	display grid
	grid-template-columns repeat(2,1fr)
	grid-gap 50px 25px
	margin 50px 0
	.dropSelectGrid-item
		border 1px solid medgray
		padding 50px 30px 30px 30px
		position relative
		&:after
			display block
			content: ""
			width 50%
			top -3px
			left 0
			right 0
			margin auto
			height 6px
			background marine
			position absolute
	.dropSelectGrid-item-header
		position relative
		padding-left 110px
		p
			margin 0
			font-size 14px
			color medgray
		h4
			margin 5px 0
			color marine
		h5
			margin 0
			color red
			font-size 16px
	.dropSelectGrid-item-body
		border-top 1px solid red
		margin-top 35px
		padding-top 20px
		color medgray
		h5 
			margin 0
			font-size 16px
			color oxford
			font-weight bold
		p
			margin 0
			~ p
				margin 20px 0 20px 0
		a
			color medgray
			margin 5px 0
			display inline-block
			font-weight regular
			font-family indivisaRegular
	img
		width 100px
		height 100px
		border-radius 50%
		position absolute
		top 50%
		left 0
		transform translateY(-50%)
	a.btn
		margin-bottom 0
		font-size 16px
		color oxford
		font-weight regular
		padding 10px 45px
		&:hover
			color #fff
.carouselBackFull
	background marine
	color #fff
	.carouselBackFullWrapper
		display grid
		grid-template-columns 40% 55%
		padding 100px 0
		align-self center
		grid-gap 0 5%
		h2
			color #fff
			font-size 50px
		h3
			color #fff
			font-size 18px
		h4
			font-size 15px
			color smoke
			font-family indivisaRegular
			font-weight regular
		h5 
			margin 0
			font-size 16px
			color smoke
			font-family indivisaRegular
		p
			margin 0
			color smoke
			font-family indivisaRegular
		a
			color smoke
			margin 5px 0
			display inline-block
			font-family indivisaRegular
		.slide
			margin 0 10px
			text-align center
			img
				width 300px
				height 180px
				object-fit cover
				margin 0 auto
	.carouselBackDesc
		align-self center
		padding-right 50px
	.carouselBackContainer
		position relative
	.flechaPrev,.flechaNext
		position absolute
		top 20%
		background rgba(lightmarine, 0.9)
		z-index 10
		padding 10px
		display grid
		cursor pointer
		transition all .3s ease-out
		&:hover
			background #fff
			span
				color red
	.flechaPrev
		left -50px
	.flechaNext
		right -50px
.interactiveCards
	margin 50px 0 100px
	.slick-list
		width 1500px
		overflow visible
		&.visible
			overflow visible
	.slick-slide
		width 400px !important
	.interactiveCard
		width 400px
		padding 50px 80px 30px 30px
		background smoke
		border-radius 25px
		box-shadow -5px 5px 15px 3px rgba(0,0,0,0.35)
		box-sizing border-box
		position relative
		min-height 470px
		cursor pointer
		transition all .5s ease-out
		margin-right -110px
		&:hover
			transform translate(-5px)
		&.rotate
			transform rotate(3deg) translate(-60px, -5px)
	.container
		&.visible
			overflow-x visible !important
	img
		width 100px
		height 100px
		border-radius 50%
		position absolute
		left 0
		top 50%
		transform translateY(-50%)
	.interactiveCard--header
		position relative
		padding-left 110px
		margin-bottom 50px
		h3
			margin 0
			color marine
			font-size 24px
		h4
			margin 5px 0
			font-size 16px
			color medgray
			font-weight regular
			font-family indivisaRegular
	.interactiveCard--contact
		color marine
		
		h5
			font-family indivisaBold
			margin 0
			font-size 16px
		p
			margin 0
		a
			font-weight regular
			margin 5px 0
			font-family 'indivisaRegular'
			display inline-block
	.interactiveCard--desc
		color medgray
	.interactiveCard--footer
		text-align center
		color marine
		left 0
		right 0
		margin auto
		position absolute
		bottom 20px
.variant
	.accordion
		color marine
		font-family indivisaSerif
	h4
		color marine
.numbered-list
	margin-left 20px
	li
		position relative
		padding-left 40px
		padding-bottom 40px
		border-left 1px solid medgray
		margin-bottom 0
		h4
			color marine
			margin 0 0 20px 0
		p
			color medgray
			margin 0
			font-size 16px
		.marker
			display block
			width 40px
			height 40px
			position absolute
			left -21px
			top 0
			border 1px solid marine
			line-height 50px
			background white
			border-radius 50%
		span
			position absolute
			left 0
			top 22px
			z-index 2
			transform translate(-50%,-50%)
			font-size 20px
			font-weight bold
			color marine
		&:last-child
			border none
.two-columns-img-cta
	display grid
	grid-template-columns repeat(2,1fr)
	grid-template-rows 500px
	align-self start
	overflow hidden
	h4
		font-size 24px
		margin 0
	p
		font-size 16px
		margin 20px 0
	a.btn
		border-radius 0
		margin 0
	.colums
		position relative
		box-sizing border-box
		padding 60px 40% 60px 60px
		color white
		&:last-child
			.img 
				&:before 
					background rgba(88,88,88,.9)
	.img 
		width 100%
		height 100%
		font-size 0
		position absolute
		left 0
		top 0
		z-index -1
		img
			width 100%
			height 100%
			object-fit cover
		&:before
			content ''
			display block
			width 100%
			height 100%
			position absolute
			top 0
			left 0
			background linear-gradient(to top, rgba(0,0,0,0) 20%, #000 110%)
.online-consultation.--with-image
	max-width 100%
	width 100%
	position relative
	border-radius 15px
	background none
	padding 2.5rem 0
	&:before
		content ''
		display block
		width 100%
		height 100%
		position absolute
		top 0
		left 0
		background rgba(1,15,52,.7)
		border-radius 15px
	img
		width 100%
		height 100%
		object-fit cover
		z-index -1
		position absolute
		left 0
		top 0
		border-radius 15px
	.item
		z-index 1
		&:first-child
			padding 0 0 0 50px
			text-align left
			h4
				font-size 22px
		a
			max-width 231px
			margin 0 auto
		&:last-child
				justify-self center
.online-consultation.--variant-color
	width 100%
	max-width 100%
	background none
	padding 1.5rem 0
	border 1px solid lightgray
	position relative
	border-radius 15px
	.item
		&:first-child
			padding 0 0 0 50px
			text-align left
			h4
				font-size 22px
				color marine
		&:last-child
			justify-self center
.slide-informativo
	display grid
	grid-template-columns 60% 40%
	background smoke
	.slide-informativo-left
		background smoke
		padding 0 80px 0 40px
		align-self center
		h3
			color marine
			font-size 24px
		p
			color oxford
			line-height 20px
	.slide-informativo-right
		background marine
		text-align center
		color #fff
		box-sizing border-box
		padding 40px 40px 30px 40px
		img
			width 200px
			height 200px
			border-radius 50%
			margin 0 auto
		h4
			font-family indivisaBold
	.slick-dots
		display inline-flex
		li
			margin 5px
			cursor pointer
			&.slick-active
				.dot
					background lightBlue
	.dot
		width 10px
		height 10px
		background rgba(lightBlue,.1)
		border-radius 50%
		display inline-block
.infografia
	text-align center
	h2
		display inline-block
	p
		width 50%
		color marine
		margin 0 auto
	.infografia-content
		display grid
		grid-template-columns 1fr 1fr 1fr
		margin 50px 0
		grid-gap 0 20px
		.icono
			width 60px
			min-width 60px
			height 60px
			line-height 60px
			border-radius 50%
			background red
			font-size 35px
			color #fff
			span
				line-height 60px
				padding-right 0
				padding-left 0
		ul
			display flex
			height 100%
			justify-content space-around
			flex-flow column
			font-family indivisaBold
			color marine
			li
				display flex
				align-items center
				justify-content flex-end
				span
					line-height 24px
			&:first-child
				li
					flex-direction row
					span
						text-align right
						padding-right 20px
				.icono
					span
						padding-right 0
			&:last-child
				li
					flex-direction row-reverse
					span
						text-align left
						padding-left 20px
				.icono
					span
						padding-left 0
	a.btn
		margin 0 auto
		border 1px solid marine
		color marine
		&:hover
			color #fff
			border 1px solid white
.div-imagen-iconos
	display grid
	grid-template-columns 2fr 1fr 1fr 1fr
	grid-template-rows auto auto
	place-items center
	place-content center
	.imagen
		grid-column 1/2
		grid-row 1/-1
	.grid-item
		text-align center
		width 100%
		height 100%
		display flex
		flex-flow column
		align-items center
		justify-content center
		&:nth-child(3)
			border-left 1px solid lightgray
			border-right 1px solid lightgray
		&:nth-child(5)
			border-top 1px solid lightgray
		&:nth-child(6)
			border-left 1px solid lightgray
			border-right 1px solid lightgray
			border-top 1px solid lightgray
		&:nth-child(7)
			border-top 1px solid lightgray
	a
		color medgray
		font-family indivisaRegular
		.icon
			font-size 80px
			color marine
			display block
			width 100%
			text-align center
			margin-bottom 10px
			&.ym
				font-size 70px
		span
			position relative
			display block
			&:before
				content '\e929'
				font-family 'lasalle'
				color red
				position absolute
				left 100%
				margin-left 5px
				top 50%
				margin-top -3px
				transform translateY(-50%)
				font-size 18px
				transition 0.6s all ease
		&:hover
			.icon
				color lightmarine
			span
				&:before
					margin-left 15px
					transition-delay 0.2s
.bannerFull-imagen
	position relative
	margin-bottom 4.5rem
	overflow hidden
	.img
		width 100%
		height 100%
		font-size 0
		position absolute
		top 50%
		left 50%
		transform translate(-50%, -50%)
		img
			width 100%
			height 100%
			object-fit cover
	.container
		position relative
		top 50%
		transform translateY(-50%)
	.cardInfo
		width 41.5%
		min-height 270px
		background smoke
		padding 97px 54px 20px
		h2
			font-size 21px
			line-height 29px
			color marine
			margin 0
			text-transform uppercase
		p
			line-height 20px
			color marine
.gridSection
	height 600px
	position relative
	margin-bottom 4.5rem
	.imgGrid
		position absolute
		top 0
		left 0
		width 50%
		overflow hidden
		img
			width 100%
			height 100%
			object-fit cover
	.gridOne
		width 50%
		height 600px
		font-size 0
		float left
	.gridTwo,
	.gridThree
		width 50%
		height 300px
		font-size 0
		float left
	.infoGrid
		width 50%
		height 600px
		background marine
		color white
		position relative
		box-sizing border-box
		padding 115px 0 40px 83px
		margin 0 0 0 auto
		h2
			font-size 36px
			line-height 60px
		p
			line-height 24px
		.btn
			padding 15px 51px
			margin-top 2rem
		&:before
			content ''
			display block
			width 100%
			height 100%
			background marine
			position absolute
			top 0
			left 100%
			margin-left -1px
.div-imagen
	display flex
	flex 1

.cardImgLink
	width:33.3%
	position relative
	height 600px
	cursor pointer
	overflow hidden
	a
		display block
		font-size 0
		height 100%
		width 100%
		position relative
		&:before
			position absolute
			width 100%
			height 100%
			display block
			content ""
			background linear-gradient(to bottom, rgba(0,0,0,0) 75%, rgba(marine,0.65) 100%)
			z-index 2
		&:after
			display none
		img
			position absolute
			left 0
			top 0
			width 100%
			height 100%
			object-fit cover
			z-index 0
			transition all .3s ease-out
		span
			font-size 30px
			color white
			z-index 2
			margin 0
			font-family indivisaRegular
			transition all .3s ease-out
			top 0
			left 0-95px
			width 100%
			height 100%
			display block
			position absolute
			writing-mode: vertical-lr
			transform: rotate(180deg) translateX(80%)
			box-sizing border-box
			padding 25px 20px
		&:hover
			img
				transform scale(1.2)
			h3
				font-family indivisaBold
.slide-preview-link
	display grid
	grid-template-columns 45% 40%
	position relative
	grid-gap 0 15%
	.slide-preview-left
		grid-column 1
		grid-row 1/3
		.slide
			height 400px
			img
				width 100%
				height 100%
				object-fit cover
	.slide-preview-right
		align-self end
		transform translateY(-60px)
		position relative
		grid-column 2
		.slide
			margin-left 10px
	.slide-texts
		grid-column 2/-1
		grid-row 1
		transform translateX(-20%)
		h2
			margin-bottom 10px
			&:after
				display none
		a
			cursor pointer

	.arrows
		position absolute
		bottom 0
		right 0
		display flex
		div
			width 35px
			height 35px
			text-align center
			background lightgray
			cursor pointer
			position relative
			transition all .3s ease-out
			&:hover
				background navy
				span
					color white
			span
				position absolute
				left 0
				right 0
				top 50%
				transform translateY(-50%)
				color oxford
				transition all .3s ease-out
		.prev-arrow
			margin-right 20px
			span
				transform rotate(180deg) translateY(45%)
.twoColumns-image
	margin 3rem 0
	display grid
	grid-template-columns 49% 49%
	grid-gap 25px
	justify-content center
	.columnImg
		max-width 467px
		margin 0 auto
		.img
			width 100%
			font-size 0
			img
				width 100%
				height 100%
				object-fit cover
		.description
			h3
				font-size 24px
				color marine
				padding 4rem 0 1.5rem
				margin 0
			p
				line-height 24px
				color oxford
.blockLinks
	margin 2.5rem 0
	display grid
	grid-template-columns 46% 48%
	grid-gap 6%
	justify-content center
	.blockLi
		justify-self center
		align-self center
	.info-links
		h2
			font-size 48px
			line-height inherit
			color marine
			padding-bottom 1rem
			margin 0
		p
			font-size 18px
			line-height 24px
			color gray
.linkCards-info
	display grid
	grid-template-columns 50% 50%
	grid-gap 6%
	justify-content center
	.cardLink
		align-self center
		&:first-child
			grid-column 1
			grid-row 1 / 3
		&:last-child
			grid-column 2 / 3
.cardLink
	max-width 200px
	border 1px solid lightgray
	border-radius 10px
	padding 27px 15px 42px
	box-sizing border-box
	.icon
		display block
		width 51px
		height 51px
		font-size 28px
		line-height inherit
		color white
		background red
		border-radius 50%
		position relative
		margin-bottom 16px
		&:before
			position absolute
			top 50%
			left 50%
			transform translate(-50%, -50%)
		&.biblioteca
			font-size 32px
	h3
		font-size 16px
		color marine
		margin 0
	p
		font-size 14px
		line-height 18px
		color gray
		margin 10px 0
	.arrow
		top 14px
		right 22px
		float right
.backgroundMarine
	background marine
	position relative
.hedaerSlider
	color white
	// max-width 410px
	padding-bottom 3.5rem
	.container
		padding-right 35rem
	.subtitle
		color white
		padding-top 3.5rem
	p
		line-height 24px
		color white
.bodySliders
	position relative
	.sliderText
		width 61%
		background smoke
		padding 6.5rem 5rem 4.6rem 0
		position relative
		// top 12px
		z-index 1
		h3
			font-size 40px
			line-height inherit
			color marine
			margin 0 0 1.7rem
		p
			color marine
			line-height 24px
		.slider
			margin-right 20px
		&:before
			content ''
			display block
			width 100%
			height 100%
			background smoke
			position absolute
			top 0
			right 100%
			margin-right -1px
		.slick-arrow
			height 20px
			cursor pointer
			background marine
			border 0
			border-radius 0
			padding .85rem 1rem
			position absolute
			bottom -22px
			&:before
				content '\e942'
				font-family 'lasalle'
				font-style normal
				font-size 20px
				color white
			&.slick-prev
				right 112px
			&.slick-next
				right 15px
				&:before
					content '\e929'
		.indicador
			height 20px
			position absolute
			right 67px
			bottom -22px
			background marine
			font-size 14px
			line-height 22px
			color white
			padding .85rem .75rem
	.sliderImg
		width 48%
		position absolute
		bottom 15px
		right 0
		overflow hidden
		.slider
			width 100%
			height auto
			img
				width 100%
				height 600px
				object-fit cover

.slider-index
	font-size 0
	>div
		display inline-block
		width 50%
		vertical-align middle
.slide-index-text
	position relative
	padding-bottom 70px
	.slick-arrow
		width 40px
		height 40px
		border none
		font-size 0
		position absolute
		bottom 0
		cursor pointer
		&:after
			font-family 'lasalle'
			width 40px
			height 40px
			line-height 40px
			font-size 24px
			text-align center
		&.slick-prev
			background smoke
			&:after
				content '\e942'
				color gray
			&:hover
				background lightgray
		&.slick-next
			background marine
			left 55px
			&:after
				content '\e929'
				color white
			&:hover
				background darkmarine
	.slide-index-info
		padding-top 20px
		width 90%
		h3
			color marine
			margin 0
			font-size 40px
		p
			color marine
			font-size 16px
			line-height 20px
		.arrow
			margin-top 10px
			font-size 16px
			display inline-block
.slide-index-img
	z-index 2
	img
		//transform scale(0.8)
		position relative
.slideImagesIndex
	position relative
	.fakeBack
		position absolute
		top 0
		left 0
		width 100%
		height 100%
		img
			position absolute
			top 0
			left 0
			width 100%
			height 100%
.cardMenu
	max-width 240px
	margin 0 auto 2px
	position relative
	overflow hidden
	.img
		display block
		width 100%
		height 100%
		font-size 0
		position relative
		&:before
			content ''
			display block
			width 100%
			height 100%
			position absolute
			bottom 0
			background linear-gradient(to bottom, rgba(0,0,0,0) 50%, #010F34 100%)
			z-index 1
	&:hover
		img
			transform scale(1.1)
	img
		width 100%
		height 100%
		object-fit cover
		transition 0.6s all ease
	.info
		width 65%
		position absolute
		bottom 20px
		left 17px
		z-index 1
		a
			color white
			&:after
				display none
		h3
			font-size 16px
			line-height 18px
			margin 0
.gridCards
	max-width 100%
	margin 4rem auto
	overflow hidden
	display flex
	flex-wrap wrap
	justify-content center
	.grid-colum
		width calc(99% / 4)
		margin-right 2px
		&:last-child
			margin-right 0
		&:nth-child(odd)
			margin-top 8.2rem
.colorButtoms
	padding 5.2rem 0
	h2
		display inline-block
		font-size 60px
		line-height 60px
		color marine
		position relative
		left 50%
		transform translateX(-50%)
		~ p
			max-width 465px
			font-size 18px
			line-height 24px
			color gray
			text-align center
			margin 25px auto 60px
.listColors
	max-width 100%
	margin 0 auto
	display grid
	grid-template-columns 23.5% 23.5% 23.5% 23.5%
	grid-gap 2%
	row-gap 1.6rem
	overflow hidden
	.colorCard
		width 100%
		max-width 220px
		min-height 100px
		background white
		border-radius 10px
		justify-self center
		a
			display block
			width 100%
			min-height 100px
			font-family indivisaRegular
			color oxford
			position relative
			&:after
				display none
			&:before
				content ''
				display block
				width 37%
				height 5px
				border-radius 10px
				position absolute
				top 0
				left 50%
				transform translateX(-50%)
				transition 0.5s all ease
			&:hover
				color white
				&:before
					width 100%
					min-height 100px
		p
			width 85%
			font-size 20px
			text-align center
			margin 0
			position absolute
			top 50%
			left 50%
			transform translate(-50%, -50%)
		&.limegreen
			a
				&:before
					background limegreen
		&.softblue
			a
				&:before
					background softblue
		&.purplebase
			a
				&:before
					background purplebase
		&.strongred
			a
				&:before
					background strongred
		&.orange
			a
				&:before
					background orange
		&.marine
			a
				&:before
					background marine
.bannerFull-filtros
	position relative
	margin -1.9rem 0 0
	.img
		width 100%
		height 100%
		font-size 0
		position absolute
		top 50%
		left 50%
		transform translate(-50%, -50%)
		overflow hidden
		img
			width 100%
			height 100%
			object-fit cover
		&:before
			content ''
			display block
			width 100%
			height 100%
			background rgba(1, 15, 52, .7)
			position absolute
			top 0
			left 0
	.container
		position relative
		top 50%
		transform translateY(-50%)
	.infoMenu-full
		color white
		h1
			font-size 80px
			line-height 60px
			text-align center
			margin-bottom 1.3rem
		p
			max-width 560px
			font-size 18px
			line-height 24px
			text-align center
			margin 0 auto
.menuBanner
	width 100%
	height 80px
	margin -2.5rem 0 4rem
	position relative
	transition transform .8s cubic-bezier(.165,.84,.44,1)
	&.search
		.menu-Search 
			.filters-search
				transform translateX(0)
	&.open 
		transform translateY(-40px)
		.menu-filters 
			&.second 
				pointer-events auto
				opacity 1
				transition opacity .8s cubic-bezier(.25,.46,.45,.94) .3s
			.filters 
				&.btnMas 
					a 
						i 
							transform translate(-50%, -50%) rotate(45deg)
	.menu-filters
		width 100%
		display flex
		flex-wrap wrap
		align-items center
		box-shadow 15px 0px 20px #00000029
		&.second 
			border-top 1px solid lightgray
			opacity 0
			pointer-events none
			box-shadow 15px 0px 20px #00000029
			transition opacity .2s cubic-bezier(.165,.84,.44,1) 0s;
			.filters 
				&:last-child
					width calc(100% / 3)
					border-right 0
		.filters
			width calc(100% / 3)
			background white
			border-right 1px solid lightgray
			position relative
			box-sizing border-box
			&:last-child
				width 17%
			a
				display block
				width 100%
				height 100%
				font-family indivisaRegular
				font-size 18px
				line-height 60px
				color marine
				text-align center
				padding .61rem 0
				transition 0.5s all ease
				&:after
					display none
				i
					font-size 26px
					vertical-align sub
					&.deportivo
						font-size 30px
					&.mas
						font-size 32px
				&:hover 
					background marine
					color white
			&.btnMas 
				a 
					min-height 79.5px
					padding 0
					position relative
					transition 0.5s all ease
					i 
						position absolute
						top 50%
						left 50%
						transition 0.5s all ease
						transform translate(-50%, -50%)
			.dropdown-submenu 
				max-height 400px
				position absolute
				top auto
				bottom 100%
				width 100%
				background white
				padding 15px
				overflow-y auto
				box-sizing border-box
				z-index 10
				box-shadow 15px 0px 20px #00000029
				transform scaleY(0)
				transform-origin 50% 100%
				transition transform .5s cubic-bezier(.165,.84,.44,1)
				ul 
					opacity 0
					transition opacity 0s cubic-bezier(.165,.84,.44,1) 0s
					li 
						border-bottom 1px solid lightgray
						margin-bottom 0
						&:last-child 
							border-bottom 0
					a 
						line-height initial
						text-align left
						padding 0.5rem 1rem
						box-sizing border-box
						transition 0.2s all ease
			&.open 
				> a 
					color white
					background marine
				.dropdown-submenu 
					transform scaleY(1)
					ul 
						opacity 1
						transition opacity .5s cubic-bezier(.25,.46,.45,.94) .35s
		&.scrolling 
			.filters 
				.dropdown-submenu 
					top 100%
					bottom auto
					transform-origin 50% 0
	.menu-Search
		width 100%
		max-width 940px
		position absolute
		top 0
		left 0
		overflow hidden
		.filters-search
			background white
			display flex
			flex-wrap wrap
			justify-content flex-end
			align-items center
			// transform translateX(773px)
			transform translateX(83%)
			transition transform 1s cubic-bezier(.77,0,.175,1) 0s
		.filters
			width 17%
			&:nth-child(2)
				width 65%
				padding 5px 10px
				border-left 1px solid lightgray
				box-sizing border-box
			&:last-child
				width 18%
			a
				display block
				width 100%
				min-height 80px
				position relative
				transition 0.5s all ease
				&:after
					display none
				i
					font-size 36px
					position absolute
					top 50%
					left 50%
					transition 0.5s all ease
					transform translate(-50%, -50%)
					&.mas
						transform translate(-50%, -50%) rotate(45deg)
				&:hover
					color white
					background marine
					i
						&.mas
							transform translate(-50%, -50%) rotate(135deg)
			input
				width 100%
				height 70px
				font-size 18px
.menu-tabsSlide 
	margin 3.5rem 0 5rem
	.subtitle 
		&:after
			top 100%
			bottom unset
	.menuMobile 
		font-family indivisaSerif
		font-size 16px
		color marine
		border 2px solid medgray
		border-left-color red
		padding 10px 30px 10px 10px
		position relative
		cursor pointer
		display none
		&:after 
			content '\e902'
			font-family 'lasalle'
			font-size 20px
			color red
			position absolute
			top 50%
			right 5px
			transform translateY(-50%)
			transition 0.6s all ease
	.tabsNav 
		margin-bottom 67px
		position relative
		> ul 
			border-bottom 1px solid medgray
			padding-bottom 19px
			display grid
			grid-template-columns 14.5% 14.5% 14.5% 14.5% 14.5% 14.5%
			grid-gap 2.6%
			> li 
				place-self center
				margin 0
				a 
					font-family indivisaRegular
					font-size 18px
					color gray
					&:after
						display none
				&.active 
					a 
						font-family indivisaBold
						color marine
				&:hover 
					a
						font-family indivisaBold
						color marine
		.deslizador 
			display block
			width 70px
			height 2px
			background strongred
			border-radius 6px
			position absolute
			top 95%
			left 33px
	.tabsContent 
		> ul
			> li 
				display none
				&.active 
					display block
	.item 
		display flex
		flex-wrap wrap
		justify-content space-between
		align-items end
		.imgLeft 
			max-width 450px
			width 47%
			img 
				width 100%
				height 100%
				object-fit cover
		.infoRight
			max-width 470px 
			width 48%
			.logo
				max-width 170px
				padding-top 1rem
				img 
					width 100%
					height 100%
					object-fit cover
			h2 
				font-size 30px
				color marine
				padding 2rem 0 1.5rem
			p 
				line-height 24px
				color marine
				margin-top 0
			.arrow 
				font-size 18px
				color marine
.sliderImgTexto 
	.slick-arrow
		width 35px
		height 35px
		cursor pointer
		background marine
		border 0
		border-radius 0
		position absolute
		bottom 0
		z-index 1
		&:before
			content '\e942'
			font-family 'lasalle'
			font-style normal
			font-size 20px
			color white
			position absolute
			top 50%
			left 50%
			transform translate(-50%, -50%)
		&.slick-prev
			right 55px
		&.slick-next
			right 0
			&:before
				content '\e929'
		&.slick-disabled 
			background rgba(221, 221, 221, .2)
			&:before 
				color gray
				opacity 1
.cardSliderMenu 
	position relative
	margin 5rem 0 10rem
	.blockMenu 
		display block
		width 31%
		height 680px
		background marine
		position absolute
		top 110px
		left 0
		z-index -1
	.subtitle 
		&:after 
			top 100%
			bottom unset
.menu-tabsSlideCards 
	margin 6.5rem 0 0
	.menuMobile 
		font-family indivisaSerif
		font-size 16px
		color marine
		border 2px solid medgray
		border-left-color red
		padding 10px 30px 10px 10px
		position relative
		cursor pointer
		display none
		&:after 
			content '\e902'
			font-family 'lasalle'
			font-size 20px
			color red
			position absolute
			top 50%
			right 5px
			transform translateY(-50%)
			transition 0.6s all ease
	.tabsNav 
		width 83.5%
		margin 0 0 4.5rem auto
		> ul 
			display flex
			flex-wrap wrap
			> li 
				width 26%
				padding-right 6%
				margin 0 
				a 
					font-family indivisaRegular
					font-size 21px
					color gray
					padding 10px 0
					&:hover 
						&:after 
							width 60%
				&.active 
					a 
						font-family indivisaBold
						color marine
						&:after 
							width 60%
				&:hover 
					a
						font-family indivisaBold
						color marine
	.tabsContent 
		> ul
			> li 
				display none
				&.active 
					display block
.sildeCards-menu 
	.slick-track 
		transition 1.3s all ease !important
	.slick-list 
		width 2000px
	.slick-arrow
		width 55px
		height 55px
		cursor pointer
		background white
		border 0
		border-radius 0
		position absolute
		left -10.8rem
		z-index 1
		&:before
			content '\e942'
			font-family 'lasalle'
			font-style normal
			font-size 28px
			color strongred
			position absolute
			top 50%
			left 50%
			transform translate(-50%, -50%)
		&.slick-prev
			bottom 15rem
		&.slick-next
			bottom 10rem
			&:before
				content '\e929'
		&.slick-disabled 
			background #344a85
			&:before 
				color white
	.slide 
		margin 0 30px 0 0
	.slideCard 
		max-width 380px
		margin 0
		.img 
			height 210px
			overflow hidden
			img 
				width 100%
				height 100%
				object-fit cover
				transition 0.3s all ease
			a 
				display block
				width 100%
				height 100%
				font-size 0
				&:hover
					img 
						transform scale(1.2)
		.info 
			background smoke
			padding 2.5rem 1.5rem
			h2 
				font-size 24px
				line-height initial
				color marine
				margin-bottom 1.5rem
			p 
				font-size 18px
				line-height 24px
				color gray
			.arrow 
				font-size 18px
				color #1A3376
				&:before
					font-size 21px
.bannerSlider-vertical,
.bannerSlider-horizontal
	position relative
	.navSlider 
		position absolute
		bottom 4rem
		right 10rem
		a
			display block
			width 45px
			height 45px
			cursor pointer
			background white
			border 0
			border-radius 0
			font-size 24px
			text-align center
			line-height 40px
			z-index 1
			.icon 
				position absolute
				top 50%
				left 50%
				transform translate(-50%, -50%)
			&:after
				display none
			&.btnPrev-sliderV
				left 20px
			&.btnNext-sliderV
				left 60px
.sliderH-img 
	.img 
		width 100%
		position relative
		top 0
		left 0
		font-size 0
		z-index -1
		&:before
			content ''
			display block
			width 100%
			height 100%
			position absolute
			bottom 0
			background linear-gradient(to bottom, rgba(0,0,0,0) 30%, #000F34 130%)
	img 
		width 100%
		height 100%
		object-fit cover
	.container 
		position relative
		.description 
			max-width 55%
			position absolute
			left 0
			bottom 3.5rem
			color white
			.subtitle 
				color white
				margin 0 0 22px
				&:after 
					display none
			p 
				font-size 18px
				line-height 24px
				margin-bottom 1.8rem
			.arrow 
				font-size 18px
				color white
				&:before
					font-size 21px
					color white
.sliderV-options 
	max-width 200px
	position absolute
	top 50%
	right 0
	transform translateY(-50%)
	.slick-list 
		max-height 450px
	.slick-arrow 
		width 45px
		height 45px
		font-size 0
		cursor pointer
		background #55718c
		border 0
		border-radius 0
		position absolute
		top 100%
		//margin-top: 5.5rem
		z-index 1
		transition 0.6s all ease
		&:before 
			content '\e942'
			font-family 'lasalle'
			font-style normal
			font-size 22px
			color white
			transition 0.6s all ease
			position absolute
			top 50%
			left 50%
			transform translate(-50%, -50%)
		&.slick-prev 
			left 10px
		&.slick-next 
			left 80px
			&:before
				content '\e929'
		&.slick-disabled 
			background #55718c
			border 0
			&:before 
				color white
		&:hover
			background white
			&:before
				color strongred
	.item 
		max-width 200px
		position relative
		margin-bottom 20px
		&:hover 
			.img 
				transform translate(25%, 0)
			.hoverTutulo 
				left 0
				z-index 1
	.img 
		z-index 1
		transform translate(0, 0)
		transition 0.8s all ease
		height 130px
		overflow hidden
		img 
			width 100%
			height 100%
			object-fit cover
	.hoverTutulo 
		position absolute
		top 50%
		left 5%
		transform translateY(-50%)
		transition 0.8s all ease
		z-index -1
		a 
			font-size 18px
			color white
			writing-mode vertical-rl
			transform rotate(180deg)
			&:after 
				display none
.sliderImg-horizontal 
	.img 
		width 100%
		position relative
		top 0
		left 0
		font-size 0
		z-index -1
		&:before
			content ''
			display block
			width 100%
			height 100%
			position absolute
			bottom 0
			background rgba(1, 15, 52, .6)
	img 
		width 100%
		height 100%
		object-fit cover
	.container 
		position relative
		.description 
			max-width 55%
			position absolute
			left 0
			bottom 18rem
			color white
			.subtitle 
				color white
				margin 0 0 22px
				&:after 
					display none
			p 
				font-size 18px
				line-height 24px
				margin-bottom 1.8rem
			.arrow 
				font-size 18px
				color white
				&:before
					font-size 21px
					color white
.sliderH-options 
	position absolute
	bottom 2.5rem
	.slick-list 
		width 2000px
		margin-left 11.3%
	.item 
		max-width 250px
		margin-right 30px
		overflow hidden
		height 160px
	a 
		display block
		width 100%
		height 100%
		font-size 0
		img 
			width 100%
			height 100%
			object-fit cover
			transition 0.3s all ease
		&:hover
			transform scale(1.2)
	.slick-arrow 
		width 45px
		height 45px
		font-size 0
		cursor pointer
		background #808798
		border 0
		border-radius 0
		position absolute
		top 50%
		transform translateY(-50%)
		z-index 1
		transition 0.6s all ease
		&:before 
			content '\e942'
			font-family 'lasalle'
			font-style normal
			font-size 22px
			color white
			position absolute
			transition 0.6s all ease
			top 50%
			left 50%
			transform translate(-50%, -50%)
		&.slick-prev 
			left -1.1rem
		&.slick-next 
			left 3.2rem
			&:before
				content '\e929'
		&.slick-disabled,
		&:hover
			background white
			&:before 
				color strongred

@media(max-width 1699px)
	.cardSliderMenu 
		.blockMenu 
			width 28.6%
@media(max-width 1499px)
	.cardSliderMenu 
		.blockMenu 
			width 25.6%
@media(max-width 1299px)
	.cardSliderMenu 
		.blockMenu 
			width 22%
	.sildeCards-menu 
		.slick-arrow 
			left -5.8rem
@media(max-width 1099px)
	.menu-tabsSlideCards 
		.tabsNav 
			width 100%
			margin 0 auto 4.5rem
			> ul 
				> li 
					width 25%
					padding-right 5%
	.cardSliderMenu 
		.blockMenu 
			position relative
			width 80%
			height 348px
			top unset
			bottom 12rem
			margin 0 auto
	.sildeCards-menu 
		.slick-arrow 
			&.slick-prev 
				bottom -7rem
				left 50%
				transform translateX(-70px)
			&.slick-next 
				bottom -7rem
				left 50%
				transform translateX(20px)
@media(max-width tablet-landscape)
	.dropSelect
		display grid
		grid-template-columns 45% 55%
	.carouselBackFull
		.carouselBackFullWrapper
			.flechaPrev
				left 5px
			.flechaNext
				right 5px
	.sliderH-img 
		.container 
			.description 
				left 20px
	.sliderImg-horizontal 
		.container 
			.description 
				left 20px
	.sliderH-options 
		.slick-arrow 
			&.slick-prev 
				left 0
			&.slick-next
				left 4.3rem
	.two-columns-img-cta 
		.colums 
			padding 60px 10% 60px 60px
@media(max-width tablet-portrait - 100)
	.hedaerSlider
		.container
			padding-right 28rem
	.menu-tabsSlide 
		.tabsNav 
			> ul 
				grid-template-columns 15% 15% 15% 15% 15% 15%
				grid-gap 2%
@media(max-width tablet)
	.carouselBackFull
		.carouselBackDesc
			padding 0
		.carouselBackFullWrapper
			grid-template-columns 100%
			.slide
				margin 0 10px
				text-align center
				img
					width 180px
					height 150px
					object-fit cover
			.flechaPrev
				left 0px
			.flechaNext
				right 0px
			.carouselWithBack
				margin 20px 0
	.slide-index-img 
		.slick-arrow
			width 40px
			height 40px
			border none
			font-size 0
			position absolute
			top 100%
			cursor pointer
			margin-top 4.5rem
			z-index 1
			&:after
				font-family 'lasalle'
				width 40px
				height 40px
				line-height 40px
				font-size 24px
				text-align center
			&.slick-prev
				background smoke
				left 50%
				transform translateX(-45px)
				&:after
					content '\e942'
					color gray
				&:hover
					background lightgray
			&.slick-next
				background marine
				left 50%
				transform translateX(15px)
				&:after
					content '\e929'
					color white
				&:hover
					background darkmarine
	.two-columns-img-cta
		display flex
		.colums
			padding 60px 30px
	.slide-informativo
		grid-template-columns 50% 50%
		.slide-informativo-left
			padding 30px
	.div-imagen-iconos
		grid-template-columns repeat(3,1fr)
		.imagen
			grid-column 1/-1
			grid-row 1
		.grid-item
			padding: 20px 0
			box-sizing border-box
	.div-imagen
		display flex
		flex 1
		flex-flow column
	.cardImgLink
		width 100%
		height auto
		margin 20px 0
		box-sizing border-box
		height 315px
		background marine
		a
			display flex
			align-items center
			img
				position relative
				transform none
				object-fit unset
				width auto
				height 100%
			span
				transform rotate(0)
				position relative
				color marine
				display inline-block
				left 0
				bottom 0
				text-align center
				color white
				flex-grow 2
				font-size 42px
				transform: none;
				writing-mode horizontal-tb
				height auto
	.gridSection
		height auto
		background marine
		.imgGrid
			width 100%
			position relative
		.infoGrid
			width 100%
			height auto
			padding 80px 0
			margin 0 auto
			&:before
				display none
	.blockLinks
		grid-template-columns 100%
		grid-gap 3rem
		.info-links
			h2
				font-size 36px
			p
				font-size 16px
	.linkCards-info
		display block
		.cardLink
			margin 0 30px
	.slide-preview-link
		display grid
		grid-template-columns 100%
		position relative
		grid-gap 30px 0
		.slide-preview-left
			grid-column 1
			grid-row 2
			.slide
				height auto
				img
					width 100%
					height 100%
					object-fit cover
		.slide-preview-right
			align-self end
			position relative
			grid-column 1
			grid-row 3
			transform translate(0)
			.slide
				margin 0 1%
		.slide-texts
			grid-column 1
			grid-row 1
			transform translate(0)
			h2
				margin-bottom 10px
			a
				cursor pointer

		.arrows
			position absolute
			bottom 0
			right 4%
			display flex
			div
				width 35px
				height 35px
				text-align center
				background lightgray
				cursor pointer
				position relative
				transition all .3s ease-out
				&:hover
					background navy
					span
						color white
				span
					position absolute
					left 0
					right 0
					top 50%
					transform translateY(-50%)
					color oxford
					transition all .3s ease-out
			.prev-arrow
				margin-right 20px
				span
					transform rotate(180deg) translateY(45%)
	.slide-index
		.slide
			display grid
			grid-template-columns 100%
	.hedaerSlider
		.container
			padding-right 20px
	.bodySliders
		background smoke
		.sliderText
			width 100%
			padding 6.5rem 0rem 4.6rem
			.slider
				margin-right 0
			.slick-arrow 
				&.slick-next
					right 20px
				&.slick-prev 
					right 111px
		.sliderImg
			width 100%
			position relative
			bottom 0
			.slider
				img
					height auto
	.slider-index 
		padding-bottom 7rem
		>div
			display block
			width 100%
			margin 20px 0
	.slide-index-text 
		padding-bottom 20px
	.cardMenu
		.info
			width 80%
	.listColors
		grid-template-columns 30.6% 30.6% 30.6%
		grid-gap 4%
		row-gap 1.6rem
	.menuBanner 
		.menu-filters 
			.filters 
				width 100%
				border-right 0
				border-bottom 1px solid lightgray
				&.btnSubmenu 
					display none
					opacity 0
					pointer-events none
					transition opacity 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) 0s
				&.open 
					.dropdown-submenu 
						position relative
				&:first-child 
					order 1
				&:nth-child(2) 
					order 2
				&:last-child 
					width 50%
					border-right 1px solid lightgray
					order 0
				.dropdown-submenu 
					box-shadow none
			&.second 
				border-top 0
				.filters 
					width 100%
					&:first-child 
						order 1
					&:nth-child(2) 
						order 2
					&:last-child 
						display block
						width 100%
						order 3
		&.open 
			height auto
		&.mobile 
			.menu-filters 
				.filters 
					&.btnSubmenu 
						display block
						pointer-events auto
						opacity 1
						transition opacity .8s cubic-bezier(.25,.46,.45,.94) .3s
		.menu-Search 
			.filters-search 
				transform translateX(33%)
			.filters 
				width 50%
				border-bottom 1px solid lightgray
				&:nth-child(2) 
					width 15%
				&:last-child
					width 18%
		&.search 
			.menu-filters
				.filters 
					border-bottom 0
			.menu-Search
				.filters 
					width 17%
					border-bottom 0
					&:nth-child(2) 
						width 65%
					&:last-child 
						width 18%
	.menu-tabsSlide 
		.menuMobile 
			display block
			&.open 
				+ ul
					height auto
					border 2px solid medgray
					border-top none
				&:after 
					transform translateY(-50%) rotate(180deg)
		.tabsNav 
			> ul 
				display block
				border 0
				height 0
				padding-bottom 0
				overflow hidden
				> li 
					display block
					a 
						display block
						padding 12px 10px
						border-bottom 1px solid gray
					&:last-child
						a 
							border-bottom 0
			.deslizador 
				display none 
		.item 
			.imgLeft 
				width 100%
				margin 0 auto
			.infoRight 
				max-width 100%
				width 100%
				margin 0 auto
	.sliderImgTexto 
		.slick-arrow 
			bottom -30px
	.menu-tabsSlideCards 
		.menuMobile 
			display block
			&.open 
				+ ul
					height auto
					border 2px solid medgray
					border-top none
				&:after 
					transform translateY(-50%) rotate(180deg)
		.tabsNav 
			> ul 
				display block
				border 0
				height 0
				padding-bottom 0
				overflow hidden
				> li 
					display block
					width 100%
					a 
						display block
						padding 12px 10px
						border-bottom 1px solid gray
						&:after
							display none
					&:last-child
						a 
							border-bottom 0
	.sliderH-img 
		.container 
			.description 
				max-width 70%
	.sliderV-options 
		position relative
		top 0
		right unset
		max-width 100%
		height auto
		transform translateY(0)
		overflow unset
		margin 2rem 0 6rem
		.slick-list 
			width 2000px
		.slick-arrow 
			border 1px solid marine
			margin-top 2rem
			&.slick-prev 
				left 50%
				transform translateX(-60px)
			&.slick-next 
				left 50%
				transform translateX(20px)
		.item 
			margin-right 20px
			margin-bottom 0
			&:hover 
				.img 
					transform translate(0, 0)
			.hoverTutulo 
				max-width 200px
				text-align center
				position relative
				top 0
				left 0
				transform translateY(0)
				margin-top 1rem
				z-index 0
				a 
					color marine
					writing-mode horizontal-tb
					transform rotate(0deg)
	.sliderImg-horizontal 
		.container 
			.description 
				max-width 90%
				bottom 3.5rem
	.sliderH-options 
		position relative
		bottom 0
		margin 2rem 0 6rem 
		.slick-list 
			margin-left 0
		.slick-arrow 
			border 1px solid marine
			top 100%
			transform translateY(0)
			margin-top 1.5rem
			&.slick-prev
				left 50%
				transform translateX(-60px)
			&.slick-next
				left 50%
				transform translateX(20px)
			&.slick-disabled 
				border 0
@media(max-width lgmobile)
	.dropSelect
		display block
	.dropSelectGrid
		grid-template-columns 1fr
		.dropSelectGrid-item
			padding 30px
		.dropSelectGrid-item-header
			padding 0
		.dropSelectGrid-item-body
			margin-top 20px
		img
			margin 0 auto 20px auto
			display block
			position relative
			transform translateY(0)
			top 0
			width 120px
			height 120px

		a.btn
			display block
			text-align center
			max-width 220px
			margin auto
	.interactiveCards
		.interactiveCard
			height auto
			width 100%
			margin-right -70px
			min-height 580px
		img
			margin 0 auto 20px auto
			display block
			position relative
			transform translateY(0)
			top 0
			width 120px
			height 120px
		.interactiveCard--header
			padding 0
			margin-bottom 30px
	.two-columns-img-cta
		display block
	.slide-informativo
		grid-template-columns 100%
	.modulesMain
		.digitalHome
			.redes
				width 100%
				margin 0 auto
	.infografia
		p
			width 100%
		.infografia-content
			grid-template-columns 100%
			ul
				&:first-child
					li
						flex-flow row-reverse
						span
							text-align left
							padding-left 20px
						.icono
							span
								padding-left 0
	.div-imagen-iconos
		grid-template-columns repeat(2,1fr)
		.imagen
			grid-column 1/-1
			grid-row 1
		.grid-item
			&:nth-child(3)
				border-right none
			&:nth-child(4)
				border-top 1px solid lightgray
			&:nth-child(5)
				border-left 1px solid lightgray
			&:nth-child(6)
				border-right none
			&:nth-child(7)
				border-left 1px solid lightgray
	.gridCards
		.grid-colum
			width calc(98% /4)
@media(max-width mobile)
	.modulesMain
		.principios-container
			margin 50px 0
	.online-consultation.--with-image
		padding 2.5rem 1rem .5rem
		grid-template-columns 100%
		grid-template-rows auto auto
		row-gap: 30px;
		.item
			&:first-child
				padding 0
				text-align center
	.online-consultation.--variant-color
		grid-template-columns 100%
		grid-template-rows auto auto
		row-gap 30px
		.item
			&:first-child
				padding 0
				text-align center
	.bannerFull-imagen
		height auto
		background smoke
		.img
			height auto
			position relative
			top 0
			left 0
			transform translate(0, 0)
		.container
			top 0
			transform translateY(0)
		.cardInfo
			width 100%
			min-height auto
			padding 60px 0 40px
	.twoColumns-image
		grid-template-columns 100%
		grid-gap 3rem
		.columnImg
			.description
				h3
					padding 3rem 0 1rem
	.cardImgLink
		height 180px
		margin 10px 0
		a
			span
				font-size 20px
	.gridCards
		.grid-colum
			width calc(98% / 2)
			&:nth-child(odd)
				margin-top 0
			&:first-child
				margin-top 8.2rem
			&:nth-child(2)
				margin-bottom 4.2rem
			&:nth-child(3)
				margin-top -13.5rem
	.listColors
		grid-template-columns 48% 48%
	.menuBanner 
		height 60px
		margin -2rem 0 4rem
		.menu-Search 
			.filters 
				a 
					min-height 60px
				input 
					height 50px
					font-size 16px
		.menu-filters 
			.filters 
				a 
					line-height 42px
				&.btnMas 
					a 
						min-height 60px
	.cardSliderMenu 
		margin 3rem 0 5rem
		.blockMenu 
			width 85%
			height 350px
	.menu-tabsSlideCards 
		margin 4.5rem 0 0 
		.tabsNav 
			> ul 
				> li 
					a 
						font-size 16px
	.sildeCards-menu 
		.slideCard 
			max-width 350px
			.info 
				h2 
					font-size 22px
				p 
					font-size 16px
					line-height 22px
				.arrow 
					font-size 16px
	.sliderH-img 
		background marine
		.container 
			.description 
				max-width 100%
				position relative
				top 0
				left 0
				padding 1.5rem 0
				p 
					font-size 16px
					line-height 22px
				.arrow
					font-size 16px
	.sliderImg-horizontal 
		background marine
		.container 
			.description 
				max-width 100%
				position relative
				top 0
				left 0
				padding 1.5rem 0
				p 
					font-size 16px
					line-height 22px
	.sliderH-options 
		.item 
			max-width 200px
	.interactiveCards 
		.slick-arrow 
			width 45px
			height 45px
			font-size 0
			cursor pointer
			background white
			border 1px solid marine
			border-radius 0
			position absolute
			top 100%
			margin-top 2.5rem
			z-index 1
			&:before 
				content '\e942'
				font-family 'lasalle'
				font-style normal
				font-size 22px
				color strongred
				position absolute
				top 50%
				left 50%
				transform translate(-50%, -50%)
			&.slick-next 
				left 50%
				transform translateX(20px)
				&:before
					content '\e929'
			&.slick-prev 
				left 50%
				transform translateX(-60px)
			&.slick-disabled 
				background #55718c
				border 0
				&:before 
					color white
@media(max-width mobile-landscape)
	.list-winners
		li
			width 100%
			float unset
	.modulesMain
		.sectionComplete
			margin .5rem 0 3rem
	.gridSection
		.gridOne
			height 400px
		.gridTwo,
		.gridThree
			height 200px
		.infoGrid
			padding 60px 0
	.bodySliders
		.sliderText
			padding 5.5rem 0rem 4.5em
	.gridCards
		.grid-colum
			width 100%
			&:first-child
				margin-top 0
			&:nth-child(2)
				margin-bottom 0
			&:nth-child(3)
				margin-top 0
	.listColors
		grid-template-columns 100%
		grid-gap 1.5rem
	.sildeCards-menu 
		.slideCard 
			max-width 340px
@media(max-width mobile-landscape - 100) 
	.sildeCards-menu 
		.slideCard 
			max-width 280px
	.sliderV-options 
		.item 
			max-width 155px
	.sliderH-options 
		.item 
			max-width 155px
@media(max-width mobile-portrait)
	.gridSection
		.gridOne
			height 250px
		.gridTwo,
		.gridThree
			height 125px