footer
	// position fixed
	// width 100%
	// bottom 0
	// z-index 4
	.footerTop
		background marine
		padding 20px 0 5px
		a
			color white
		.container
			font-size 0
	label 
		display none
		color white
		padding 10px 0
		font-size 16px
		font-family 'indivisaRegular'
		text-align center
		position relative
		border-bottom 1px solid rgba(white, 0.2)
		cursor pointer
		&:after
			content '\e902'
			font-family 'lasalle'
			margin-left 10px
			display inline-block
			font-size 20px
			position relative
			top 2px
			transition 0.6s all ease
		&.open
			&:after
				transform rotate(180deg)
			// + ul
			// 	display block
	.sedes
		display inline-block
		vertical-align middle
		width 70%
		box-sizing border-box
		margin 0
		ul
			>li
				display inline-block
				margin-right 15px
				margin-bottom 10px
				&:last-child
					margin-right 0
		a
			font-size 13px
			font-family 'indivisaRegular'
			&:after
				background white
				height 1px
	.redes
		text-align right
		display inline-block
		vertical-align middle
		width 30%
		box-sizing border-box
		margin 0
		transform translateY(50%)
		float right
		a
			font-size 20px
			position relative
			transition 0.6s all ease
			&:after
				display none
			&:hover
				top -5px
		h6
			margin 0
			font-family 'indivisaRegular'
			color white
			font-size 12px
			display inline-block
			margin-right 15px
			vertical-align top
			padding-top 4px
		ul
			display inline-block
			vertical-align top
			>li
				display inline-block
				margin-right 2px
				margin-bottom 0
				&:last-child
					margin-right 0
	.footerBottom
		background darkmarine
		padding 10px 0 5px
		.container
			overflow hidden
	.logotipos
		span(1/4)
		width 30%
		ul
			>li
				display inline-block
				margin-right 10px
				margin-bottom 0
				&:last-child
					margin-right 0
		img
			max-width 70px
	
	

	.globalNav
		span(3/4)
		width 70%
		text-align right
		padding-top 5px
		li
			display inline-block
			vertical-align middle
			margin-right 15px
			margin-bottom 0
			border-right 1px solid white
			padding-right 15px
			&:last-child
				margin-right 0
				border-right none
				padding-right 0
			a
				color white
				font-family 'indivisaRegular'
				font-size 14px
				&:after
					background white

@media (max-width tablet-portrait)
	footer
		.logotipos,
		.globalNav
			stack()
			text-align center
			margin-bottom 10px

@media (max-width tablet)
	footer
		.redes
			transform none
			h6,
			ul
				display block
				text-align center
			h6
				margin-bottom 5px



@media (max-width mobile)
	footer
		label
			display block
		.footerTop,
		.footerBottom
			padding-top 10px
			padding-bottom 0
			.container
				padding 0
		.redes,
		.sedes
			stack()
		.redes
			padding-top 0
			text-align center
			margin-bottom 5px
			h6
				font-size 16px
				padding-top 6px
				display inline-block
				margin-bottom 0
			ul
				display inline-block
				li
					margin-right 15px
					margin-bottom 0
			a
				font-size 28px
		.sedes,
		.globalNav
			background lightmarine
			ul
				display none
				li
					display block
					text-align center
					margin 0px
					border-bottom 1px solid rgba(white, 0.2)
					background marine
					&:last-child
						border none
			a
				padding 14px 0
				display block
		.globalNav
			background darkmarine
			margin 0
			label
				border-bottom none
				border-top 1px solid rgba(white, 0.2)
				padding-bottom 15px
		.logotipos
			margin-bottom 0
			padding 10px 0
		.legales
			border-top 1px solid rgba(white, 0.2)
			ul
				background marine

@media (max-width mobile-portrait)
	footer
		.redes
			h6
				display none