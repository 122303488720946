.scrollArrow 
	width 25px
	height 45px
	overflow hidden
	a 
		display block
		width 100%
		height 100%
		color white
		position relative
		top 50%
		animation rebota .5s alternate infinite ease-out
		.arrowprev 
			font-weight bold
			font-size 20px
			position absolute
			transform rotate(-90deg)
.grandesMaestros 
	.subtitle 
		margin 50px 0px 60px
		&:after 
			top 100%
			bottom unset
	ul 
		width 100%
		display flex
		flex-wrap wrap
		li
			width 22.5%
			margin-bottom 60px
			margin-right 2.5%
			&:nth-child(4n) 
				margin-right 0
	.img 
		font-size 0
		margin-bottom 1.3rem
		img 
			width 100%
			height 100%
			object-fit cover
	.showMore 
		margin 0 0 4rem
.bannerFull-teachers 
	position relative
	.imgPerfil 
		position relative
		margin-bottom -11rem
	.img 
		width 270px
		font-size 0 
		position absolute
		bottom 4rem
		left 50%
		transform translateX(-50%)
		z-index 1
		img 
			width 100%
			height 100%
			object-fit cover
			padding 10px
			background white
			border-radius 50%
			border 1px solid marine
	.info 
		height 335px
		text-align center
		background marine
		position relative
		box-sizing border-box
		z-index -1
		h1 
			font-family indivisaBold
			font-size 30px
			line-height 18px
			color white
			padding 10rem 0 0
		h2 
			font-family indivisaBold
			font-size 21px
			line-height 26px
			color white
		p 
			font-size 18px
			line-height 26px
			color white
			margin 0
		&:before 
			content ''
			display block
			width 100%
			height 100%
			background marine
			position absolute
			top 0
			right 100%
			margin-right -1px	
		&:after 
			content ''
			display block
			width 100%
			height 100%
			background marine
			position absolute
			top 0
			left 100%
			margin-left -1px
	.scrollArrow 
		position absolute
		bottom 2.7rem
		right 5px
.contentBiografia 
	overflow auto
	margin 3.5rem 0 0
	.info 
		width 65.5%
		float left
		clear none
		text-align inherit
		margin-bottom 3.5rem
		h3 
			font-size 24px
			line-height 30px
			color darkmarine
			margin 0 0 3rem
		p 
			line-height 21px
			color marine
	.return 
		clear both
		width 75.3%
		margin 0 0 4.8rem auto
		a 
			&.arrow 
				&:before 
					content '\e942'
					right 100%
					left unset
					margin-right 5px
					margin-left 0
				&:hover 
					&:before 
						margin-right 15px
						transition-delay .2s
	.socialMediaVertical-list 
		width 9%
		float left
		clear none
		margin-right 13.5%
@media(max-width 1600px) 
	.bannerFull-teachers 
		.img 
			bottom 2.7rem
@media(max-width tablet) 
	.grandesMaestros 
		ul 
			justify-content space-evenly
			li 
				width 215px
				margin-right 0
@media(max-width mobile) 
	.contentBiografia 
		.socialMediaVertical-list,
		.info
			display block
			width 100%
			float none
			clear both
			padding 2rem 0 0
		.return 
			width 100%
			padding-left 25px
			margin 0 auto 4.8rem
@media(max-width mobile-landscape) 
	.grandesMaestros 
		ul 
			li 
				margin-bottom 50px
	.bannerFull-teachers 
		.img 
			width 220px
			bottom 4.5rem
		.info 
			h1 
				line-height 28px
				padding 8rem 0 0
		.scrollArrow 
			bottom 13.5rem
@media(max-width mobile-portrait) 
	.bannerFull-teachers 
		.img 
			width 210px
			bottom 5.5rem
			margin-left -10px
		.info 
			h1 
				padding 7rem 0 0
