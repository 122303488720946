.listImagesWithText
	.textGray
		background smoke
		width 50%
		box-sizing border-box
		padding 40px 20px
		position relative
		z-index 2
		top 100px
		
		&:after
			content ''
			display block
			width 600px
			height 100%
			position absolute
			top 0
			background smoke
		&:before
			content ''
			display block
			width 50px
			height 100%
			position absolute
			top 0
			background smoke
		h2
			color marine
			margin-bottom 30px
		p
			color marine
	.imgBg
		width 50%
		height 100%
		position absolute
		font-size 0
		overflow hidden
		img
			width 100%
			height 100%
			object-fit cover
			transition 0.6s all ease
		a
			&:hover
				img
					transform scale(1.2)
	>li
		height 500px
		position relative
		margin 150px 0
		&:first-child
			margin-top 0
		&:nth-child(even)
			.imgBg
				right 0
			.textGray
				margin-left 0
				margin-right auto
				&:after
					right 100%
				&:before
					left 100%
		&:nth-child(odd)
			.imgBg
				left 0
			.textGray
				margin-right 0
				margin-left auto
				&:after
					left 100%
				&:before
					right 100%

.programs
	.titleList
		margin-bottom 5px
		font-size 30px
		font-family 'indivisaBold'
	.subtitleList
		margin 0
		color marine
		font-family 21px
		margin-bottom 20px
		font-family 'indivisaRegular'

	.listPrograms
		margin-top 30px
		>li
			margin-bottom 40px
			&:last-child
				margin-bottom 0
	.showMore
		margin 80px 0 50px
		.icon
			top 5px
			position relative

.scheduleSection
	padding 100px 0 50px
	h2
		color marine
		font-size 24px
		margin-bottom 40px
.table-content
	overflow auto
	table
		width 100%
	>ul
		display none
		>li
			margin-bottom 20px
			background smoke
			padding 5px 20px
			li
				position relative
				margin 0
		a
			span
				display block
				width 100%
		label
			display none
		
		.icon
			background red
			border-radius 50%
			display block
			height 34px
			width 34px
			line-height 34px
			color white
			text-align center
			top 50%
			margin 0 auto 
			font-size 22px
		.icon,
		p
			display inline-block
			vertical-align middle
			margin-left 5px
.tblSchedule
	border-spacing 30px 10px
	border-collapse separate
	thead
		.icon
			background red
			border-radius 50%
			display block
			height 54px
			width 54px
			line-height 54px
			color white
			text-align center
			margin 0 auto 
			font-size 32px
		label
			font-size 16px
			color marine
			text-align center
			margin 15px 0
			font-family 'indivisaBold'
			display block
	tbody
		td
			background smoke
			color marine
			padding 25px 40px
			text-align center
			a
				display block
				span
					display block

.inversionSeccion
	padding 60px 0
	h2
		text-align center
		margin-bottom 60px
		font-size 24px
		color marine

.inscriptionSeccion
	position relative
	background smoke
	padding 30px 0
	margin 30px 0
	.bgImg
		position absolute
		height 100%
		right 0
		top 0
		width 40%
		overflow hidden
		img
			width 100%
			height 100%
			object-fit cover
	h2
		font-size 24px
		color marine
	.process
		width 50%
		ol
			padding-left 20px
			margin 20px 0
			>li
				margin-bottom 10px
				color marine
			a
				color lightBlue

.detailNota
	h1
		margin-bottom 0
	>p
		font-size 24px
		margin 15px 0

.fichaSection
	margin 80px 0

.temarioSection
	h3
		color marine
	padding 50px 0
	&.gray
		background smoke
	.listTemario
		padding-bottom 0


.rememberQuote
	padding 60px 0
	.quoteCont
		background marine
		border-radius 10px
		max-width 925px
		margin 0 auto
		display flex
		align-items center
		justify-content center
		padding 20px
		h5
			color white
			font-size 50px
			font-family 'indivisaSerif'
			margin 0
			position relative
			&:after
				content ''
				display block
				width 80px
				height 2px
				background red
				bottom -0
				left 0
				position absolute

		p
			color white
			font-size 16px
			width 55%
			margin-left 20px

.detailNota
	.titleNota
		margin-bottom 0
	>p
		margin 5px 0
		color marine
		font-size 24px

.fichaSection
	.info-topic
		h2
			color marine
			


@media (max-width tablet)
	.listImagesWithText
		.textGray
			top 10px
			&:before
				width 25px
	.tblSchedule
		border-spacing 15px 10px
	.scheduleSection
		padding 0px 0 50px

@media (max-width mobile)
	.listImagesWithText
		>li
			height auto
			margin 50px 0px
		.container
			padding 0
		.textGray
			width 100%
			top 0
			&:before,
			&:after
				display none
		.imgBg
			position relative
			width 100%
	.table-content
		table
			display none
		>ul
			display block
	.inversionSeccion 
		h2
			margin-bottom 50px
			
	.rememberQuote 
		.quoteCont
			flex-direction column
			p
				width 100%
				margin-left 0
	.inscriptionSeccion
		padding-bottom 0
		.process
			width 100%
		.bgImg
			margin-top 40px
			font-size 0
			position static
			width 100%
		
		


