.imagesWithText
	min-height 610px
	position relative
	.imgBg
		width 65%
		height 100%
		position absolute
		right 0
		font-size 0
		img
			width 100%
			height 100%
			object-fit cover
	.container
		position relative
		z-index 2
	.textBlue
		background marine
		max-width 685px
		box-sizing border-box
		padding 50px 100px 50px 0px
		position relative
		top 80px
		color white
		&:after
			content ''
			display block
			width 600px
			position absolute
			top 0
			right 100%
			height 100%
			background marine
			margin-right -1px		
		h1
			font-size 30px
			font-family 'indivisaItalicLight'
			position relative
			margin 20px 0 30px
			span 
				display block
				font-family indivisaRegular
				font-weight regular
				font-size 21px
				line-height 30px
			&:after
				content ''
				width 80px
				height 2px
				background white
				top 100%
				display block
				position absolute
				margin-top 5px
		
		.date
			background red
			padding 8px 25px 6px
			border-radius 20px
			color white
			display inline-block
			strong
				font-family 'indivisaSerif'
				vertical-align middle
				font-size 24px

		.bulletIcons
			li
				margin-bottom 2px
			.icon
				font-size 32px
				display inline-block
				vertical-align middle
				width 40px
				height 40px
				text-align center
				line-height 40px
				margin-right 10px
				&.organizador
					font-size 24px
				&.produccion 
					font-size 26px
			p
				display inline-block
				vertical-align middle
				width 80%
				margin 0
			a 
				font-family indivisaRegular
				color white
				&:after
					background white
	&.detailGroup 
		.imgBg 
			width 53%
		.textBlue 
			max-width 540px
			h1
				span 
					display block
					font-family indivisaRegular
					font-weight regular
					font-size 21px
					line-height 30px
			&.informative 
				padding 75px 120px 85px 0px
				p 
					font-size 18px
					line-height 26px
					margin-top 3.6rem
	&.titleOnly 
		.textBlue 
			top 165px
			padding 40px 100px 70px 0
			h1 
				font-size 40px
				line-height 50px
@media (max-width tablet)
	.imagesWithText
		min-height auto
		.imgBg
			width 100%
			position relative
			height auto
		.textBlue
			margin-top -300px
			top 0
		&.detailGroup 
			.imgBg 
				width 100%
			.textBlue 
				max-width 685px
	.eventsCalendar 
		.headEvents 
			.subtitle
				width 70%
			.frmMes
				width 30%
@media (max-width mobile)
	.imagesWithText
		min-height auto
		background marine
		.imgBg
			width 100%
			position relative
			height auto
		.textBlue
			margin-top 0
			padding 20px 30px 20px 0px
			&:after
				display none
		&.detailGroup 
			.textBlue
				&.informative 
					padding 75px 30px 85px 0px
	.eventsCalendar 
		.headEvents 
			.subtitle
				width 100%
			.frmMes
				margin 0px auto 40px
				width 100%
				text-align center
				display block
	.downloadCalendar 
		h4
			display block
			margin-bottom 10px
		ul
			margin-left 15px
@media (max-width 375px)
	.imagesWithText 
		.textBlue 
			padding 20px 0
			.bulletIcons 
				.icon
					width 30px
					margin-right 5px
		&.detailGroup 
			.textBlue
				&.informative 
					padding 70px 0px
	.eventsCalendar
		padding 50px 0
	.downloadCalendar 
		ul 
			li
				display block
				margin-bottom 5px
				border-left 1px solid marine
				border-right none
				&:last-child
					border-left 1px solid marine
@media (max-width mobile-portrait)
	.imagesWithText 
		.textBlue 
			.bulletIcons
				li
					margin 10px 0