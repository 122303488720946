.videoIframe
	// max-width 916px
	a 
		display block
		width 100%
		height 100%
		font-size 0
		position relative
		overflow hidden
		&:before 
			content ''
			display block
			width 100%
			height 100%
			background rgba(1,29,104,0.7)
			position absolute
			top 0
			left 0
			opacity 0
			z-index 1
			transition 0.3s all ease
		&:after 
			display none
		&:hover 
			&:before 
				opacity 1
			.icon 
				top 50%
				opacity 1
				transform translate(-50%, -50%) scale(1.2)
	.icon 
		font-size 80px
		color white
		position absolute
		top 40%
		left 50%
		transform translate(-50%, -50%)
		transition 0.3s all ease
		opacity 0
		z-index 1
	.imgIframe 
		font-size 0
		img 
			width 100%
			height 100%
			object-fit cover
	iframe 
		border 0
	&.videoThumbBox 
		margin 0 0 30px
		overflow hidden
		position relative
		height 500px
		iframe
			position absolute
			top 0
			left 0
			width 100%
			height 100%
		a 
			&:before
				background rgba(black, 0.6)
				opacity 1
			&:hover 
				&:before
					opacity .4
		.imgIframe 
			height 500px
		.icon
			font-size 60px
			top 50%
			left 50%
			opacity 1
		iframe 
			width 100%
			height 100%
			min-height 500px
.apoyosAcademicos 
	margin 4.5rem 0 4rem
	.sliderIconos 
		width 335px
		margin 0 auto 4rem
		// width 35%
		.slide 
			p 
				~ span 
					font-size 0
					color marine
					padding-left 15px
		.slick-slide 
			&:last-child 
				a 
					&:before 
						display none
				p 
					~ span 
						padding-left 0
		.slick-current 
			p 
				~ span 
					font-weight regular
					font-size 16px
					line-height 24px
				a 
					span 
						font-family indivisaItalicLight
						font-size 36px
	.contentTabsIcons 
		.content-accordion
			width 100%
			display flex
			flex-wrap wrap
		.knows-block 
			width 30%
			margin-right 4.5%
			// margin-right 42px
			&:nth-child(3n) 
				margin-right 0
			ul 
				padding 0 1rem
				p 
					font-weight regular
					line-height 24px
					color marine
		.download-list 
			padding 1.5rem 1rem 0.7rem
.listCirculares 
	margin-top 3rem
	li
		width 100% 
		border-bottom 1px solid lightgray
		padding-bottom 1.3rem
		display flex
		flex-wrap wrap
		justify-content space-between
	.description 
		width 70%
		margin-bottom 23px
		h5 
			font-family indivisaBold
			font-size 21px
			line-height 20px
			color marine
			margin 0
		p 
			margin 0
			strong 
				font-style normal !important
	.circled
		width auto
		margin-top 7px
		align-self baseline
		.icon 
			font-size 26px
			padding 5px
.listRequisitos 
	margin-bottom 40px
	ul 
		.img 
			display inline-block
			width 33px
			font-size 0
			margin-right 38px
			vertical-align middle
			img 
				width 100%
				height 100%
				object-fit cover
		p 
			display inline-block
			margin .7rem 0
			vertical-align middle
	~ .btn 
			font-size 20px
			padding 10px 43px
.listPasos 
	ul 
		li 
			font-size 16px
			line-height 21px
			color marine
			margin-bottom 22px
		strong 
			color marine
		a 
			color blueVivid
			&:after 
				background blueVivid 
			&:hover 
				&:after 
					width 100%
		ul 
			margin-top 22px
			li 
				margin-bottom 0
	~ .circled 
		display inline-block
		margin 30px 0 35px
.imgCalendarExamen 
	width 100%
	font-size 0
	margin 25px 0
	img 
		width 100%
		height 100%
		object-fit cover
.listLibros 
	ul 
		.img 
			display inline-block
			width 70px
			font-size 0
			margin .5rem 15px 0 0
			img 
				width 100%
				height 100%
				object-fit cover
		.contentLibros 
			display inline-block
			vertical-align top
			p 
				font-size 18px
				color gray !important
				margin-bottom 1.5rem
				strong 
					font-style normal !important
					color marine !important
			.copy 
				max-width 212px
				font-weight bold
				color oxford !important
				text-align center
				background smoke
				border-radius 25px
				padding 15px 0
				margin-bottom 1rem
		.ulArrows 
			a 
				font-size 18px
				color lightmarine
				&:before 
					font-weight bold
					font-size 22px
	p 
		font-size 18px
		color lightmarine !important
		margin-bottom 1.5rem
		strong 
			font-style normal !important
			color marine !important
	.btn 
		margin 1rem 0 1.5rem
		padding 10px 45px
.videoLibros 
	max-width 450px
	margin 2.5rem 0 3.3rem
	position relative
	min-height 275px
	iframe 
		width 450px
		height 255px
	.player
		position absolute
		top 0
		left 0
		width 100%
		height 100%
.datosEditorial 
	margin 3rem 0
	li 
		font-size 18px 
		color lightmarine
	a 
		color lightmarine
		&.correo 
			color #1988F7
			&:after 
				background #1988F7
			&:hover 
				&:after 
					width 100%
@media(max-width tablet) 
	.apoyosAcademicos 
		.sliderIconos 
			width 390px
			// width 50%
		.contentTabsIcons 
			.knows-block 
				width 45%
				&:nth-child(odd) 
					margin-right 42px
				&:nth-child(even) 
					margin-right 0
	.listCirculares 
		.description 
			width 100%
	.listRequisitos 
		ul 
			.img 
				display block
			p 
				display block
	.listLibros 
		ul 
			.img 
				display block
				width 80px
				margin 1.5rem 0 0 0
			.contentLibros 
				display block
@media(max-width mobile) 
	.videoIframe 
		&.videoThumbBox 
			.imgIframe 
				height 300px
			iframe 
				min-height 300px
	.videoLibros 
		max-width 350px
		iframe 
			width 350px
			height 200px
@media(max-width mobile-landscape) 
	.apoyosAcademicos 
		.sliderIconos 
			width 100%
		.contentTabsIcons 
			.knows-block 
				width 100%
				&:nth-child(odd) 
					margin-right 0
@media(max-width mobile-landscape - 99) 
	.videoLibros 
		max-width 280px
		iframe 
			width 280px
			height 160px
@media(max-width mobile-portrait) 
	.listCirculares 
		.circled 
			margin-bottom 1rem