.content-slider-records 
	padding-bottom 42px
.slider-records 
	.item-constancy 
		max-width 280px
		position relative
		&:after 
			content ''
			width 280px
			height 375px
			background marine
			position absolute
			top 0
			left 0
			z-index -1
		img 
			transform scale(.9)
	.slick-list 
		width 2000px
	.slick-slide 
		margin 0 7px
		&:first-child 
			margin 0 7px 0 0