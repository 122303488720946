.cardArt
	overflow hidden
	.img
		width 210px
		height 190px
		display inline-block
		vertical-align middle
		overflow hidden
		img
			width 100%
			height 100%
			object-fit cover
			transition 0.6s all ease
		&:hover
			img
				transform scale(1.2)
	.info
		width calc(100% - 220px)
		display inline-block
		vertical-align middle
		box-sizing border-box
		padding-left 20px
	
	h3
		border-bottom 1px solid lightgray
		margin 0
		padding 10px 0
	// .intro
	// 	margin 5px 0
	p
		font-size 18px
		line-height 24px
		color marine

@media (max-width mobile)
	.cardArt
		.img
			width 100%
			height auto
		.info
			width 100%
			padding-left 0
		