.cards-alliance 
	overflow hidden
	margin-bottom 4rem
	ul 
		li 
			width 22.95%
			float left
			margin 0 16px 30px 0
			&:nth-child(4n)
				margin 0
			&:nth-child(4n + 1) 
				clear both
			&.open 
				img 
					filter none
					opacity 1
				.viewMore 
					a 
						transform translateX(-50%) rotate(180deg)
	&.opacidad 
		margin-bottom 17.5rem
.cardAlliance 
	max-width 218px
	border 1px solid lightgray
	img 
		width 120px
		display block
		margin 0 auto
	p 
		font-size 14px
		line-height 20px
		color marine
		padding 5px 20px
		margin 0
	.img-log 
		padding 2rem 0 .5rem
	.info-card 
		display none
	.viewMore 
		text-align center
		position relative
		margin-bottom 2.7rem
		a 
			font-size 20px
			color red
			position absolute
			left 50%
			transform translateX(-50%)
			transition 0.3s all ease
			&:after 
				display none
	&.imgOpacidad 
		img 
			filter grayscale(1)
			opacity .6
			transition 0.3s all ease
@media(max-width tablet)
	.cards-alliance 
		ul 
			li
				width 30.25%
				&:nth-child(3n) 
					margin 0 
				&:nth-child(4n)
					margin 0 16px 30px 0
				&:nth-child(3n + 1) 
					clear both
				&:nth-child(4n + 1) 
					clear none
@media(max-width mobile) 
	.cards-alliance 
		ul 
			li 
				width 100%
				float unset
				margin 0 auto 30px
				clear none
				&:nth-child(3n) 
					margin 0 auto 30px
				&:nth-child(3n + 1) 
					clear none
				&:nth-child(4n) 
					margin 0 auto 30px
		&.opacidad 
			margin-bottom 10rem
	.cardAlliance 
		max-width 100%