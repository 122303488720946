/**
* Add the correct display in IE 9-.
* 1. Add the correct display in Edge, IE, and Firefox.
* 2. Add the correct display in IE.
*/
details, /* 1 */
menu, 
summary
	/* 1 */
	display block

