.searchWrap 
	position relative
	text-align center
.searchWrap
	position fixed
	z-index 1000
	top 0
	left 0
	overflow hidden
	width 100%
	height 100vh
	pointer-events none
	.container
		position relative
	&::before 
		content ''
		position absolute
		top 0
		left 0
		width 100%
		height 100%
		pointer-events none
		background rgba(0,0,0,0.3)
		opacity 0
		transition opacity 0.6s
		transition-timing-function cubic-bezier(0.2,1,0.3,1)
	
.mainWrapHide 
	opacity 0
	transform scale3d(0.8,0.8,1)
	
.searchOpen 
	pointer-events auto
	&::before 
		opacity 1
	.searchInner 
		transform translate3d(0,0,0)
	.searchSuggestion 
		transform translate3d(0,0,0)
		transition-delay 0.05s
		&:last-child 
			transition-delay 0.1s
			
.searchInner 
	transition transform 0.6s
	transition-timing-function cubic-bezier(0.2,1,0.3,1)
.searchInnerUp 
	transform translate3d(0,-100%,0)
.searchInnerDown
	transform translate3d(0,100%,0)
.searchSuggestion 
	transform translate3d(0,150px,0)
	transition transform 1s
	transition-timing-function cubic-bezier(0.2,1,0.3,1)
.searchInner 
	position absolute
	display flex
	justify-content center
	align-items center
	width 100%
	height 50%
	background white
.searchInnerUp 
	color marine
.searchInnerDown 
	top 50%
	color white
	background marine
.btnSearchClose 
	font-size 2.4em
	position absolute
	z-index 100
	top 30px
	right 15px
	display none
	opacity 0
	transform scale3d(0.8, 0.8, 1)
	transition opacity 0.6s, transform 0.6s
	transition-timing-function cubic-bezier(0.2,1,0.3,1)
	color marine
	cursor pointer
	&:after
		display none
.searchForm 
	width 75%
	max-width 980px
	margin 0 auto
	label
		text-align left
		color marine
		font-size 28px
		display block
		margin-bottom 10px
		font-family 'indivisaSerif'
.searchInput 
	font-family inherit
	font-size 18px !important
	line-height 1
	display inline-block
	box-sizing border-box
	width 100%
	padding 0 0 0.1em 0
	border-radius 0
	color white
	border 1px solid gray !important
	position relative
	&:after
		content '\e92e'
		font-family 'lasalle'
		position absolute
		top 3px
		color marine
		font-size 34px
		right 2px
	&::-webkit-input-placeholder 
		opacity 0.7
		color gray
	&::-moz-placeholder 
		opacity 0.7
		color gray
	&:-ms-input-placeholder 
		opacity 0.7
		color gray
	&::-ms-clear 
		display none
	input
		width 90%
		margin 0
		display block
		border none
		position relative
		top 2px
	&.active
		.btnReset
			opacity 1
	.btnReset
		opacity 0
		transition 0.3s all ease
		position absolute
		top 3px
		right 40px
		width 36px
		height 36px
		border-right 1px solid marine
		font-size 0
		&:after,
		&:before
			content ''
			width 26px
			height 2px
			background marine
			display block
			position absolute
			border-radius 5px
		&:after
			top 50%
			left 0
			transform rotate(45deg)
		&:before
			top 50%
			left 0
			transform rotate(-45deg)



.searchInput::-webkit-search-cancel-button,
.searchInput::-webkit-search-decoration 
	-webkit-appearance none


.searchInfo 
	font-size 90%
	font-weight bold
	display block
	width 100%
	margin 0 auto
	padding 0.85em 0
	text-align right
.searchRelated 
	display flex
	width 75%
	max-width 900px
	//pointer-events none
.searchSuggestion 
	width 50%
	padding 0 1em 0 0
	text-align left
	width 100%
	&:last-child 
		padding 0 0 0 1em
	h6 
		font-size 1.35em
		margin 0 0 20px
	p 
		font-size 1.05em
		line-height 1.4
		margin 0.75em 0 0 0
		color white
	a
		color white
		border-bottom 1px solid transparent
		font-family 'indivisaRegular'


.btnSearch 
	transition opacity 0s
.mainWrapHide 
	.btnSearch 
		opacity 0
		transition-delay 0s
.searchOpen 
	.btnSearchClose 
		opacity 1
		transform scale3d(1, 1, 1)
		display block
		
.withBanner
	overflow hidden
	padding-bottom 90px
	
.containerVisible
	width 100%
	margin 0 auto
	max-width 960px
	//height 240px
	height auto
	.contImg
		overflow hidden
		width 100%
		height 240px
		position relative
	img
		max-width 100%
		width auto
		position absolute
		//left 50%
		//transform translateX(-50%)
		top 50%
		transform translateY(-50%)
		transition 0.6s all ease

.searchResults
	width 80%
	margin 30px 0
	li
		background white
		box-shadow 0 0 10px rgba(black, 0.2)
		padding 25px 25px 15px 20px
		box-sizing border-box
		margin-bottom 28px
		&:last-child
			margin-bottom 0
	h2
		font-family 'indivisaBold'
		font-size 16px
		margin 0
		&:before
			display none
		a
			color brandcolor
			&:after
				content ''
				display block
				position absolute
				width 0
				height 2px
				background accent-color
				transition 0.6s all ease
			&:hover
				&:after
					width 80px
	p
		color gray
		font-size 14px
		line-height 20px
	.btnMore
		display block
		margin-right 0
		margin-left auto
		max-width 120px
		color accent-color
		position relative
		text-align right
		padding-right 30px
		font-weight 700
		font-size 18px
		&:after
			content '\e929'
			font-family 'lasalle'
			position absolute
			right 0
			top 50%
			margin-top -12px
			font-size 20px
			transition 0.6s all ease
		&:hover
			&:after
				right -10px

.paginator
	width 80%
	text-align center
	margin 10px 0
	li
		display inline-block
		vertical-align middle
		a
			display block
			width 20px
			height 40px
			font-size 14px
			line-height 40px
			font-family 'indivisaItalicLight'
			color grayLight
			&.active,
			&:hover
				color accent-color
				font-weight 700
			&.prev,
			&.next
				width 40px
				height 40px
				background grayWhite
				margin 0 10px
				color gray
				line-height 42px
				&:hover
					background accent-color
					color white
					
.noResults
	.noResultsTxt
		font-size 32px
		display block
		margin 0 auto 20px
		width 50%
		line-height 36px
		font-family 'indivisaItalicLight'
		position relative
		&:after
			content ''
			background accent-color
			display block
			height 3px
			width 100px
			position absolute
			bottom -5px
			left 0
	.otherResults
		width 50%
		margin 50px auto 100px
		text-align center
		>li
			display inline-block
			width 48%
			vertical-align middle
		a
			font-family 'indivisaBold'
			&.arrow
				position relative
				.icon
					color accent-color
					font-size 22px
					position absolute
					top -5px
					left 100%
					transition 0.6s all ease
					margin-left 2px
				&:hover
					.icon
						margin-left 15px
			&.btnRd
				background brandcolor
				color white
				position relative
				padding-right 50px
				.icon
					font-size 28px
					position absolute
					right 15px
					top 3px
				&:hover
					background white
					color brandcolor

.suggestions
	overflow hidden
	margin-bottom 70px
	h4
		color brandcolor
		font-family 'indivisaBold'
	> div
		col(1/2)
	ul
		li
			display inline-block
			margin-left 10px
			margin-bottom 15px
			border-right 1px solid gray
			padding-right 10px
			&:last-child
				border none



@media (max-width: 900px)
	.noResults 
		.otherResults,
		.noResultsTxt
			width 70%

@media(max-width: 600px)
	.searchSuggestion
		h6
			font-size 1em
		p
			font-size 0.8em
	.noResults 
		.otherResults,
		.noResultsTxt
			width 75%
		.noResultsTxt
			font-size 30px

@media (max-width: mobile)
	.searchRelated
		//display none
		display block
	.searchSuggestion
		&:last-child
			padding 20px 0 0
	
	.containerVisible
		height auto
		.contImg
			height auto
		img
			position static
			top 0
			transform none
			
	.searchResults,
	.paginator
		width 100%
	.searchResults
		li
			padding 25px 20px 15px 20px
	.noResults 
		.otherResults,
		.noResultsTxt
			width 95%
		.noResultsTxt
			font-size 26px
		.otherResults
			>li
				display block
				width 100%
				margin-bottom 30px
				&:last-child
					margin-bottom 0
	.suggestions
		> div
			stack()

@media (max-width: mobile)
	.searchForm
		width 95%

@media (max-width: 375px)
	.searchInfo
		font-size 80%
	
		









