//------------- ANIMACION DE PARPADEO -------------
@keyframes parpadeo 
	0% 
		opacity 1.0
	50% 
		opacity 0.0
	100% 
		opacity 1.0
//----------- FIN ANIMACION DE PARPADEO -----------
.liveLogo 
	width 160px
	// animation parpadeo 1.5s infinite linear
	a 
		display flex
		flex-wrap wrap
		width 100%
		height 100%
		font-size 0
		&:after
			display none
	.icon 
		font-size 30px
		color white
		padding 7px
		border 4px solid white
		background marine 
		border-radius 50%
		box-shadow 0 0 15px rgba(255, 255, 255, .6)
		animation pulse 1.5s infinite
		align-self center
		z-index 2
	.imgVivo 
		max-width 125px
		margin-left -24px
		align-self center
		&.marine 
			margin-left -22px
			max-width 115px
		img 
			width 100%
			height 100%
			object-fit cover
	&:hover 
		animation none
.profiles 
	padding 0 0 50px
	.download-list 
		padding 1.5rem 0 .7rem
	.ofertaHome 
		padding 50px 0 115px
	&.teachers 
			padding 0
		.downloadableProfiles 
			padding-bottom 3rem
			.subtitle 
				&:after 
					bottom 0
		.contentServicios 
			padding 80px 0 
			margin-bottom 0
	&.parent 
		.content-card-slider 
			padding-bottom 2.5rem
		.download-list 
			padding 1.5rem 0 3.5rem 
			.subtitle
				&.light 
					margin 0 0 48px
		.listDirectorHistory 
			.subtitle
				&.light 
					margin 0 0 85px
		.load-more 
			margin 25px 0 47px
section 
	&.tabsProfiles
		.lateralTabs 
			.tabsNav 
				width 12.8%
				li 
					a 
						padding 16px 10px 16px 0
						border-left 0
						border-right 1px solid medgray
						&:after 
							display block
							top unset
							bottom 13px
						&:hover 
							&:after 
								width 32%
					&.active 
						a 
							&:after 
								width 32%

			.tabsContent 
				width 77.25%
.infoBlock-tab
	h4 
		font-size 30px
		color marine
		margin 0 0 1.8rem
	h6 
		font-size 21px 
		line-height 21px
		color marine
		margin 2.8rem 0 2rem
	p 
		line-height 21px
		color marine
		strong 
			font-style italic
	h5 
		font-family indivisaBold
		font-size 16px
		color marine
		text-transform uppercase
		margin .9rem 0
.content-fullSlider 
	width 100%
	margin-bottom 42px
	overflow hidden 
	h2 
		color white
		margin 0
		span 
			display table
			font-family indivisaItalicLight
			font-weight regular
			font-size 21px
			line-height 32px
			margin-bottom .65rem
			i 
				display table-cell
				font-size 28px
				padding-right 8px
				padding-bottom .2rem
				vertical-align middle
		&:after 
			display none
	p 
		font-size 24px
		color white
		margin 1rem 0
	.btnExterno 
		margin-top 1.5rem
		.btn 
			color white
			border 1px solid white
			margin-bottom 0
			&:hover 
				border 1px solid red
	.description 
		position absolute
		left 0
		bottom 100%
		margin-bottom 6.6rem
		.liveLogo 
			margin 1.5rem 0 1rem
	.item-slider 
		.img 
			width 100%
			position relative
			top 0
			left 0
			font-size 0
			z-index -1
			&:before 
				content ''
				display block
				width 100%
				height 100%
				position absolute
				bottom 0
				background linear-gradient(to bottom, rgba(0,0,0,0) 30%, #000 130%)
			img 
				width 100%
				height 100%
				object-fit cover
		.container 
			position relative
	.slick-dots 
		width 100%
		max-width 980px
		position relative
		bottom 4.1rem
		margin 0 auto
		box-sizing border-box
		li 
			display inline-block
			margin-right 14px
			button 
				width 40px
				height 10px
				background transparent
				cursor pointer
				border none
				box-sizing border-box
				border-bottom 2px solid white
				font-size 0
				outline none
				//border-radius 25px
				//background white 0% 0% no-repeat padding-box
				opacity 0.2
				transition 0.8s all ease
				&:focus 
					outline none
				&:hover
					opacity 1

			&.slick-active 
				button 
					width 75px
					opacity 1
.list-imgPdf-profiles 
	padding 25px 0 3px
	li  
		display inline-block
		max-width 300px
		margin 0 20px 0 0
		&:nth-child(2n)
			margin 0
		.img 
			img 
				width 100%
		.link 
			margin 2.4rem 0
.meetingList 
	margin 1.5rem 0 2.8rem
	h5 
		font-family indivisaBold
		font-size 16px
		color marine
		text-transform uppercase
	ul 
		display flex
		flex-wrap wrap
		justify-content space-between
		li 
			width 27.55%
			max-width 291px
			&:nth-child(1)
				width 40%
			h5 
				margin 5px 0 !important
			.datos 
				background marine
				padding .1rem 1rem
				min-height 55px
				p 
					line-height 18px !important
					color white !important
					margin .5rem 0
.horizontalFilters 
	.tabsNavProfiles 
		padding 2rem 0 1.8rem
		ul 
			li 
				display inline-block
				padding-right 50px
				a 
					font-family indivisaItalicLight
					font-size 30px
					line-height 57px
					font-style italic
					color lightgray
					&:hover 
						&:after 
							width 26%
				&.active 
					a 
						color marine
						&:after 
							width 26%
	.tabsContentProfiles 
		ul 
			li 
				display none
				&.active 
					display block
#form-profiles,
#form-profiles-alone
	margin-bottom 2.3rem
	text-align center
	width 100%
	.customSelect 
		font-family indivisaBold
		margin-right 25px
		&:last-child 
			margin-right 0
	#custom-peril,
	#custom-peril-alone
		width 304px
	#custom-grupo,
	#custom-grupo-alone
		width 120px
		padding 10px 15px 10px 22px
#form-profiles
	text-align left
.listHorarios 
	width 100%
	display flex
	flex-wrap wrap
	justify-content center
	.item 
		width calc(100% / 2)
		ul 
			li 
				display block
				width 96%
				margin 0
				border-bottom 1px solid lightgray
				position relative
				&.active 
					a 
						color marine
						.icon  
							color red
				&:hover
					a 
						color marine
						.icon 
							color red
				a 
					display inline-block
					font-size 16px 
					line-height 21px
					color lightgray
					padding 22px 0
					position unset
					&:after 
						display none
					.icon 
						font-size 18px
						color lightgray
						padding 0
						position absolute
						top 50%
						right 12px
						transform translateY(-50%)
						transition 0.6s all ease
		.imgHorarios 
			width 100%
			height 390px
			max-width 400px
			border 1px solid lightgray
			margin 0 0 0 auto
			display flex
			flex-wrap wrap
			justify-content center
			align-content center
	&.uniqueCalendar
		flex-direction column-reverse
		.item 
			width 100%
			max-width 650px
			margin 0 auto
		.imgHorarios 
			max-width 100%
			height auto
		#form-profiles,
		#form-profiles-alone 
			#custom-peril 
				width 500px
		.link 
			text-align end
			margin 3rem 0 .55rem
.studentGroups-detail 
	.online-consultation 
		margin 2.5rem auto 5.5rem 
.online-consultation 
	max-width 723px
	padding 1.5rem 0
	background marine
	border-radius 10px
	margin 2.5rem auto
	display grid
	grid-template-columns 60% 40%
	box-sizing border-box
	.item 
		align-self center
		text-align center
		margin 0
		&:first-child 
			padding 0 2rem 0 6rem
		&:last-child 
			justify-self start
		h4 
			font-size 24px
			line-height 30px
			color white
			margin 0
		a 
			display block
			width 100%
			padding 16px 3.2rem 15px
			margin 0
	&.longText 
		.item 
			&:first-child 
				padding 0 2rem 0 3rem
			a 
				padding 15.5px 1.3rem
		.btn 
			font-size 20px
.floatingSlider 
	position relative
	margin 6.65rem 0 9.5rem
	.info
		width 54.5%
		max-width 100%
		color white
		background marine
		padding 80px 0px 67px 0
		position relative
		margin 0 auto 0 0
		box-sizing border-box
		z-index 1
		&:before 
			content ''
			display block
			width 100%
			height 100%
			background marine
			position absolute
			top 0
			right 100%
			margin-right -1px
	.subtitle 
		color white
		margin 0 0 1.8rem
		strong  
			display block
			font-size 30px
			line-height 30px
			margin-bottom .7rem
		&:after 
			background white
			top unset
			bottom -11px
	h4 
		font-size 30px
		line-height 36px
		margin 0 0 1.8rem
	h5 
		font-family indivisaItalicLight
		font-weight regular
		font-size 21px
		line-height 30px
		margin 2.5rem 0 1.5rem
		strong 
			display block
			font-family indivisaBold
			font-size 30px
			margin-bottom .3rem
	.btn 
		font-family indivisaBold
		padding 15px 75px
		margin 1rem 0 0 0
		&.shortText 
			padding 15px 45px 
	a  
		font-family indivisaRegular
		line-height 21px
		color white
	ul 
		li 
			margin-bottom 0
	p 
		line-height 24px
		padding-right 5rem
		strong 
			font-family indivisaBold
	&.simpleText
		.info
			min-height 460px
		.subtitle
			font-size 24px
			font-family 'indivisaBold'
			line-height 36px
			width 80%
			&:after
				display none
		.btn
			padding 15px 35px
		.img
			height 500px
	&.withBullets
		.subtitle
			font-size 30px
			font-family 'indivisaBold'
			line-height 36px
			width 80%
			&:after
				display none
		.img
			height 500px
		ul
			width 75%
			margin-top 30px
			li
				margin-bottom 30px
				min-height 50px
			a
				position relative
				display block
				padding-left 60px
				color white
				font-size 18px
				font-family 'indivisaItalicLight'
				&:after
					display none
				span.icon
					position absolute
					width 50px
					height 50px
					text-align center
					line-height 50px
					font-size 32px
					color white
					background red
					border-radius 50%
					top 50%
					transform translateY(-50%)
					transition .3s all ease
					left 0
				small.icon
					color red
					font-size 22px
					margin-left 5px
					transition .3s all ease
					position relative
					top 3px
				&:hover
					small.icon
						margin-left 10px
					span.icon
						top 30%
	&.fixedHigh 
		.info 
			height 400px
	&.listSteps 
		.img 
			height 490px
		.info 
			height 490px
			padding 70px 0px 50px 0
			ul 
				margin-top 2rem
				li 
					margin-bottom 20px
			p 
				margin 0
			.arrow 
				font-family indivisaItalicLight
				&:before 
					top unset
					bottom 2px
					transform translateY(0)
			.processIcon
				display inline-block
				width 54px
				height 54px
				background red
				border-radius 50%
				position relative
				margin-right 25px
				vertical-align middle
			.icon 
				font-size 30px
				position absolute
				top 50%
				left 50%
				transform translate(-50%, -50%)
			.description 
				display inline-block
				width 73%
				vertical-align middle
	.img 
		width 51%
		height 400px
		position absolute
		right 0
		top 35px
		img 
			width 100%
			height 100%
			object-fit cover
.contentServicios 
	padding 80px 0 70px
	margin-bottom 9rem
	.subtitle 
		margin 0 0 30px
		~ p 
			text-align center
			color marine
			line-height 24px 
			padding 0 15rem
.slideService 
	text-align center
	.slide 
		max-width 220px
		margin 0 23px 0 0
	.cardService 
		min-height 100px
		background smoke
		border 1px solid lightgray
		position relative
		padding 20px 0 0
		margin 94px 0 47px
		.icon 
			width 76px
			height 76px
			line-height 76px
			color #fff
			text-align center
			border-radius 50%
			margin 0 auto
			position absolute
			top -40px
			left 50%
			transform translateX(-50%)
			background red
			span 
				width 100% 
				font-size 50px
				position absolute
				top 50%
				left 0
				transform translateY(-50%)
		.info 
			padding 1.3rem 2.1rem .3rem
			p 
				font-family indivisaBold
				line-height 21px
				color marine
.btnProtal 
	text-align center
	a
		color marine
		border 1px solid marine
.contentConsultas 
	padding 10rem 0rem 5rem
	.subtitle 
		margin 0 0 70px
		&:after 
			top unset
			bottom -23px
.cardConsultas 
	ul 
		width 100%
		display flex
		flex-wrap wrap
		justify-content space-evenly
		li 
			text-align center
			max-width 212px
			border 1px solid lightgray
			margin-bottom 40px
			.info 
				padding .3rem 3rem .5rem
			p 
				font-family indivisaBold
				font-size 16px
				line-height 21px
				color marine
				margin 0
				a 
					&:after 
						display none
			.img 
				overflow hidden
				a, .js-link
					width 100%
					height 100%
					display block
					font-size 0
					margin 0
					padding 0
					&:after 
						display none
				&:hover 
					a, .js-link
						img 
							transform scale(1.2)
				img 
					transition 0.3s all ease
				img.js-link
					&:hover
						transform scale(1.2)

.showMore 
	text-align center
	margin 1.5rem 0
	min-height 30px
	a 
		line-height 21px
		color oxford
		position relative
		i 
			font-size 22px
			position absolute
			padding-left 6px
			top 50%
			transform translateY(-55%)
			transition 0.5s all ease
		&:hover 
			i 
				top 80%
				//animation rebota 0.5s alternate infinite ease-out
.downloadableProfiles 
	.subtitle 
		&:after 
			top unset
			bottom -23px
.list-semesters 
	margin 1rem 0
	ul 
		display flex
		flex-wrap wrap
		li 
			margin-right 25px
			&:nth-child(2n)
				margin-right 0
.cardColegiatura 
	width 100%
	max-width 291px
	background marine
	.info 
		text-align center
		padding .5rem 1rem 1.2rem
		h4 
			font-family indivisaItalicLight
			line-height 31px
			color white
			margin 0 0 1.2rem
		p 
			color white
			margin .5rem 0 
			strong 
				font-style normal
			&:last-child 
				margin 1.3rem 0 0
@media(max-width tablet-portrait) 
	.cardColegiatura 
		max-width 269px
	.content-fullSlider 
		.description 
			padding 0 20px
		.slick-dots 
			padding 0 22px
	.list-imgPdf-profiles 
		li
			max-width 269px
	.listHorarios 
		.item 
			width 90%
			ul 
				li 
					width 100%
			.imgHorarios 
				max-width 500px
				height auto
				margin 1.3rem auto
	.contentServicios 
		.subtitle 
			~ p 
				padding 0 10rem
	.slideService 
		margin 0 auto 2.5rem
		position relative
		z-index 1
		.slick-list 
			width 2000px
		.slick-arrow 
			width 40px
			height 40px
			border none
			position absolute
			outline none
			background none
			top 90%
			left 50%
			font-size 0
			display block
			z-index 2
			&.slick-prev 
				transform translateX(-70px)
				&:before 
					content '\e942'
			&.slick-next 
				transform translateX(25px)
				&:before 
					content '\e929'
			&:before 
				content ''
				font-family 'lasalle'
				color red
				font-size 24px
@media(max-width tablet) 
	.online-consultation 
		.item 
			&:first-child 
				padding 0 2rem 0 3rem
	.contentServicios 
		.subtitle 
			~ p 
				padding 0
	.floatingSlider
		&.withBullets
			ul
				width 85%
		&.listSteps 
			.info 
				height auto
	.content-fullSlider 
		.item-slider 
			.img 
				height 435px
		.description 
			margin-bottom 4.5rem
		h2 
			span 
				font-size 17px
				margin-bottom 0.35rem
		p 
			font-size 18px
			margin .5rem 0 1rem
		.btnExterno 
			margin-top 1rem
			margin-bottom .5rem
		.slick-dots 
			bottom 3rem
@media(max-width mobile) 
	.contentConsultas 
		padding 7rem 0rem 4rem
	.list-imgPdf-profiles 
		li
			display block
			max-width 300px
			margin 0 auto
			&:nth-child(2n)
				margin 0 auto
	.meetingList 
		ul 
			li
				width 48%
				&:nth-child(1) 
					width 48%
	.cardColegiatura 
		max-width 291px
	.list-semesters 
		ul 
			li
				margin 0 auto 1.3rem
				&:nth-child(2n)
					margin 0 auto 1.3rem
	section
		&.tabsProfiles 
			.lateralTabs 
				.tabsNav 
					width 100%
					li 
						a 
							padding 10px
							border-right 0
							&:after
								display none
				.tabsContent 
					width 100%
	.listHorarios 
		.item 
			width 100%
			ul 
				li 
					a 
						&:last-child 
							padding 0 0 22px
		&.uniqueCalendar 
			#form-profiles,
			#form-profiles-alone 
				#custom-peril,
				#custom-peril-alone 
					width 100%
					margin-bottom 1rem
					margin-right 0
				#custom-grupo,
				#custom-grupo-alone 
					width 100%
			.link
				width 100%
		.link 
			a
				&.circled 
					display inline-block
	.online-consultation 
		grid-template-columns 100%
		grid-template-rows auto auto
		row-gap 30px
		.item 
			&:first-child 
				padding 0 2rem
			&:last-child 
				justify-self center
		&.longText
			.item 
				&:first-child 
					padding 0 2rem
	.floatingSlider 
		background marine
		margin 5.2rem 0 7.5rem
		.info 
			width 100%
			padding 60px 10px
			margin 0 auto
			&:before 
				display none
		.subtitle 
			display table
			left: 50%
			transform translateX(-50%)
			span
				font-size 22px
				line-height 24px
		p 
			padding-right 0
		.img 
			width 100%
			position relative
			top 0
		.btn 
			display table
			margin 2rem auto 0
		h4 
			margin 1.5rem 0
		&.fixedHigh 
			.info 
				height auto
	.contentServicios 
		margin-bottom 7rem
	.content-fullSlider 
		.item-slider 
			background marine
			.img 
				height auto
				z-index 1
		.description 
			padding 1.5rem 0 0
			position relative
			top 0
			bottom unset
		.liveLogo 
			.imgVivo 
				max-width 115px
				margin-left -22px
			.icon 
				font-size 24px
		.btnExterno 
			.btn 
				font-size 16px

@media(max-width mobile) 
	.floatingSlider.withBullets .info, .floatingSlider.simpleText .info
		min-height auto
@media(max-width mobile-landscape) 
	.online-consultation 
		.item 
			&:first-child 
				padding 0 1rem
		&.longText
			.item 
				&:first-child 
					padding 0 1rem
	.meetingList 
		ul 
			justify-content center
			li
				width 100%
				&:nth-child(1) 
					width 100%
	.horizontalFilters 
		.tabsNavProfiles 
			ul 
				li 
					padding-right 30px
					a 
						font-size 26px
						line-height 50px
	#form-profiles,
	#form-profiles-alone 
		.customSelect
			margin-right 0
			margin-bottom 1rem
		.customSelect-label 
			font-size 15px
		#custom-peril 
			width 100%
		#custom-grupo 
			width 100%
	
	.floatingSlider
		&.withBullets,
		&.simpleText
			.container
				padding 0
			.info
				text-align left
			.subtitle
				width 100%
			p
				padding-right 0
			ul
				width 100%
			.img
				display block
				position static
				width 100%
				height auto
	.listHorarios 
		.item 
			ul 
				li 
					a 
						.icon 
							display block
							font-size 16px
							position relative
							top 5px
							right 0
							transform translateY(0)
	.content-fullSlider 
		h2 
			line-height 38px
			span 
				margin-bottom .25rem