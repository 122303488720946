/**
* Remove the margin in all browsers (opinionated).
*/
body
	margin 0

/**
* Add the correct display in IE 9-.
* 1. Add the correct display in Edge, IE, and Firefox.
* 2. Add the correct display in IE.
*/
article, 
aside, 
footer, 
header, 
main, /* 2 */
nav, 
section
	display block

/**
* Correct the font size and margin on `h1` elements within `section` and
* `article` contexts in Chrome, Firefox, and Safari.
*/

h1
	font-size 2em
	margin 0.67em 0

