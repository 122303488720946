.listConference 
	margin 0 0 5.5rem
	li 
		border-bottom 1px solid lightgray
		position relative
		padding-bottom 15px
		h3 
			font-size 21px
			line-height 26px
			color marine
			margin 0
		p 
			font-size 18px
			line-height 26px
			color marine
			margin 0
		.circled 
			position absolute
			top 50%
			right 3px
			transform translateY(-50%)
.listDirectorHistory 
	ul
		width 100% 
		display flex
		flex-wrap wrap
		justify-content space-between
		li 
			max-width 295px
			margin-bottom 45px
		h3 
			font-size 18px
			line-height 24px
			color marine
			margin 0
		p 
			line-height 24px
			color marine
			margin 0
		.img 
			font-size 0
			position relative
			overflow hidden
			img 
				transition 0.6s all ease
			a 
				font-family indivisaItalicLight
				font-size 16px
				color white
				position absolute
				bottom -50px
				right 45px
				transition 0.6s all ease
				z-index 1
				i 
					display inline-block
					width 30px
					height 30px
					font-size 20px
					color white
					text-align center
					background #22338d
					border-radius 50%
					box-sizing border-box
					position absolute
					top 50%
					right -37px
					transform translateY(-50%) rotate(-90deg)
					&:before 
						width 100%
						position absolute
						top 50%
						left 0
						transform translateY(-50%)
				&:after 
					display none
			&:after 
				content ''
				display block
				width 100%
				height 0
				position absolute
				bottom 0
				left 0
				background transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box
				transition 0.6s all ease
				opacity .81
				z-index 0
			&:hover 
				a 
					bottom 20px
				img 
					transform scale(1.2)
				&:after 
					height 95%
		.info 
			padding-top 14px
	&.descriptive 
		.subtitle 
			padding-top 2.5rem
			margin 0 0 72px
		ul 
			.img 
				&:after 
					display none
				&:hover 
					img 
						transform scale(1)
@media(max-width tablet) 
	.listDirectorHistory 
		ul 
			justify-content space-evenly
@media(max-width mobile-landscape) 
	.listConference 
		li 
			.circled 
				width 100%
				max-width 150px
				position relative
				top unset
				bottom 0
				left 0
				right unset
				margin-top .5rem
				transform translateY(0)