.row
	&.col-12
		float unset
.content-404 
	padding 157px 0
	ul 
		width 100%
		display grid
		grid-template-columns 50% 50%
		li
			position relative
			&:last-of-type 
				text-align center
				margin-top 2.5rem
				justify-self center
				align-self center
			h1 
				font-family indivisaItalicLight
				font-weight bold
				font-size 30px
				line-height 60px
				color darkred
				z-index 1
			p 
				font-family indivisaItalicLight
				font-weight regular
				font-size 220px
				line-height 264px
				color smoke
				margin 0
				position absolute
				top -70px
				left -1.5rem
				z-index -1
			.btn 
				margin-top 2rem
			.img 
				max-width 410px
				img 
					width 100%
					display block
					margin 0 auto
@media(max-width 1599px) 
	.content-404 
		padding 50px 0
@media(max-width tablet-portrait)
	.content-404 
		ul 
			li 
				h1 
					font-size 26px
					line-height 38px
				p 
					font-size 180px
					line-height 174px
					top -33px
					left 50%
					transform translate(-50%, 0)
@media(max-width tablet)
	.content-404 
		padding 40px 0
		ul 
			grid-template-columns 100%
			row-gap 1rem
			li 
				justify-self center
@media(max-width mobile-landscape)
	.content-404 
		ul 
			row-gap 0
			li 
				&:last-of-type 
					margin-top 3.5rem
@media(max-width mobile-portrait + 40)
	.content-404 
		ul 
			li 
				width 100%