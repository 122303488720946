.wrapSlider
	position relative
	.navSlider
		a
			position absolute
			width 40px
			height 40px
			top 50%
			transform translateY(-50%)
			font-size 24px
			text-align center
			line-height 40px
			&:after
				display none
			&.btnPrev
				left 0
			&.btnNext
				right 0
.sliderTabs,
.sliderIconos
	width 90%
	margin 0 auto
	.slick-arrow
		display none
		position absolute
		font-size 0
		padding 0
		opacity 0
	.slide
		p
			width 60px
			height 60px
			box-sizing border-box
			position relative
		a
			border-radius 50%
			display block
			width 21px
			height 21px
			background medgray
			position relative
			left 50%
			top 50%
			transform translate(-50%, -50%)
			&:after
				display none
			&:before
				content '\e953'
				font-family 'lasalle'
				font-size 60px
				left 100%
				top 50%
				margin-left 10px
				transform translateY(-50%)
				position absolute
			&:hover
				background red
				&:before
					color marine
					transition none
			span
				position absolute
				top 50%
				left 50%
				transform translate(-50%, -50%)
				display block
				margin 0
				font-size 0
				color white
	.slick-current
		a
			background red
			width 60px
			height 60px
			&:before
				margin-left 5px
			span
				font-size 20px
				line-height 20px

.videoComponent
	max-width 350px
	.btnVideo
		display block
		font-size 0
		margin-bottom 20px
		overflow hidden
		img
			transition 0.6s all ease
		&:before
			content '\e91c'
			color white
			font-family 'lasalle'
			font-size 70px
			position absolute
			top 50%
			left 50%
			transform translate(-50%, -50%)
			z-index 2
			margin 0
			transition 0.6s all ease
		&:after
			display block
			width 100%
			height 100%
			background rgba(marine, 0.7)
			z-index 1
			position absolute
			content ''
			transform none
			transition none
			top 0
			left 0
		&:hover
			img
				transform scale(1.2)
			&:before
				opacity 0.2
	.circled
		margin-top 20px
		display inline-block



.toggles
	>li
		border-bottom 1px solid medgray
		&:last-child
			margin-bottom 0
		.cont
			display none
		.title
			a
				position relative
				padding-right 20px
				display block
				&:after
					display none
				&:before
					content '\e902'
					position absolute
					top 0
					right 10px
					color marine
					font-family 'lasalle'
					font-size 24px
					transition 0.6s all ease
				&:hover
					&:before
						color red
		&.open
			.cont
				display block
			.title
				a
					&:before
						transform rotate(180deg)

.list-inlineB
	margin 30px 0 20px
	>li
		display inline-block
		margin-right 25px


@media (max-width tablet)
	.list-inlineB
		>li
			padding-bottom 10px
			&:last-child
				padding-bottom 0
			
@media (max-width tablet)
	.sliderTabs,
	.sliderIconos
		width 80%
	.wrapSlider 
		.navSlider 
			a
				&.btnNext
					right -10px
				&.btnPrev
					left -10px