.gridTopics-interest 
	.grid-item 
		width 46%
		margin-right 3%
		margin-bottom 20px
.js-link 
	cursor pointer
.cardNote 
	max-width 455px
	margin 0 auto
	.img 
		font-size 0
		overflow hidden
		a, .js-link 
			display block
			width 100%
			height 100%
			font-size 0
			position relative
			&:after 
				display none
			&:hover 
				img 
					transform scale(1.2)
		img 
			width 100%
			height 100%
			object-fit cover
			transition 0.3s all ease
		img.js-link
			&:hover 
				transform scale(1.2)
	.description 
		border 1px solid lightgray
		padding 16px 23px 1px
		h2 
			font-size 18px
			line-height 24px
			border-bottom 1px solid lightgray
			padding-bottom 14px
			margin 0
			a 
				color marine
		h4 
			font-family indivisaItalicLight
			font-weight bold
			font-size 30px
			color marine
			margin 2rem 0 0
		h5 
			font-family indivisaRegular
			font-weight regular
			font-size 21px
			line-height 30px
			color marine
			position relative
			margin 0 0 4.3rem
			&:after 
				content ''
				display block
				width 80px
				height 2px
				background #d21034
				bottom -7.5px
				left 0
				position absolute
		ul 
			padding-bottom 2.5rem
			li 
				width 100%
				margin-bottom 20px
			span 
				display inline-block
				width 40px
				height 40px
				font-size 28px
				color white
				text-align center
				background red
				border-radius 50%
				position relative
				vertical-align top
				margin-right 10px
				&:before 
					position absolute
					top 50%
					left 50%
					transform translate(-50%, -50%)
				&.calendario 
					font-size 21px
				&.mark 
					font-size 32px
			p 
				display inline-block
				width 80%
				line-height 21px
				color marine
				margin 0 
				strong 
					display block
				a 
					font-family indivisaRegular
					color marine
.date-publication 
	p 
		line-height 24px
		color gray
		margin .7rem 0
.load-more 
	text-align center
	margin 40px 0 90px
	a 
		font-size 18px
		line-height 14px
		color gray
		position relative
		i 
			font-weight bold
			font-size 20px
			position absolute
			top 100%
			// bottom -1.7rem
			left 50%
			transition 0.6s all ease
			transform translate(-50%, 60%) rotate(90deg)
		&:hover 
			i 
				top 150%
				//animation rebota 0.5s alternate infinite ease-out
@media(max-width mobile)
	.gridTopics-interest 
		.grid-item 
			width 100%
			margin-right 0
	.cardNote
		.description 
			h2 
				font-size 16px
				line-height 20px
			.date-publication 
				p 
					font-size 12px
					line-height 20px
@media(max-width mobile-landscape) 
	.cardNote 
		.description 
			h4 
				font-size 28px
				margin 2rem 0 0
			h5
				font-size 19px
				line-height 28px
				margin 0 0 3.3rem
@media(max-width mobile-landscape - 59) 
	.cardNote 
		.description 
			ul 
				span 
					display block
					margin-bottom 10px
				p 
					display block
					width 100%