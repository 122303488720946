.slider-numeralida
	.slideCards 
		width 90%
		margin 0 auto
		position relative
		z-index 1
		overflow hidden
		.cardNumber 
			text-align center
			position relative
			background smoke
			margin 94px 0 47px
			padding 20px 0
			.info 
				border-right 1px dashed marine
				h3 
					color marine
					font-size 40px
					line-height 45px
					margin 40px 0 10px
					display block
					font-family 'indivisaItalicLight'
				p 
					// border-right 1px dashed marine
					color marine
					box-sizing border-box
					padding 5px 20px 0
					font-size 16px
					line-height 21px
					margin 0 0 15px 0
			.icono 
				border-radius 50%
				width 76px
				height 76px
				line-height 76px
				color white
				text-align center
				margin 0 auto
				position absolute
				top -40px
				left 50%
				transform translateX(-50%)
				background red
				span 
					font-size 36px
					position absolute
					width 100%
					top 50%
					left 0
					transform translateY(-50%)
					&.maestria 
						font-size 40px
		.slick-arrow
			width 40px
			height 40px
			border none
			position absolute
			outline none
			background none
			top 50%
			font-size 0
			display block
			z-index 2
			&:before
				content ''
				font-family 'lasalle'
				color red
				font-size 24px
			&.slick-prev
				left 0
				&:before
					content '\e942'
			&.slick-next
				right 0
				&:before
					content '\e929'
@media(max-width mobile) 
	.slider-numeralida 
		.slideCards 
			.cardNumber 
				.info 
					border-right 0