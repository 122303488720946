/**
* Add the correct display in IE 9-.
*/
audio,
canvas,
video
	display inline-block
/**
* Add the correct display in iOS 4-7.
*/
audio:not([controls])
	display none
	height 0
/**
* Remove the border on images inside links in IE 10-.
*/
img
	border-style none
/**
* Hide the overflow in IE.
*/
svg:not(:root)
	overflow hidden