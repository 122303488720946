.halfBg
	position relative
	padding 180px 0
	margin-top 80px
	&:before
		content ''
		width 50%
		height 100%
		position absolute
		top 0
		left 0
		display block
		background smoke
.slideProyectosPrestigio
	.slide
		font-size 0
		overflow hidden
	.info
		width 20%
		display inline-block
		vertical-align middle
		background smoke
		position relative
		box-sizing border-box
		padding-right 80px
		ul, p, li, ol
			font-size 16px
		h2
			font-size 20px
			margin-bottom 30px
		ul
			li
				position relative
				padding-left 15px
				&:after
					content ''
					width 5px
					height 5px
					display block
					border-radius 50%
					background marine
					position absolute
					top 5px
					left 0
	.toAnimate
		.info
			opacity 0
			transform translateY(200%)
			transition 0.6s all ease
		.secondarySlide
			opacity 0
			transform translateX(200%)
			transition 0.6s all ease
	.slick-active
		&.toAnimate
			.info
				opacity 1
				transform translateY(0%)
			.secondarySlide
				opacity 1
				transform translateX(0%)
	.secondarySlide
		width 80%
		display inline-block
		vertical-align middle
		.slide
			max-width 500px
	.slick-dots
		position absolute
		top 50%
		left -50px
		margin 0
		transform translateY(-50%)

		>li
			margin-bottom 20px
			&:last-child
				margin 0
		button
			font-size 0
			display block
			width 10px
			height 10px
			background medgray
			border-radius 50%
			border none
			padding 0
			box-sizing content-box
			border 4px solid smoke
			box-shadow 0 0 0 1px smoke
			transition 0.3s all ease
			outline none
		.slick-active
			button
				background marine
				box-shadow 0 0 0 1px marine

@media( max-width tablet-landscape)
	.halfBg
		padding 150px 0
	.slideProyectosPrestigio 
		.secondarySlide 
			.slide
				max-width 400px
		.info
			padding-left 40px
			box-sizing border-box
		.slick-dots
			left 5px

@media( max-width tablet)
	.halfBg
		padding 150px 0
		&:before
			width 60%
	.slideProyectosPrestigio 
		.secondarySlide 
			.slide
				max-width 300px
		.slick-dots
			left 10px
		.info
			width 15%
			padding-right 30px

@media( max-width mobile)
	.halfBg
		padding 80px 0
		&:before
			width 100%
			height 60%
	.slideProyectosPrestigio
		margin-left 40px
		.secondarySlide 
			.slide
				max-width 200px
		.slick-dots
			left -30px
		.info
			max-width 350px
			display block
			padding 0
			margin-bottom 50px

@media( max-width mobile-portrait)
	.slideProyectosPrestigio
		.info
			max-width 280px