.listEach3
	overflow hidden
	
	>li
		column(1/3, $cycle:3)
		margin-top 0
		margin-bottom 60px
		box-sizing border-box

.titleList
	font-size 24px
	color marine
	font-family 'indivisaItalicLight'
	margin 0 0 40px

.cardPeople
	.img
		a,
		.photo 
			width 170px
			height 170px
			border-radius 50%
			overflow hidden
			position relative
			display block
			margin 0 auto
			&:hover
				img
					transform translate(-50%, -50%) scale(1.2)
		img
			max-width 100%
			position absolute
			top 50%
			left 50%
			transform translate(-50%, -50%)
			transition 0.3s all ease
		h3
			font-size 20px
			color marine
			text-align center
			line-height 26px
	.text
		color oxford
		text-align center
		display none
		label, p
			font-size 16px
		label
			margin 0px 0 20px
			display block
		h5
			font-size 16px
			font-family 'indivisaBold'
			margin 0 0 5px
		a.mailTo
			color oxford
			font-size 16px
			font-family 'indivisaRegular'
			&:after
				display none
			&:hover
				color red
		p
			margin 4px 0 0
			~ 
				p 
					margin-top 15px
			// &:last-child
			// 	margin-top 4px
		
		a
			.icon
				color red
	.viewMore
		margin 10px 0 0
		text-align center
		position relative
		a
			color red
			font-size 20px
			transition 0.3s all ease
			position absolute
			left 50%
			transform translateX(-50%)
			&:after
				display none
	
	&.open
		.viewMore
			a
				transform translateX(-50%) rotate(180deg)


@media (max-width tablet)
	.listEach3
		>li
			column(1/2, $cycle:2, $uncycle:3)

@media (max-width mobile)
	.listEach3
		>li
			stack()