.search-main 
	padding 50px 0 30px
	h1 
		font-size 18px
		line-height 24px
		color darkmarine
		margin 0 0 1.4rem
#searchKeywordsForm 
	.input
		position relative
		margin-bottom 2.2rem
		input 
			width 100%
			line-height 22px
			color lightgray
			padding 13px 20px
			border 1px solid lightgray
			&:focus 
				color marine
		span 
			font-size 26px
			font-weight bold
			position absolute
			top 50%
			right 18px
			transform translate(0px, -50%) rotate(280deg)
	.select 
		width 100%
		display flex
		flex-wrap wrap
	.customSelect 
		width 296px
		border 0
		border-bottom 1px solid darkmarine
		border-radius 0
		margin-right .8rem
		&:last-of-type 
			width 237px
		.customSelect-arrow 
			top 18px
			&:after 
				font-weight bold
				font-size 20px
				color lightmarine
		&.open 
			.customSelect-arrow 
				top 11px
	.customSelect-label
		font-family indivisaBold
		font-size 18px
		line-height 26px
		color marine
.search-content 
	margin-bottom 50px
.content-search-results
	ul 
		li 
			position relative
			padding 16px 18.5rem 8px 20px
			// border 1px solid lightgray
			outline 1px solid lightgray
			border-left 10px solid marine
			margin-bottom 25px
		h2 
			font-weight bold
			font-size 24px
			line-height 34px
			color marine
		p 
			font-weight regular
			font-size 14px
			line-height 20px
			color gray
			margin-top .6rem
		.btn 
			font-size 14px
			padding 7px 21px
			position absolute
			top 50%
			right 40px
			transform translate(0, -50%)
@media(max-width tablet-portrait) 
	.content-search-results 
		ul 
			li 
				padding 16px 9.7rem 8px 20px
@media(max-width tablet)
	#searchKeywordsForm 
		.customSelect 
			width 276px
			&:last-of-type 
				width 217px
@media(max-width mobile)
	#searchKeywordsForm 
		.input 
			margin-bottom 2rem
		.customSelect 
			width 100%
			margin-bottom 1.3rem
			&:last-of-type 
				width 100%
	.search-content 
		margin-bottom 35px
	.content-search-results 
		ul 
			li 
				padding 16px 15px 8px 15px
			h2 
				font-size 20px
				line-height 26px
			.btn 
				margin 1rem 0 15px
				position relative
				top unset
				right unset
				left 50%
				transform translate(-50%, 0)