.modal-full 
	display none
	position fixed
	top 0
	left 0
	width 100%
	height 100%
	z-index 200
	.modal-overlay 
		position relative
		display table
		width 100%
		height 100%
	.modal-white 
		display table-cell
		width 100%
		height 100%
		background rgba(255,255,255,1)
		padding 60px 0 0 
		vertical-align middle
	.modal-container 
		margin 0 auto
	.modal-close 
		position absolute
		top 20px
		right 30px
		margin auto
		a 
			&:after 
				display none
		span 
			font-size 40px
			color strongblue
	.modal-dark 
		display table-cell
		width 100%
		height 100%
		background rgba(0,0,0,.75)
		padding 20px 0 
		vertical-align middle
		.modal-close 
			span 
				color white
		.img-container 
			max-width 502px
			margin 0 auto
		.link-download 
			position absolute
			top 88%
			right 47px
	.navSlider
		display flex
		flex-wrap wrap
		position absolute
		top 70%
		right 30px
		writing-mode vertical-lr
		transform translate(0, -50%) rotate(180deg)
		a 
			font-family indivisaBold
			font-size 18px
			line-height 24px
			color marine
			position relative
			&:last-child 
				padding-bottom 2rem
			&:before 
				margin-left 0
				left 50%
				top unset
			&:after 
				top 0
				left -3px
			&:hover 
				&:after 
					width 2px
					height 60%
			&.btnPrev-modal 
				order 2
				&:before 
					margin-top 5px
					top 100%
					transform translateX(-50%) rotate(90deg)
				&:hover 
					&:before
						margin-left 0
						margin-top 15px
			&.btnNext-modal 
				order 1
				&:before 
					margin-bottom 5px
					bottom 100%
					transform translateX(-50%) rotate(-90deg)
				&:hover 
					&:before
						margin-left 0
						margin-bottom 15px
.content-videos 
	display none
.modal-header 
	margin-bottom 2.6rem
	display grid 
	grid-template-columns 70% 30%
	.title, 
	.form-us-catedra
		align-self center
	.form-us-catedra 
		justify-self end
		.customSelect 
			width 180px
			font-family indivisaBold
	.subtitle-marine 
		font-size 40px
		line-height 60px
		padding-top 0
		&:after 
			bottom 0
.modal-body 
	margin-bottom 71px
	h4 
		font-size 18px
		line-height 24px
		color marine
		margin-top 0
	.videos 
		width 100%
		margin 0 auto
		iframe 
			width 100%
			height 540px
			border 0
@media(max-width 1599px) 
	.modal-full 
		.modal-white 
			padding 40px 0 0 
		.navSlider 
			top 60%
@media(max-width tablet-landscape + 75) 
	.modal-body 
		.videos 
			width 85%
			iframe 
				height 450px
@media(max-width tablet-portrait) 
	.modal-full 
		.modal-dark 
			.img-container 
				max-width 462px
			.link-download 
				right 20px
@media(max-width tablet-portrait - 100) 
	.modal-body 
		.videos 
			iframe 
				height 390px
@media(max-width tablet) 
	.modal-full 
		.modal-white 
			padding 60px 0 0
		.modal-dark 
			.img-container 
				max-width 402px
			.link-download 
				width 100%
				max-width 100%
				position relative
				right 50px
				text-align right
				margin 2rem 0 0 auto
				// top 83%
		.navSlider
			top 15.5%
			right 65px
			writing-mode horizontal-tb
			transform translate(0, -50%) rotate(0deg) 
			a 
				&:hover 
					&:after 
						width 60%
						height 2px
						top 100%
						left 0
				&:first-child 
					margin-right 2rem
				&:last-child 
					padding-bottom 0
				&.btnPrev-modal 
					order 1
					&:before
						margin-top 0 
						margin-left 5px
						top 50%
						left 100%
						transform translateY(-50%) rotate(-90deg)
					&:hover 
						&:before 
							margin-top 0
							margin-left 5px
				&.btnNext-modal 
					order 2
					&:before
						margin-bottom 0
						margin-left 5px
						top 50%
						bottom unset
						left 100%
						transform translateY(-50%) rotate(90deg)
					&:hover 
						&:before 
							margin-bottom 0
							margin-left 5px
	.modal-body 
		.videos 
			width 95%
@media(max-width lgmobile) 
	.modal-body 
		.videos 
			iframe 
				height 330px
@media(max-width mobile)
	.modal-full 
		.modal-dark 
			.link-download 
				.circled 
					display inline-block
	.modal-body 
		.videos 
			iframe 
				height 300px
@media(max-width mobile-landscape) 
	.modal-full 
		.modal-dark 
			.img-container 
				max-width 322px
			.modal-close 
				top 40px
			.link-download 
				right 30px
	.btn-scroll 
		top 31.5%
	.modal-header 
		grid-template-columns 100%
		grid-template-rows 90px 60px
		.form-us-catedra 
			justify-self left
@media(max-width mobile-portrait) 
	.modal-full 
		.modal-close 
			right 10px
		.modal-dark 
			.img-container 
				max-width 291px
			.link-download 
				top 75%
	.modal-body 
		.videos 
			iframe 
				height 170px
	.btn-scroll 
		top 32.5%
		right 35px