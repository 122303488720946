.masonry_services
	margin-bottom 40px
	.service-title
		text-align center
		margin-bottom 80px
	.service-title h1 
		display initial
		font-size 40px
		font-family: 'indivisaSerif'
		font-weight: regular 
.masonryCardService
	position relative
	overflow hidden
	.img
		overflow hidden
		position relative
	
		>a,
		>.js-link,
		img.js-link
			display: block
			width: 100%
			height: 100%
			font-size: 0
			position: relative
			img
				transition all .3s ease
			&:hover
				img  
					transform scale(1.2)
		img.js-link
			transition all .3s ease
			&:hover
				transform scale(1.2)
		.__overlay
			&:before
				content ''
				width 100%
				height 100%
				left 0
				top 0
				background: linear-gradient(to bottom,  rgba(black,0.0) 65%,rgba(black,0.65) 100%)
				z-index 2
				position absolute	
		h2 
			position absolute
			left 0
			right 0
			text-align center
			bottom 10px
			color white
			z-index 3
			font-weight bold
			font-size 18px
			font-family indivisaRegular
		.__blue
			color marine
.renta-salas-container
	height 550px
	overflow auto
.section-service-container
	position relative
	height 580px
	// overflow auto
	.imgBg
		width 44%
		position absolute
		left 0
		top 0
		height 100%
		img
			width 100%
			height 100%
			object-fit cover
	.section-service-content
		width: 54%
		margin-left: auto
		padding 50px 0 0 0
		h1
			margin 0
			color marine
			font-weight bold
			font-size 30px
	.service-content-body
		line-height 24px
		padding 20px 0 30px 0
		p 
			color marine
			font-weight regular
			font-size 16px
		ul
			list-style initial
			padding-left 20px
			color marine
			li
				margin 0
		.sala
			display: flex
			margin 25px 0
			h2
				font-size 18px
				color marine
				border-bottom: 1px solid lightgray;
				padding-bottom: 10px;
			p
				color gray
				margin 5px 0
				font-size: 16px
			a 
				margin-top 10px
			hr
				margin 10px 0
				border-top: 1px solid #DBDCDD
			.sala-imagen
				width 45%
				margin-right: 5%
				position relative
				height 200px
				margin-bottom 0
				img
					width: 100%;
					height: 100%;
					object-fit cover
			.sala-datos
				width 45%
				align-self: center
			
@media(max-width tablet-landscape)
	.section-service-container
		.imgBg
			width 40%
		.section-service-content
			padding 50px 20px
			width 52%
@media(max-width tablet)
	.renta-salas-container
		height auto
	.section-service-container
		height auto
		.container
			display flex
			align-items center
			flex-flow column	
		.section-service-content
			width unset	
			padding 20px
		.imgBg
			width 100%
			position relative
			height auto
		.service-content-link
			margin-bottom 10px
@media(max-width mobile)
	.masonry_services
		.masonry-3x3
			.grid-item
				width 100%
				margin-right: 0%
				margin-bottom: 20px
	.section-service-container
		.service-content-body
			.sala
				flex-flow column
				.sala-imagen
					width 100%
					height 400px
					margin-bottom 10px
					img
						object-fit unset
				.sala-datos
					width 100%
