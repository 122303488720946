header
	.headerTop
		background white
		padding 10px 0
	.headerBottom
		background marine
		padding 10px 0
		position relative
	.logotipo
		display inline-block
		vertical-align middle
		width 115px
		a
			&:after
				display none
	.toolsHeader
		float right
		padding-top 15px
	.lang
		display inline-block
		vertical-align top
		>li
			display inline-block
			vertical-align middle
			border-left 1px solid marine
			&:first-child
				border none
		a
			font-family 'indivisaRegular'
			padding 5px 10px
			&:after
				display none
			&.active
				font-family 'indivisaBold'
	.searchButton
		display inline-block
		vertical-align top
		a
			padding 5px 10px
			font-size 26px
			position relative
			bottom 5px
			&:after
				display none
	.nav
		float right
		width 80%
		text-align center
		.openSub
			display none
			position absolute
			top -4px
			left 0
			z-index 2
			&:before
				content '\e902'
				font-family 'lasalle'
				color red
				position absolute
				top 16px
				left 0
				transition 0.6s all ease
			&.open
				&:before
					transform rotate(180deg)
				~.submenu
					//display block
					height auto
		>ul
			display block
			margin -10px 0 0
			text-align right

			>li
				display inline-block
				vertical-align middle
				margin-right 15px
				padding 20px 0 0
				&:last-child
					margin-right 0
				>a
					color white
					font-family 'indivisaRegular'
					font-size 14px
					position relative
					height 100%
					padding 20px 0
					&:after
						background white
				&:hover
					.submenu
						display block
						height auto
		.submenu
			height 0
			position absolute
			left 0
			top 100%
			padding 20px 0 10px
			width 100%
			text-align left
			overflow hidden
			background white
			box-shadow 0 5px 5px rgba(black, 0.2)
			display none
			transition 0.6s all ease
			z-index 4
			&.active
				height auto
				display block
			>ul
				display flex
				>li
					flex 1
					a
						text-align center
						color marine
						font-family 'indivisaBold'
						display block
						&:after
							background red
							display none
						&:hover
							img
								transform translate(-50%, -50%) scale(1.1)
							strong
								&:after
									width 60%
						span
							display block
							max-width 215px
							height 130px
							overflow hidden
							margin 0 auto 10px
							position relative
						strong
							display inline-block
							position relative
							&:after
								content ''
								width 0
								height 2px
								display block
								background red
								position absolute
								top 100%
								left 0
								transition 0.6s all ease
							

					ul
						padding-top 12px
						li
							margin-bottom 15px
							a
								text-align left
								display inline-block
								&:after
									display block
					img
						transition 0.6s all ease
						position absolute
						width 100%
						top 50%
						left 50%
						transform translate(-50%, -50%)
	.logoMicro
		display inline-block
		h2
			color white
			font-size 16px
			strong
				display block
	.btnMenu
		display none
		position absolute
		top 8px
		right 20px
		width 40px
		height 40px
		a
			display block
			width 100%
			height 100%
			position relative
			font-size 0
			&:after,
			&:before
				background none
				content ''
				width 100%
				height 15px
				display block
				position absolute
				top 50%
				transform translateY(-50%)
				box-sizing border-box
				transition 0.6s all ease
			&:after
				border-top 2px solid white
			&:before
				border-bottom 2px solid white
			

.menuOpen
	overflow hidden
	header
		nav
			left 0
	.btnMenu
		a
			transform rotate(180deg)
			&:after
				margin-top -4px
				transform rotate(45deg)
			&:before
				margin-top -4px
				transform translateY(-50%) rotate(-45deg)

@media (max-width mobile)
	
	header
		.btnMenu
			display block
		.nav
			overflow auto
			background white
			position absolute
			top 100%
			height calc(100vh - 108px)
			left 100%
			width 100%
			padding 10px 30px
			box-sizing border-box
			z-index 5
			transition 0.6s all ease
			.openSub
				display block
			// &:after
			// 	content ''
			// 	display block
			// 	width 100%
			// 	position absolute
			// 	top 100%
			// 	margin-top -1px
			// 	left 0
			// 	height 500px
			// 	background white
			>ul
				margin 10px 0 0
				text-align left
				>li
					display block
					padding 15px 0
					font-size 16px
					margin-bottom 20px
					padding-bottom 0
					position relative
					a
						padding-left 30px
						color marine
						font-size 20px
						font-family 'indivisaBold'
						&:after
							display none
			.submenu
				position relative
				box-shadow none
				margin 0 0 -15px
				padding-bottom 0
				transition 0.6s all ease
				>ul 
					flex-direction column
					>li
						margin 0 0 20px
						a
							text-align left
							font-family 'indivisaRegular'
							font-size 16px
							&:after
								display none
							strong
								font-weight 400
								&:after
									display none
							span
								display none
						ul
							li
								&:last-child
									margin-bottom 0
								a
									font-family 'indivisaRegular'
									&:after
										display none
						img
							display none
		
			
