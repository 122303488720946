.missionary-grid
	display grid
	grid-template-columns repeat(3,1fr)
	margin 70px 0 100px 0
	grid-gap 2% 3%
	.img
		position relative
		overflow hidden
		> a
			display block
			width 100%
			height 100%
			overflow hidden
			font-size 0
			img
				transition all .3s ease
			&:hover
				img  
					transform scale(1.2)
			&:before
				content ''
				width 100%
				height 100%
				left 0
				top 0
				background: linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%)
				z-index 2
				position absolute
.missionary-grid-item
	.missionary-grid-data
		position absolute
		bottom 20px
		left 20px
		z-index 3
		color white
		line-height 24px
		a
			margin 0
			color white
			font-size 16px
		p
			margin 0
			font-size 18px
			&:nth-child(1)
				font-weight bold
			&:nth-child(2)
				margin-bottom 5px
				font-weight regular

.missionary-detail-banner
	position relative
	overflow hidden
	font-size 0
	&:before
		content ''
		width 100%
		height 100%
		left 0
		top 0
		background linear-gradient(to bottom, rgba(0,0,0,0) 68%, rgba(0,0,0,.85) 92%)
		z-index 2
		position absolute
.missionary-detail-banner-image
	width 100%
	height 580px
	img
		width 100%
		height 100%
		object-fit cover
.missionary-detail-banner-text
	position absolute
	bottom 10%
	left 0
	right 0
	color white
	z-index 3
	line-height 24px
	h1
		font-size 60px
		font-family indivisaItalicLight
	h2
		font-size 24px
		font-weight regular
		font-family indivisaRegular
.principios-container
	display flex
	margin 80px auto 50px auto
	border-bottom 1px solid #C9C9C9
	.missionary-logo-container
		width 22%
		margin-right 3%
	.missionary-principios
		width 75%
		color marine
		h3
			font-size 21px
			margin 0
		p
			font-size 16px
			line-height 24px
		.missionary-principio
			margin 0 0 40px 0
	hr
		border-top 1px solid #C9C9C9
		margin 0 0 30px 0
		display none
.missionary-slider-wrapper
	h2
		color marine
		font-size 21px
		margin-bottom 40px
	.container
		overflow-x visible !important
		padding 0
	.slick-list
		width 2000px
		margin 10px 0
	.missionary-slide
		max-width 500px
	.slick-slide
		margin 0 10px
.floatingSlider.withBullets.missionary.__alma
	margin 6.65rem 0 15.5rem
	background	none
	.img
		height 660px
		top 50px
.floatingSlider.withBullets.missionary.__amadis 
	margin 10.5rem 0 12.5rem
	.img	
		height 660px
		top -55px
.floatingSlider.withBullets.missionary
	color white
	background none
	.container
		padding 0
	h3
		font-size 21px
		margin-bottom 20px
	ul
		list-style disc
		text-align left
		width 100%
		li
			font-size 16px
			margin 5px 0px 5px 16px
			min-height: 0
	.icono
		background red
		border-radius 100px
		height 55px
		width 55px
		display flex
		align-items center
		justify-content center
		margin-right 25px
	.icon
		&:before
			font-size 30px
	.info
		padding 100px 0 90px 0
		min-height 600px
	.info-list
		display flex
		position relative
		margin-bottom: 40px
		&:last-child
			margin 0
@media(max-width tablet-landscape)
	.missionary-slider-wrapper
		margin-left 20px
	.floatingSlider.withBullets.missionary
		padding 0 20px
@media(max-width tablet)
	.missionary-detail-banner-image
		height auto
@media(max-width mobile)
	.missionary-grid
		grid-template-columns 1fr
		grid-gap 20px 0
		margin 70px 0 0 0
	.missionary-slider-wrapper
		.missionary-slide
			max-width: 300px
	.principios-container
		flex-flow column
		border none
		margin 50px 0 0 0
		hr
			display block
		.missionary-logo-container
			max-width 200px
			margin  auto auto 30px auto
			width auto
		.missionary-principios
			width auto
	.missionary-detail-banner-text
		h1
			font-size 45px
		h2
			font-size 20px
	.floatingSlider.withBullets.missionary
		.img
			width 100%
			position relative
			display block
		.info
			padding 60px 20px
			min-height auto
		.icono
			width 45px
			height 45px
			margin-right 15px
		.icon
			&:before
				font-size 25px
		
	.floatingSlider.withBullets.missionary.__alma
		margin 3.5rem 0px 3.5rem
		.img
			top 0
			height auto
	.floatingSlider.withBullets.missionary.__amadis
		margin 3.5rem 0px 3.5rem
		.img
			top 0
			height auto