.cardPublication
	.img
		height 300px
		overflow hidden
		a
			display block
			width 100%
			height 100%
			position relative
			&:after
				position absolute
				top 0
				left 0
				content ''
				display block
				width 100%
				height 100%
				background rgba(marine, 0.7)
				opacity 0
				transition 0.3s all ease
			img
				width 100%
				height 100%
				object-fit cover
				transition 0.3s all ease
			span
				color white
				display block
				position absolute
				top 40%
				left 0
				text-align center
				transform translateY(-50%)
				z-index 2
				width 100%
				font-size 20px
				opacity 0
				transition 0.3s all ease
				&:before
					content '\e973'
					font-family 'lasalle'
					display block
					margin-bottom 15px
					font-size 36px
					line-height 20px
			&:hover
				img
					transform scale(1.2)
				span
					opacity 1
					top 50%
				&:after
					opacity 1
	.text
		h3
			color marine
			font-size 18px
			margin 30px 0 
			font-family 'indivisaRegular'
			line-height 24px

.modalBlue
	display none
	width 100%
	height 100%
	justify-content center
	align-items center
	position fixed
	top 0
	left 0
	background rgba(darkmarine, 0.8)
	z-index 9
	.container
		position relative
		padding-top 40px
		.pdfViewer,
		.videoViewer
			border 1px solid white
			height auto
			font-size 0
			overflow auto
			max-height 600px
			overflow auto
		.videoViewer
			width 95%
			height 400px
			margin 0 auto

		.infoPdf
			background marine
			border 1px solid white
			border-bottom 0
			text-align right
			.btn
				padding 0
				width 40px
				height 40px
				text-align center
				line-height 40px
				margin 5px
				outline none
			p
				display inline-block
				vertical-align middle
				width auto
				margin 0 5px
				color white
				span
					&.current
						font-family 'indivisaBold'
						margin-left 5px

	.btnClose
		position absolute
		top 0
		right 0
		font-size 32px
		color white
		&:after
			display none
		&:hover
			color red
	iframe,
	object
		width 100%
		height 100%
		border none
	canvas
		width 100%
		margin 0 auto

.filtersTop
	.tabsByService
		display inline-block
		vertical-align top
		padding-top 5px
		width 74%
		ul
			li
				margin 0 30px 0 0
	.filterRight
		display inline-block
		vertical-align top
		width 25%
		position static
		.customSelect
			width 95px



@media(max-width tablet-portrait)
	.filtersTop
		.tabsByService
			display block
			width 100%
		.filterRight
			display block
			width 100%
			margin-bottom 30px
			text-align center


