.page
	overflow hidden

a
	color marine
	font-weight 500
	text-decoration none
	transition 0.6s all ease
	font-family 'indivisaBold'
	position relative
	outline none
	&:after
		content ''
		width 0
		height 2px
		display block
		background red
		position absolute
		top 100%
		left 0
		transition 0.6s all ease
	&:hover
		color red
		&:after
			width 60%
	&.arrow
		position relative
		&:before
			content '\e929'
			font-family 'lasalle'
			color red
			position absolute
			left 100%
			margin-left 5px
			top 50%
			transform translateY(-50%)
			font-size 18px
			transition 0.6s all ease
		&:hover
			&:before
				margin-left 15px
				transition-delay 0.2s
	&.circled
		font-family 'indivisaItalicLight'
		color marine
		background lightgray
		padding 8px 35px 8px 15px
		border-radius 20px
		&:after
			display none
		&:before
			content '\e904'
			font-family 'lasalle'
			color white
			text-align center
			line-height 25px
			font-size 16px
			box-sizing border-box
			padding-left 4px
			display block
			width 25px
			height 25px
			border-radius 50%
			background lightmarine
			text-align center
			position absolute
			right 4px
			top 50%
			transform translateY(-50%)
			transition 0.6s all ease
		&:hover
			padding 8px 45px 8px 15px
			background red
			color white
			&:before
				transform translateY(-50%) rotate(360deg)
				

main
	min-height 500px
	overflow hidden


.ulArrows
	a
		position relative
		font-family 'indivisaRegular'
		&:before
			content '\e929'
			font-family 'lasalle'
			color red
			position absolute
			left 100%
			margin-left 5px
			top 50%
			transform translateY(-50%)
			font-size 18px
			transition 0.6s all ease
		&:hover
			&:before
				margin-left 15px
				transition-delay 0.2s

label
	[type=checkbox],
	[type=radio]
		display inline-block
		margin-right 14px

ul
	margin 0
	padding 0
	list-style none
	>li
		margin-bottom 15px

body
	font-family 'indivisaRegular'
	font-size 16px
	margin 0 auto
	background white


h1
	font-size 34px
	margin 30px auto
	font-family 'indivisaBold'

h2
	font-size 28px
	margin 0
	font-family 'indivisaBold'

h3, h4
	font-size 20px
	font-family 'indivisaBold'

hr
	clear both;
	border 0
	border-top 1px solid gray
	border-radius 0
	margin 30px 0

img
	max-width 100%

//- - - - - - - - - - - - - - - - - - - - - - - -
//- Elementos del formulario
//- - - - - - - - - - - - - - - - - - - - - - - -

fieldset
	margin  60px 0 0
	padding 0
	border none
	min-width 0
	&:first-child
		margin-top 0

.container
	margin  0 auto
	max-width  980px
	width 100%
	box-sizing border-box


.form-list
	reset-list()
	display block
	margin 30px 0 10px -2%
	padding-left 0
	list-style none
	font-size 0
	
	.form-list
		margin-top 0 
		margin-bottom 0 
	&.full
		margin-left 0 
		padding 0
		>li
			display block
			margin-left 0
			width auto
		.hintTip
			display block
		.currentInput
			.datepicker
				width 95% !important
			textarea
				width 100%
	
	&.compact
		>li
			margin-bottom 8px
	
	&.four
		>li
			width 23%
	&.third
		>li
			width 30%
			
	&.center
		text-align center
		
	&.inline
		margin-left 0 !important
		>li 
			display inline-block
			margin-left 20px
			width auto
			vertical-align bottom	
			&:first-child
				margin-left 0

	[type=text]
	[type=password]
	[type=email]
		display block
		width 100%
		box-sizing border-box
		
	>li
		display inline-block
		margin 0 0 20px 2%
		width 48%
		font-size 14px
		vertical-align top

		&.full
			display block
			width   auto
		&.margin
			margin-right 50px
		.currentInput
			&:nth-child(2)
				margin-top 50px;
		&.doble
			>.currentInput
				col(1/2)
				margin-top:0
			


input
	outline  none
	&[type=text],
	&[type=password],
	&[type=email]
		color  gray
		background transparent
		border 1px solid gray
		box-sizing  border-box
		font-size  16px
		vertical-align  middle
		//-min-width  160px
		padding  10px 20px
		transition 0.6s all ease

		&:focus
			color  marine
			border-color marine

		&.error
			border-color red
			color red
		
		&.disabled
		&:disabled
			color rgba(gray, 0.4)
			background rgba(lightgray, 0.3)
			border-color lightgray
		
		&.full
			width:100%
		
		&.small
			width:100px
		
		&.mini
			width 50px
		
	
	&[type=radio]
		position relative
		margin-right 6px
		&:after
			background #fff
			border 3px solid #fff
			border-radius 20px
			box-shadow 0 0 0px 1px black
			content ''
			display block
			height 10px
			left -2px
			position absolute
			top -2px
			width  10px
		
		&:checked
			&:after
				background-color darkgray
			
		
		&:disabled
			&:after
				box-shadow 0 0 0 1px lightgray
				border 3px solid lightgray
				background lightgray
			
			&:checked
				&:after
					//background-color lightgray
					background-color lightgray
				
			
		
		&.error
			&:after
				box-shadow 0 0 0 1px red
				border 3px solid lightgray
				background lightgray
			
		
	
	&[type=checkbox]
		@extend input[type=radio]
		&:after
			border-radius 2px
		
		&:checked
			&:after
				background darkgray
			
		&:disabled
			&:checked
				&:after
					background-color lightgray
	&.noLabel
		margin-bottom 10px

.lbRd
	position relative
	margin-right 25px
	padding-left 25px
	.flCh
		position absolute
		background #fff
		border 2px solid #fff
		box-sizing border-box
		border-radius 20px
		box-shadow 0 0 0px 2px marine
		content ''
		display block
		height 14px
		left 0
		position absolute
		top 2px
		width  14px
	:checked 
		& + .flCh
			background marine
	
	:disabled
		& + .flCh

			color rgba(gray, 0.4)
			//background rgba(lightgray, 0.3)
			box-shadow 0 0 0 2px lightgray
		
		&:checked
			& + .flCh
				background rgba(lightgray, 0.3)
	&.error
		& ~ .flCh
			box-shadow 0 0 0 1px red
			border 3px solid lightgray
			background lightgray
	input
		&[type=radio]
			display none
			&:after,
			&:before
				display none

.lbCh
	position relative
	margin-right 25px
	padding-left 25px
	.flCh
		position absolute
		background #fff
		border 2px solid #fff
		box-sizing border-box
		border-radius 2px
		box-shadow 0 0 0px 2px marine
		content ''
		display block
		height 14px
		left 0px
		position absolute
		top 2px
		width  14px
		overflow hidden
		&:after
			content ''
			width 8px
			height 4px
			border 2px solid marine
			display block
			border-top 0
			border-right 0
			position absolute
			top 20px
			opacity 0
			transition 0.6s all ease
			left 0
			transform rotate(-45deg)
	:checked 
		& + .flCh
			&:after
				top 1px
				opacity 1

	
	:disabled
		& + .flCh

			color rgba(gray, 0.4)
			//background rgba(lightgray, 0.3)
			box-shadow 0 0 0 2px lightgray
		
		&:checked
			& + .flCh
				&:after
					border-color lightgray
	&.error
		& + .flCh
			box-shadow 0 0 0 1px red
			border 3px solid lightgray
			background lightgray
	input
		&[type=checkbox]
			display none
			&:after,
			&:before
				display none
	
		
		
	

textarea
	@extend input[type=text]
	padding 10px 10px
	//-width 300px
	width:100%
	height 5.7em
	outline none
	border 1px solid gray
	vertical-align bottom
	box-sizing border-box
	resize none
	&.lineal
		height 15px
	
	&.medio
		height 65px
	
	&.full
		height 130px
	


.customSelect
	>.labelFl
		color black
		font-size 11px
		opacity 0
		position absolute
		top 50%
		transition 1s all ease
		z-index 0
	
	&.active
		.labelFl
			color darkgray
			opacity 1
			top -12px
		
	
	&.listo
		.labelFl
			top -12px
			opacity 1
		
	
	&.loading
		.customSelect-arrow
			top    9px
			width  22px
			height 22px
			border 4px solid marine
			box-sizing border-box
			border-top-color red
			border-radius 12px
			animation rotateplane 1.4s .5s infinite ease-in-out
			&:after
				display none

	&.noLabel
		margin 10px 0

@keyframes rotateplane
	0%
		transform rotate(0deg)

	100%
		transform rotate(360deg)


.currentInput
	display inline-block
	margin 10px 0
	position relative
	width 100%
	.currentInput-label
		color black
		display block
		font-size 0
		left 0
		opacity 0
		position absolute
		text-align left
		top -12px
		width 100%
		
		animation hintLabel_hide .5s
	
	
	input
		width 215px
		&[type=checkbox]
			margin-top 10px
			width auto
	&.activo
	&.listo
		.currentInput-label
			display block
			top -12px
			font-size 11px
			opacity 1
			
			animation hintLabel .5s
	&.activo
		.currentInput-label
			color darkgray
		
		input
			border-bottom-color darkgray
	&.error
		.currentInput-label
			color red
		
		input
			border-color red

	.datepicker, .rangeDate
		display inline-block !important
		vertical-align bottom !important
		width 86% !important



@keyframes hintLabel
	0%
		top 0
		opacity 0
	
	100%
		top -12px
		opacity 1
	
		
@keyframes hintLabel_hide
	0%
		top -12px
		opacity 1
		font-size 11px
	
	99%
		top 0
		opacity 0
		font-size 11px
	
	100%
		top 0
		opacity 0
		font-size 0

.ui-datepicker-year
	background transparent 
	border:none
	color white 
	font-weight 300

input:focus + label
	opacity:1
	top:0

.customSelect
	background white
	border 1px solid marine
	box-sizing border-box
	color marine
	display inline-block
	font-size 14px
	margin 0
	padding 10px 15px
	position relative
	text-align left
	vertical-align middle
	//max-width 220px
	width:100%
	border-radius 6px
	&.open
		.customSelect-label
			color gray
		
		.customSelect-arrow
			transform rotate(-180deg)
			transition all .4s
		
		.customSelect-options
		.customSelect-filter
			display block
	&.error
		border-bottom 1px solid red
		.customSelect-label
			color red
	&.disabled
		background rgba(lightgray, 0.3)
		border-color lightgray
		.customSelect-label
			color rgba(gray, 0.4)
		.customSelect-arrow
			&:after
				color rgba(gray, 0.4)
	&.middle
		width 178px
		.customSelect-label
			max-width  148px
	&.full
		width 100%
		.customSelect-label
			width  100%
	&.filter
		.customSelect-options
			margin-top:0px //53px
	.currentSelect
		position absolute
		width  0
		height 0
		overflow hidden
	
	.customSelect-arrow
		display block
		position absolute
		right 10px
		top 10px
		width  20px
		height 20px
		transition all .4s
		&:after
			content '\e902'
			font-family 'lasalle'
			color marine
			font-style normal
			font-size 24px
			position absolute
			top -5px
			right -1px

.customSelect-label
	cursor default
	display block
	padding-right 18px
	font-size 16px
	line-height 22px
	font-weight 500
	vertical-align middle
	overflow hidden

.customSelect-options
	box-shadow 0 6px 6px -3px rgba(0,0,0, 0.2)
	display none
	left 0
	max-height 230px
	//margin-top  -4px
	font-size 16px
	overflow auto
	position absolute
	top 100%
	width 100%
	z-index 3
	border-radius 6px
	border 1px solid marine
	background white
	
	>label
		color marine
		display block
		padding 8px 16px
		background white

		&:hover
			color white
			background marine
			cursor pointer
		
		&.active
			background lightmarine
		
		&.hide
			display none
		
		&.selected
			font-family 'indivisaBold'
			color white
			background rgba(lightmarine, 0.8)

.customSelect-filter
	display  none
	position absolute
	z-index  4
	top 0 //100%
	left 0
	padding 12px 10px
	width 100%
	box-sizing border-box
	background white
	box-shadow 0 6px 12px -6px rgba(0,0,0,0.3)
	border-radius 6px
	input
		border none
		padding 0

.customSelect-search
	margin 0 !important
	color darkgray !important


.customSelect-tagList
	display block
	padding 6px 0 0

.customSelect-tag
	display inline-block
	margin 0 6px 6px 0
	padding 9px 12px
	font-size 13px
	font-weight 700
	color #fff
	background darkgray
	transition background-color .4s
	&:after
		display inline-block
		content 'x'
		margin-left 8px
		width  10px
		height 9px
	&:hover
		background darkgray
		cursor pointer
		transition background-color .4s
.ui-datepicker
	background lightgray
	min-width:220px
	z-index 13 !important
	.ui-datepicker-title
		box-sizing:border-box
		background gray
		color white
		height:48px
		padding:15px 0
		text-align:center
	
	.ui-datepicker-header
		.ui-corner-all
			background black
			font-size:0
			height:48px
			position:absolute
			width 25px
			&:hover
				background darkgray
			
		
		.ui-datepicker-next
			background black
			position relative
			right 0
			&:after
				content '>'
				position absolute
				display block
				top 0
				left 0
			&:hover
				background-color darkgray
			
		
		.ui-datepicker-prev
			background black
			position relative
			right 0
			&:after
				content '<'
				position absolute
				display block
				top 0
				left 0
			&:hover
				background-color darkgray
				
	.ui-datepicker-calendar
		margin:10px auto
		width:90%
		th
			color black
			font-weight 500
		
		td
			min-width:20px
			padding:5px 0
			text-align:center
			a
				color black
				font-size:14px
				width:20px
				&:hover
					color:darkgray

//- - - - - - - - - - - - - - - - - - - - - - - -
//- BOTONES
//- - - - - - - - - - - - - - - - - - - - - - - -
.btn
	background transparent
	border-radius 6px
	border 1px solid gray
	color gray
	display inline-block
	font-size 18px
	font-family 'indivisaBold'
	font-weight 500
	padding 10px 30px 9px
	transition 1s all ease
	cursor pointer
	//max-width 270px
	box-sizing border-box
	overflow hidden
	position relative
	z-index 1
	&:after,
	&:before
		content ''
		display block
		width 0
		height 100%
		position absolute
		left 0
		top 0
		background rgba(red, 0.8)
		transition 0.3s all ease
		z-index -1
	&:before
		transition-delay 0.3s
	&:hover
		color white
		border-color red
		&:after,
		&:before
			width 100%
	&.whiteBlue
		border 1px solid white
		color white
		&:after,
		&:before
			background rgba(white, 0.8)
		&:hover
			color marine
	&.red
		border 1px solid red
		color white
		background red
		&:after,
		&:before
			background rgba(white, 0.8)
		&:hover
			color red
	&.whiteRed
		border 1px solid white
		color white
		&:after,
		&:before
			background rgba(white, 0.8)
		&:hover
			color red


//- - - - - - - - - - - - - - - - - - - - - - - -
//- GRID
//- - - - - - - - - - - - - - - - - - - - - - - -
.block
	overflow hidden
	.b-full
		stack()
	.b-half
		column(1/2)
	.b-2of3
		column(2/3)
	.b-1of3
		column(1/3)
	.b-1of4
		column(1/4)


//- - - - - - - - - - - - - - - - - - - - - - - -
//- HINT-TIP
//- - - - - - - - - - - - - - - - - - - - - - - -

.msj
	color red
	text-align left
	padding-left 20px
	display none
	margin-top 5px
	width 100%

.hintTip
	display block
	position  relative
	vertical-align  middle
	

	&.error
		+.msj,
		~.msj,
		.msj,
		.hintTip-text
			display block
		
		.customSelect
			margin-bottom 10px
			border-color red
			.customSelect-label
				color gray
			.customSelect-arrow
				&:after
					color red
			
			.arrow
				border-top-color red
		input, textarea
			border-color red
		.lbRd,
		.lbCh
			.flCh
				box-shadow 0 0 0px 2px red

	.customSelect + .hintTip-text
		margin-top -10px
	
	.hintTip-text
		background white
		border-radius 0 0 4px 4px
		border-top 1px solid red
		//border-top:0
		box-sizing border-box 
		color red
		cursor default
		display none
		margin 0 
		min-width 100%
		font-size 13px
		padding 6px 0
		text-align right
		top 100%
		position absolute
		//-white-space nowrap
		z-index 2


//- - - - - - - - - - - - - - - - - - - - - - - -
//- ICONOS
//- - - - - - - - - - - - - - - - - - - - - - - -


/*  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 *	LBOX
 *  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 */
#js
	position absolute
	z-index  200
 	

.Lbox-overlay
	display block
	position fixed
	z-index  200
	top  0
	left 0
	width  100%
	height 100%
	text-align center
	vertical-align middle
	background-color rgba( gray, 0.75 )

.Lbox-holder
	width  100%
	height 100%

.Lbox-cont
	position relative
	top 50%
	margin 0 auto
	padding 30px
	max-width 440px
	max-height 80%
	border-radius 3px
	background white
	//box-shadow 0 0 16px rgba(black, .3)
	overflow auto
	
	.btn-close
		display block
		position absolute
		top   14px
		right 14px
		width  40px
		height 40px
		cursor pointer
		&:before
			content '\e928'
			font-family 'lasalle'
			text-align center
			line-height 40px
			color marine
			display block
			font-size 36px
			transition 0.6s all ease
		&:hover
			&:before
				color red
	
	.ico-status
		display none
		margin 0 auto
		width  43px
		height 46px
		bg-sprite -538px -122px
		
		&.error
			width  73px
			height 47px
			background-position -453px -121px
		&.success
			width  43px
			height 46px
			background-position -538px -122px

.Lbox-title
	margin 24px 0 10px
	font-size 30px
	color black
	
.Lbox-desc
	background-color white
	width 60%
	margin 20px auto
	padding 10px
	

.Lbox-confirm
	margin 36px 0 0
	text-align center
	.btn
		width 39%
	.btn-accept
		margin-left 8%



/*  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 *	GLOBALES
 *  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 */
 
.hidden
	display none

.visible
	display block

.flLeft
	float left

.flRight
	float right

.clBoth
	clear both
	
.a-center
	text-align center

.a-left
	text-align left

.a-right
	text-align right


@media (max-width: 780px)
	.block
		.b-1of4
			column(1/2, $cycle:2)

@media (max-width: 560px)
	.block
		.b-full,
		.b-half,
		.b-2of3,
		.b-1of3,
		.b-1of4
			stack()
	a
		&.circled
			display block
	


