.breadWrap
	background smoke
	.container
		overflow hidden
		>ul
			padding 6px 0
			&:first-child
				span(2/3)
				padding-top 10px
			&:last-child
				span(1/3)
				text-align right
			li
				display inline-block
				font-size 14px
				margin-bottom 0
				a, span
					color gray
					font-family 'indivisaRegular'
				span
					font-family 'indivisaBold'
				a
					position relative
					display inline-block
					padding-right 10px
					&:after
						content ''
						display block
						width 0
						height 2px
						background accent-color
						bottom 0
						left 0
						position absolute
						transition 0.6s all ease
					&:hover
						color accent-color
						&:after
							width 70%
							max-width 50px
					&:before
						content '/'
						display block
						top 0
						right 0px
						position absolute
		.tools
			label
				display inline
				color gray
				margin-right 20px
			ul
				display inline-block
				li
					margin-right 10px
					&:last-child
						margin-right 0
					a
						padding-right 0
						&:before
							display none
			.fontBigger
				font-size 22px
			.fontSmaller
				font-size 18px
			a:hover
				color accent-color
				&:after
					display none

.redesShare
	position fixed
	top 50%
	transform translateY(-50%) rotate(-90deg)
	right 0px
	z-index 18
	&.topLeft
		top 170px
		right 20px
		transform rotate(0)
		transition 0.6s all ease
		a
			font-size 0
			&:hover
				span
					transform rotate(0)
		&.top
			top 20px
	a
		font-size 14px
		font-family 'indivisaBold'
		.icon
			display inline-block
			vertical-align middle
			margin-left 10px
			color white
			font-size 26px
			width 40px
			height 40px
			text-align center
			line-height 40px
			background accent-color
			border-radius 50%
			transition 0.6s all ease
		&:hover
			.icon
				background der-1
				transform rotate(90deg)

@media (max-width: tablet)
	.redesShare
		right 20px
		a
			font-size 0


@media (max-width: mobile)
	.breadWrap 
		.container 
			.tools 
				label
					display none
	.redesShare
		transform translateY(-50%) rotate(0deg)
		display none
		top 50px
		&.scrolled
			display block
		& a:hover .icon
			transform translateY(-50%) rotate(0deg)
	// 	display none