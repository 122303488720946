.plataforma-educativa
	color marine
	h1
		margin-bottom 80px
	h3
		font-size 36px
	p
		font-size 16px
		line-height 24px
		strong
			font-family indivisaBold
.sliderPlataforma,
.sliderEscuela
	margin 50px 0 80px 0
	.slick-list
		width 2500px
	.slick-slide
		width 400px
		margin 0 10px
	.cardGrupo 
		max-width 100%
		&:hover
			a.arrow
				&:before
					transform translate(10%,-65%)
	.infoGrupo
		display flex
		align-items center
		color white
		h2
			a
				font-family indivisaItalicLight
				color white
				&:before
					color white
					font-size 25px
.plataforma-sliders
	h2
		color marine
		font-size 36px
	p
		color marine
.consideraciones-tecnicas
	margin 120px 0 0 0
	.title-icon
		position relative
		margin 30px 0
		.icon
			width 50px
			height 50px
			text-align center
			line-height 50px
			font-size 32px
			color #fff
			background #d21034
			border-radius 50%
			margin-right 10px
			position absolute
			left 0
			top 50%
			transform translateY(-50%)
			z-index 1
		h5
			font-size 24px
			margin 0 0 0 70px
	ul
		padding 0 20px
		li
			list-style disc
			margin 20px 0
			line-height 21px
		strong
			color lightBlue
	.sesiones
		margin 100px 0
		.imgWithText-der
			.textBg
				max-width 550px
				padding 70px 3.5rem 70px 0px
				top 0
			.imgBg
				width 50%
				height 86%
				top 40px

	.plataformas
		position relative
		color #fff
		margin 100px 0 150px 0
		.imgBg
			left 0
			width 50%
			height 100%
			position absolute
			font-size 0
			overflow hidden
			top 40px
		.textBlue
			background marine
			width 50%
			box-sizing border-box
			padding 70px 20px 90px 20px
			position relative
			z-index 2
			margin-right 0
			margin-left auto
			top 0
			&:before
				content ''
				display block
				width 50px
				height 100%
				position absolute
				top 0
				background #001d68
				right 100%
				margin-right -1px
			&:after
				content ''
				display block
				width 600px
				height 100%
				position absolute
				top 0
				background #001d68
				left 100%
				margin-left -1px
.banner-gris
	text-align center
	color marine
	margin-bottom 50px
	.container
		background smoke
		border-radius 10px
		padding 15px 0
	ul
		li
			display inline-block
			margin 0 10px
	p
		margin 10px
		font-weight bold
		font-size 24px
		strong 
			font-family indivisaBold
	ul
		margin 10px
		padding 0
		a
			font-family indivisaRegular 
			font-weight regular
	.line-diagonal
		display block
		background red 
		border-radius 5px
		transform rotate(35deg) translate(5px,5px)
		width 2px
		height 25px
@media(max-width tablet-landscape)
	.consideraciones-tecnicas
		.plataformas
			margin 150px 0 150px 0
			.imgBg
				img
					width 100%
					height 100%
					object-fit cover
	.banner-gris
		padding 0 20px
@media(max-width tablet)
	.consideraciones-tecnicas
		.plataformas
			.imgBg
				left 0
				width 50%
				height 100%
				position absolute
				font-size 0
				overflow hidden
				top 40px
		.sesiones
			.imgWithText-der
				.imgBg
					right 0
					width 50%
					height 100%
					position absolute
					font-size 0
					overflow hidden
					top 40px
				.textBg
					margin-top 0
					width 52%
	.consideraciones-tecnicas
		.plataformas
			.imgBg
				left 0
				width 50%
				height 100%
				position absolute
				font-size 0
				overflow hidden
				top 40px
@media(max-width mobile) 
	.sliderPlataforma,
	.sliderEscuela
		.slick-slide
			width 350px
	.consideraciones-tecnicas
		margin 60px 0 0 0
		ul
			padding 0 0px 0 20px
		.sesiones
			.imgWithText-der
				.imgBg
					width auto
					top 0
					height auto
					position relative
				.textBg
					padding 40px 0
					width auto
		.plataformas
			margin 70px 0
			.imgBg
				position relative
				width 100%
				top 0
			.textBlue
				width 100%
				padding 40px 20px 40px 0
		.banner-gris
			.container
				ul
					margin 10px 0
					li
						display block
				.line-diagonal
					margin 0 auto
					transform rotate(35deg) translate(0,0)
@media(max-width mobile-landscape - 100)
	.sliderPlataforma,
	.sliderEscuela
		.slick-slide
			width 250px