/**
* Add the correct display in IE 9-.
* 1. Add the correct display in Edge, IE, and Firefox.
* 2. Add the correct display in IE.
*/
figcaption, 
figure
	display block

/**
* Add the correct margin in IE 8.
*/
figure
	margin 1em 40px

/**
* 1. Add the correct box sizing in Firefox.
* 2. Show the overflow in Edge and IE.
*/
hr
	box-sizing content-box /* 1 */
	height 0 /* 1 */
	overflow visible /* 2 */

/**
* 1. Correct the inheritance and scaling of font size in all browsers.
* 2. Correct the odd `em` font sizing in all browsers.
*/
pre
	font-family monospace, monospace /* 1 */
	font-size 1em /* 2 */

