progress
  display inline-block; /*Add the correct display in IE 9-.*/
  vertical-align baseline; /*Add the correct vertical alignment in Chrome, Firefox, and Opera*/

/**
* Add the correct display in IE 10-.
* 1. Add the correct display in IE.
*/
[hidden]
	display none

/**
* 1. Change font properties to `inherit` in all browsers (opinionated).
* 2. Remove the margin in Firefox and Safari.
*/

button, 
input, 
select, 
textarea
	font inherit; /* 1 */
	margin 0; /* 2 */

/**
* Restore the font weight unset by the previous rule.
*/

optgroup
	font-weight bold

/**
* Show the overflow in IE.
* 1. Show the overflow in Edge.
*/

button, 
input
	/* 1 */
	overflow visible

/**
* Remove the inheritance of text transform in Edge, Firefox, and IE.
* 1. Remove the inheritance of text transform in Firefox.
*/

button, 
select
	/* 1 */
	text-transform none

/**
* 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
*    controls in Android 4.
* 2. Correct the inability to style clickable types in iOS and Safari.
*/

button, 
html [type="button"], /* 1 */
[type="reset"], 
[type="submit"]
	-webkit-appearance button; /* 2 */

/**
* Remove the inner border and padding in Firefox.
*/

button::-moz-focus-inner, 
[type="button"]::-moz-focus-inner, 
[type="reset"]::-moz-focus-inner, 
[type="submit"]::-moz-focus-inner
	border-style none
	padding 0

/**
* Restore the focus styles unset by the previous rule.
*/

button:-moz-focusring, 
[type="button"]:-moz-focusring, 
[type="reset"]:-moz-focusring, 
[type="submit"]:-moz-focusring
	outline 1px dotted ButtonText

/**
* Change the border, margin, and padding in all browsers (opinionated).
*/

fieldset
	border 1px solid #c0c0c0
	margin 0 2px
	padding 0.35em 0.625em 0.75em

/**
* 1. Correct the text wrapping in Edge and IE.
* 2. Correct the color inheritance from `fieldset` elements in IE.
* 3. Remove the padding so developers are not caught out when they zero out
*    `fieldset` elements in all browsers.
*/

legend
	box-sizing border-box; /* 1 */
	color inherit; /* 2 */
	display table; /* 1 */
	max-width 100%; /* 1 */
	padding 0; /* 3 */
	white-space normal; /* 1 */

/**
* Remove the default vertical scrollbar in IE.
*/

textarea
  overflow auto

/**
* 1. Add the correct box sizing in IE 10-.
* 2. Remove the padding in IE 10-.
*/

[type="checkbox"], 
[type="radio"]
	box-sizing border-box; /* 1 */
	padding 0; /* 2 */

/**
* Correct the cursor style of increment and decrement buttons in Chrome.
*/

[type="number"]::-webkit-inner-spin-button, 
[type="number"]::-webkit-outer-spin-button
	height auto

/**
* 1. Correct the odd appearance in Chrome and Safari.
* 2. Correct the outline style in Safari.
*/

[type="search"]
	-webkit-appearance textfield; /* 1 */
	outline-offset -2px; /* 2 */

/**
* Remove the inner padding and cancel buttons in Chrome and Safari on OS X.
*/

[type="search"]::-webkit-search-cancel-button, 
[type="search"]::-webkit-search-decoration
	-webkit-appearance none

/**
* Correct the text style of placeholders in Chrome, Edge, and Safari.
*/

::-webkit-input-placeholder
	color inherit
	opacity 0.54

/**
* 1. Correct the inability to style clickable types in iOS and Safari.
* 2. Change font properties to `inherit` in Safari.
*/

::-webkit-file-upload-button
	-webkit-appearance button; /* 1 */
	font inherit; /* 2 */

