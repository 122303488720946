.subtitle-marine 
	font-family indivisaItalicLight
	font-weight regular
	font-size 60px
	line-height 60px
	letter-spacing -1px
	color marine
	padding-top 6.9rem
	position relative
	&:after 
		content ''
		width 81px
		height 2px
		background red
		position absolute
		bottom -1rem
		left 0.2rem
.nosotros 
	.main 
		padding 0
	.ofertaHome 
		.subtitle-marine 
			padding 20px 0 60px 
.history-us 
	position relative
	min-height 612px
	overflow hidden
.content-slider-left 
	display flex
	flex-wrap wrap
	width 100%
	.left-slider-slick 
		width 70.5%
		position absolute
		top 0
		left 0
		.item 
			width 100%
			height 612px 
			img 
				width 100%
				height 100%
				object-fit cover
		.slick-dots 
			width auto
			position absolute
			bottom 3.2rem
			left 8.2rem
			li 
				display inline-block
				margin-right 14px
				&.slick-active 
					button 
						width 75px
						opacity 1
				button 
					width 40px
					height 10px
					border none
					box-sizing border-box
					background transparent
					font-size 0
					cursor pointer
					border-bottom 2px solid white
					opacity 0.2
					transition 0.8s all ease
					&&:hover
						opacity 1
					&:focus 
						outline none

	.info-slider-left 
		width 60%
		background marine
		margin 5.5rem 0 0 auto
		position relative
		box-sizing border-box
		z-index 1
		&:before 
			content ''
			width 100%
			height 100%
			display block
			position absolute
			top 0
			left 100%
			background marine
			margin-left -1px
		.info 
			padding 3.2rem 4rem 4rem 5rem
			box-sizing border-box
			h1 
				font-family indivisaItalicLight
				font-weight regular
				font-size 60px
				line-height 60px
				color white
				margin 0 0 3.5rem
				position relative
				&:after 
					content ''
					width 81px
					height 2px
					background white
					position absolute
					bottom -12px
					left 4px
			p 
				font-size 16px
				line-height 24px
				color white
			a 
				color white
				margin-top .9rem
				&.btn
					border 1px solid white
				&.player 
					display block
					font-size 18px
					line-height 14px
					padding-left 32px
					span 
						font-size 28px
						position absolute
						top 50%
						left 0
						transform translate(0, -50%)
					&:after 
						display none
.our-story 
	padding 6.75rem 0 5.9rem
	display grid
	grid-template-columns 50% 50%
	.subtitle-marine 
		line-height 67px
		padding-top 0
		padding-right 10.3rem
	p 
		font-size 16px
		line-height 24px
		color marine
		padding-right 2.5rem
		margin-top 3rem
	a 
		margin-top 2rem
	.img 
		position relative
		.bg-story 
			display block
			width 438px
			height 540px
			background lightgray
			position absolute
			top -20px
			right -20px
			z-index -1
.content-presentation
	padding 10rem 0 9rem 
	position relative
	overflow hidden
	text-align center
	.subtitle-marine 
		color white
		padding 0
		&:after 
			left 9.5rem
	p 
		font-size 18px
		line-height 24px
		color white
		padding 1.7rem 6.5rem 1.5rem
	a 
		color white
		border 1px solid white
	.img 
		width 100%
		height 100%
		position absolute	
		top 0px
		left 0px
		z-index -1
		img
			width 100%
			height 100%	
			object-fit cover
		&:after 
			content ''
			width 100%
			height 100%
			background marine
			position absolute
			top 0
			left 0
			opacity .85
.facilities-component 
	.subtitle-marine 
		padding-top 5.3rem
.linkCards 
	max-width 300px
	border 1px solid lightgray
	.img 
		font-size 0
		overflow hidden
		a, .js-link 
			display block
			width 100%
			height 100%
			font-size 0
			&:after 
				display none
			&:hover 
				img 
					transform scale(1.2)
		img 
			width 100%
			height 100%
			object-fit cover
			transition .3s all ease
		img.js-link 
			&:hover 
				transform scale(1.2)
	.info 
		padding 16px 13px
	.arrow 
		font-size 18px
		&:before 
			top unset
			bottom 2px
			transform translateY(0)
	h5 
		font-family indivisaBold
		font-size 18px
		line-height 24px
		color marine
		position relative
		margin-top 0
		&:after 
			content ''
			width 43px
			height 2px
			background red
			position absolute
			bottom -10px
			left 0
	h6 
		margin 0
	p 
		font-size 14px
		line-height 20px
		color marine
	&.descriptivo 
		min-height 370px
		.info 
			padding 14px 13px
.content-card-slider 
	padding-top 4.2rem
	.card-slider 
		padding 15px 0 13px
		.slick-list 
			width 2000px
		.linkCards 
			margin 0 23px 0 0
			&:last-child 
				margin 0
	.btn-see-everything 
		text-align center
		padding-top 1.5rem
.downloadable-component 
	margin 5.5rem 0  
.content-downloadable 
	position relative 
	padding 5.5rem 0
	// overflow hidden
	&:after 
		content ''
		width 100%
		height 280px
		background smoke 
		position absolute
		top 50%
		left 0
		transform translate(0px, -50%)
		z-index -1
	.img-downloadable 
		width 41%
		height 380px
		position absolute
		top 50%
		left 0
		transform translateY(-50%)
		z-index 1
		img 
			width 100%
			height 100%
			object-fit cover
	.download-list  
		width 555px
		margin 0 0 0 auto
.content-stripe 
	position relative
	overflow hidden
	&:before 
		content ''
		width 600px
		height 100%
		display block
		position absolute
		top 0
		background marine
		left 0
		z-index -1
	&:after  
		content ''
		width 600px
		height 100%
		display block
		position absolute
		top 0
		background red
		right 0
		z-index -1
	ul 
		width 100%
		display grid
		grid-template-columns 50% 50%
		li 
			padding 25px 0
			margin 0
			a  
				font-family indivisaItalicLight 
				font-weight regular
				font-size 30px
				line-height 60px
				color white
				margin 0
				span 
					position absolute
					top 50%
					transform translate(12px, -50%)
			&:first-child 
				padding-left 5.2rem
				background marine
			&:last-child 
				padding-left 13.7rem
				background red
				a 
					&:after 
						background marine
.prizes-component  
	padding 4.5rem 0 3rem
	.subtitle-marine 
		padding-top 0
.content-list-prizes 
	margin-top 3.6rem
	ul 
		width 100%
		display flex
		flex-wrap wrap
		flex-direction row
		justify-content space-between
		li 
			width 32.2%
			margin 0
			a 
				display block
				font-size 18px
				line-height 24px
				color marine
				margin-top 10px
				span 
					font-weight bold
					color red
					position absolute
					top 50%
					transform translate(5px, -50%)
	.btn-see-everything 
		text-align center
		margin-top 50px
.headquarters 
	.subtitle-marine 
		padding-top 0
		padding-right 20rem 
.headquarters-map 
	width 100%
	margin 4rem 0
.mapSection
	margin-top 50px
	background linear-gradient(to top, rgba(white,1) 85%,rgba(white,0) 100%);
.mapUniversities
	position relative
	box-sizing border-box
	img
		display block
		margin 0 auto
	.pinesMap
		margin 0
		padding 0
		position absolute
		top 0
		left 50%
		transform translateX(-50%)
		width 100%
		max-width 734px
		height 100%
		box-sizing border-box
		li
			position absolute
			a
				font-size 36px
				color marine
				&:hover
					color accent-color
				&.active
					a
						color darkGreen
					.icon
						&:before
							content '\e94c'
				span
					display none
					&.icon
						display block
			&.pin-1
				top 15%
				left 20%
			&.pin-2
				top 20%
				left 33%
			&.pin-3
				top 28%
				left 45%
			&.pin-4
				top 36%
				left 52%
			&.pin-5
				top 45%
				left 56%
			&.pin-6
				top 40%
				left 36%
			&.pin-7
				bottom 22%
				right 49%
			&.pin-8
				bottom 30%
				right 47%
			&.pin-9
				bottom 27%
				right 40%
			&.pin-10
				bottom 20%
				right 43%
				a
					&.active
						color brandcolor
			&.pin-11
				bottom 21%
				right 40%
				a
					&.active
						color brandcolor
			&.pin-12
				bottom 19%
				margin-bottom 5px
				right 39%
				margin-right -2px
				a
					&.active
						color brandcolor
			&.pin-13
				bottom 17%
				right 36%
			&.pin-14
				bottom 9%
				right 32%
			&.pin-15
				bottom 24%
				right 2%
.list-headquarters 
	display block
	max-width 765px
	padding 62px 55px
	margin 50px auto 75px
	background white 0% 0% no-repeat padding-box
	box-shadow 0px 0px 8px #00000029
	ul 
		li 
			margin-bottom 21px
			span 
				font-size 14px
				line-height 22px
				color gray
			p 
				font-family indivisaBold
				font-size 16px
				line-height 22px
				color marine
				margin 0
	.arrow 
		font-size 18px
		line-height 26px
		color red
@media(max-width tablet-landscape) 
	.our-story 
		grid-template-columns 420px 420px
		justify-content center 
		.img 
			.bg-story 
				height 476px 
		.subtitle-marine 
			padding-right 6.3rem
	.downloadable-component 
		margin 4.5rem 0
	.content-downloadable 
		.img-downloadable 
			width 436px
		.download-list  
			width 500px
	.content-stripe 
		ul 
			text-align center
			li
				&:first-child 
					padding-left 0
					padding-right 2.5rem
				&:last-child 
					padding-left 0
@media(max-width tablet-portrait) 
	.our-story 
		grid-template-columns 360px 360px 
		.subtitle-marine 
			padding-right 2.3rem
		.img 
			.bg-story 
				height 407px
				width 100%
	.content-presentation 
		padding 8rem 0
	.content-downloadable 
		padding 0 0 4.5rem
		.download-list   
			width 100%
		.img-downloadable 
			width 516px
			position relative
			top 0
			transform translateY(0)
			display block
			margin 1rem auto 2.5rem
			img 
				object-fit cover
		&:after 
			height 400px
			top unset
			bottom -3rem
			transform translate(0, 0)
@media(max-width tablet-portrait - 90) 
	.our-story 
		grid-template-columns 100%
		row-gap 30px
		.img 
			width 436px
			display block
			margin 0 auto
			img 
				width 100%
			.bg-story 
				height 506px
		.description 
			text-align center
		.subtitle-marine,
		p  
			text-align left
@media(max-width tablet) 
	.content-slider-left 
		.left-slider-slick 
			width 100%
			order 2
			position unset
			margin-top -7rem
			.item 
				height 100%
			.slick-dots 
				left 4.2rem
		.info-slider-left 
			width 100%
			margin 1.5rem 0 0 auto
			&:before 
				display none
	.our-story 
		.img 
			margin 4rem auto
		p 
			padding-right 0
	.content-presentation 
		padding 10rem 0 2rem
	.content-downloadable 
		.img-downloadable 
			width 100%
			height auto
		&:after 
			height 430px
	.content-stripe 
		ul 
			li
				&:first-child 
					padding-right 3.5rem
		&:before,
		&:after 
			width 420px
	.content-list-prizes 
		ul 
			justify-content center
			li 
				width 300px
				margin 1rem
	.headquarters 
		.subtitle-marine 
			padding-right 5rem
	.mapUniversities
		.pinesMap
			position static
			height auto
			overflow hidden
			padding-bottom 20px
			transform none
			li
				position static
				column(1/3, $cycle:3)
				margin 0
				a
					font-size 28px
					color accent-color
					display block
					&:after
						display none
					span
						display inline-block !important
						color marine
						font-size 16px
						vertical-align middle
						&.icon
							color red
							font-size 28px
							margin-top -3px
@media(max-width mobile) 
	.subtitle-marine 
		font-size 48px
		line-height 48px
	.content-slider-left 
		.left-slider-slick 
			margin-top -2rem
			.slick-dots 
				bottom 2.2rem
		.info-slider-left 
			.info 
				padding 3.2rem 3rem 4rem 3rem 
				h1 
					font-size 50px
					line-height 50px
					&:after 
						width 61px
	.our-story 
		padding 4.75rem 0 5.9rem
		.img 
			margin 1rem auto
	.content-presentation 
		padding 4rem 0
		.subtitle-marine 
			&:after 
				left 50%
				transform translate(-50%, 0)
		p 
			padding 1.7rem 0 1.5rem
	.downloadable-component 
		margin 0
	.content-downloadable 
		padding 4.5rem 0 6.5rem
		&:after 
			height 520px
			bottom 4rem
	.headquarters 
		.subtitle-marine 
			padding-right 0
	.list-headquarters 
		padding 62px 35px
	.mapSection
		.container
			padding 0
	.mapUniversities
		.pinesMap
			li
				position static
				column(1/2, $cycle:2, $uncycle:3)
				margin 0
@media(max-width mobile-landscape) 
	.content-slider-left 
		.left-slider-slick 
			.slick-dots 
				bottom .5rem
				left 2rem
		.info-slider-left 
			.info 
				padding 3.2rem 2rem 4rem 2rem
	.our-story 
		.subtitle-marine 
			line-height 54px
		.img 
			width 360px
			.bg-story 
				height 416px
	.content-stripe 
		ul 
			li 
				align-self center
				&:first-child 
					padding-right 1.5rem
				a 
					font-size 24px
					line-height 24px
		&:before,
		&:after  
			width 280px
	.content-downloadable 
		&:after 
			height 600px
@media(max-width mobile-landscape - 100)
	.our-story
		padding 4rem 0
		.img 
			width 290px
			.bg-story 
				height 336px
	.content-downloadable 
		&:after 
			height 500px
@media(max-width mobile-portrait) 
	.our-story
		.img 
			width 250px
			.bg-story 
				height 286px
	.list-headquarters 
		padding 62px 20px
	.mapUniversities
		.pinesMap 
			li 
				a 
					span
						font-size 13px
	.linkCards 
		max-width 279px
		&.descriptivo 
			min-height auto
	.content-downloadable 
		.img-downloadable 
			margin 1rem auto 1.5rem

