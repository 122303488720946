.voluntariado
	padding 50px 0
	margin 50px 0
	border-top 1px solid lightgray
	border-bottom 1px solid lightgray
	.title
		color marine
		text-align center
		font-size 30px
		margin 0 0 30px
	.imgHeader
		position relative
		margin-bottom 80px
		.logo
			width 180px
			height 180px
			border 2px solid marine
			border-radius 50%
			overflow hidden
			position absolute
			left 50%
			transform translateX(-50%)
			bottom -30px
			background white
			box-sizing border-box
			img
				position absolute
				width 80%
				top 50%
				left 50%
				transform translate(-50%, -50%)
	.content
		width 80%
		margin 0 auto
		h3
			text-align center
			font-size 30px
			margin 30px 0
			color marine
		p
			text-align center
			line-height 30px
			color marine
			margin 30px 0
		
		h5
			text-align center
			font-size 24px
			font-family 'indivisaRegular'
			color marine
			strong
				font-family 'indivisaBold'
		

.filterCenter
	width 60%
	margin 0 auto 60px
	.field
		border 1px solid marine
		border-radius 5px
		padding-left 10px
		text-align center
		>label
			display inline-block
			vertical-align middle
			font-family 'indivisaBold'
			color marine
	.customSelect
		width 80%
		border none
		display inline-block
		vertical-align middle

@media(max-width tablet)
	.voluntariado 
		.content
			width 90%

@media(max-width mobile)
	.filterCenter
		width 100%
		.field
			padding-top 10px
			padding-left 0px
			>label
				display block
		.customSelect
			width 100%
	.voluntariado
		margin 30px 0
		padding 30px 0
		.content
			width 100%
		.imgHeader
			margin-bottom 30px
			.logo
				position relative
				top -20px
				left 0
				margin 0 auto
				display block
				transform none

