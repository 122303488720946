.videoRepoGallery
	.headVideoFilters
		form
			text-align center
		.customSelect
			min-width 180px
		h3
			font-size 60px
			line-height 60px
			margin 20px auto 0
			text-align center
			color marine
	.introVideo
		margin-top 20px
		h4
			color marine
			font-size 21px
			margin 0 0 10px
.sliderVideoThumbs
	position relative
	.slide
		padding 0 10px
		box-sizing border-box
		box-sizing border-box
	.slick-list
		width calc(100% - 80px)
		margin 0 auto
	.slick-arrow
		position absolute
		width 40px
		height 40px
		border none
		background lightgray
		font-size 0
		cursor pointer
		top 50%
		transform translateY(-50%)
		transition 0.3s all ease
		&:after
			content '\e942'
			font-family 'lasalle'
			color white
			text-align center
			position absolute
			top 0
			left 0
			width 100%
			height 100%
			line-height 40px
			font-size 20px
			color gray
			transition 0.3s all ease
		&:hover
			background marine
			&:after
				color white
		&.slick-next
			right 0
			&:after
				content '\e929'


@media (max-width mobile)
	.videoRepoGallery 
		.headVideoFilters
			.form-list
				&.inline
					li
						display block
						width 100%
						margin 0 0 20px
			.customSelect
				display block
				width 100%