.download-list 
	ul 
		li 
			display inline-block
			margin 22px 25px 22px 0
	&.evaluaciones 
		a
			&.circled
				&:hover 
					background darkmarine
@media(max-width tablet-portrait) 
	.download-list 
		ul 
			text-align center
@media(max-width mobile-landscape - 100) 
	.download-list 
		ul 
			li 
				margin 22px 0 22px 0