.awards-content 
	.tabsNav 
		width 13%
	.tabsContent 
		width 82.25%
.prizeContent 
	h2 
		font-family indivisaItalicLight
		font-weight regular
		font-size 30px
		line-height 42px
		color marine
	img 
		width 100%
		display block
		margin 30px auto 40px
.textOnly 
	h3 
		font-family indivisaItalicLight
		font-weight regular
		font-size 20px
		line-height 24px
		color marine
		margin 35px 100px 35px 0
		~ h2 
			margin 60px 0 20px
	p 
		line-height 24px
		color marine
		margin 1.5rem 0
	ul 
		padding-left 1rem
		li 
			font-size 16px
			line-height 24px
			color marine 
			padding-left 12px
			position relative
			margin-bottom 0
			&:after 
				content '' 
				width 5px
				height 5px
				background marine
				border-radius 50%
				position absolute
				top 50%
				left 0
				transform translate(0, -50%)
.list-winners
	display block
	width 100%
	overflow hidden
	h2 
		font-family indivisaItalicLight
		font-weight regular
		font-size 30px
		line-height 42px
		color marine
		margin 35px 0 20px
	ul 
		padding-left 5px
	li 
		width 47%
		font-size 16px
		line-height 24px
		color marine
		padding-left 12px
		position relative
		margin-bottom 1.5rem
		float left
		box-sizing border-box
		&:after 
			content '' 
			width 5px
			height 5px
			background marine
			border-radius 50%
			position absolute
			top 50%
			left 0
			transform translate(0, -50%)
.content-winners 
	padding-top 1px
	h2 
		font-family indivisaItalicLight
		font-weight regular
		font-size 30px
		line-height 42px
		color marine
		margin 35px 0 20px
.listWinners-img 
	padding-top 1rem
	margin-bottom 5rem
	display grid
	grid-template-columns 36% 50%
	column-gap 12%
	.item 
		&:first-child
			position relative
			height 300px
			li 
				width 300px
				display none
				padding-left 0
				position absolute
				top 50%
				left 50%
				transform translate(-50%, -50%)
				height 100%
				img 
					width 100%
					height 100%
					border-radius 50%
					object-fit cover
					margin 0 auto
				&:after 
					display none
				&:first-child  
					display block
		&:last-child 
			ul 
				padding-top 5px
			li 
				width 100%
				font-size 16px
				line-height 24px
				color marine
				padding-left 12px
				position relative
				float unset
				margin-bottom 1.5rem
				box-sizing border-box
				&:after 
					content ''
					width 5px
					height 5px
					background marine
					border-radius 50%
					position absolute
					top 50%
					left 0
					transform translate(0, -50%)
				a 
					font-family indivisaRegular
					&:after
						display none
					&:hover 
						font-family indivisaBold
						color marine
				&.active 
					a 
						font-family indivisaBold
@media(max-width tablet) 
	.textOnly 
		h3
			margin 30px 0
	.listWinners-img
		margin-bottom 2rem
		grid-template-columns 40% 50%
		column-gap 8.5%
@media(max-width mobile) 
	section
		&.awards-content 
			.tabsNav 
				width 100%
			.tabsContent 
				width 100%
	.listWinners-img 
		grid-template-columns 100%
		column-gap 0
		row-gap 35px
		.item 
			justify-self center
			&:first-child 
				width 291px
			&:last-child 
				width 65%
@media(max-width mobile-landscape) 
	.list-winners 
		padding-left 0
		li 
			width 100%
			float unset
@media(max-width mobile-landscape - 100) 
	.content-winners 
		.item 
			&:first-child 
				width 271px
			&:last-child 
				width 90%