.accreditations 
	&.main 
		padding 50px 0 0
	.lateralTabs 
		.tabsNav 
			width 11.75%
.listTabs-accreditations 
	margin-bottom 3rem
	.img-log 
		position relative
		img 
			width 138px
		h2 
			position absolute
			clip rect(0, 0, 0, 0)
		+ p 
			font-size 15px
			line-height 21px
			color gray
	p 
		line-height 24px
		color marine
		margin 0 0 49px
	a 
		font-weight bold
		font-size 18px
		line-height 24px
		color darkmarine
.smoke-background 
	background smoke
	z-index -999
.content-guides 
	padding 3rem 0 4rem
	h2 
		margin 0 0 42px
	p 
		line-height 24px
		color marine
	.img-guides 
		max-width 816px
		margin 3rem auto 4.7rem
		position relative
		z-index 1
		.bg-guides 
			display block
			width 780px
			height 301px
			background marine
			position absolute
			bottom -19px
			right -20px
			z-index -1
@media(max-width tablet-portrait)
	.content-guides 
		.img-guides 
			.bg-guides 
				width 95%
				height 86%
@media(max-width mobile)
	.accreditations 
		.lateralTabs 
			.tabsNav 
				width 100%