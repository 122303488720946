.main
	padding 50px 0
	&.noPadding
		padding 50px 0 0


.lateralTabs
	margin-top 20px
	overflow hidden
	.menuMobile
		font-family 'indivisaSerif'
		font-size 13px
		color marine
		border 2px solid medgray
		border-left-color red
		padding 10px 30px 10px 10px
		display block
		position relative
		display none
		&:after
			transition 0.6s all ease
			content '\e902'
			color red
			position absolute
			top 50%
			right 5px
			font-family 'lasalle'
			transform translateY(-50%)
			font-size 20px
	.tabsNav,
	.withTitle
		column(1/4)
		li
			display block
			margin 0
			a
				display block
				padding 7px 10px 8px
				color medgray
				font-family 'indivisaSerif'
				font-size 13px
				border-left 2px solid medgray
				&:after
					display none
				&:hover
					color marine
			&.active
				a
					color marine
					border-color red
	.tabsContent
		column(3/4)
		>ul
			>li
				display none
				&.active
					display block

.tabsByService
	.menuMobileS
		font-family 'indivisaSerif'
		font-size 16px
		color marine
		border 2px solid medgray
		border-bottom-color red
		padding 10px 30px 10px 10px
		display block
		position relative
		display none
		&:after
			transition 0.6s all ease
			content '\e902'
			color red
			position absolute
			top 50%
			right 5px
			font-family 'lasalle'
			transform translateY(-50%)
			font-size 20px
	ul
		margin 0 0 40px
		li
			display inline-block
			margin 0 55px 0 0
			a
				display block
				padding 7px 2px 2px
				color medgray
				font-family 'indivisaSerif'
				font-size 16px
				&:hover
					color marine
					&:after
						width 100%
			&.active
				a
					color marine
					border-color red
					&:after
						width 100%

@media(max-width mobile)
	.lateralTabs
		.menuMobile
			display block
			&.open
				&:after
					transform translateY(-50%) rotate(180deg)
				+ ul
					height auto
					border 2px solid medgray
					border-top none
		
		.tabsNav,
		.withTitle
			stack()
			ul
				border none
				height 0
				overflow hidden
			li
				a
					border none
					border-bottom 1px solid gray
					padding 10px
				&:last-child
					a
						border none
		.tabsContent
			stack()
			margin-top 40px
	
	.tabsByService
		.menuMobileS
			display block
			&.open
				&:after
					transform translateY(-50%) rotate(180deg)
				+ ul
					height auto
					border 2px solid medgray
					border-top none
		ul
			border none
			height 0
			overflow hidden
			li
				display block
				margin 0
				a
					border none
					border-bottom 1px solid gray
					padding 10px
					&:after
						display none
				&:last-child
					a
						border none
		