.bannerAguilas 
	position relative
	background-image radial-gradient(ellipse at 50% 50%, #000d2e 0, #000d2e 25%, #000d2e 50%, #01195a 85%, #01195a 100%)
	overflow hidden
	.container 
		position absolute
		top 50%
		left 50%
		transform translate(-50%, -50%)
	ul 
		display grid
		grid-template-columns 65% 30%
		grid-gap 5%
		li 
			color white
			align-self center
			position relative
	h1 
		font-family indivisaSerif 
		font-size 100px
		line-height 63px
		letter-spacing 2px
		text-transform uppercase
		text-align end
		float left
		padding-top 1.5rem
		margin 0
		strong 
			font-size 145px 
			line-height 150px
			text-transform uppercase
			display block
			margin-right 38px
		span 
			font-family indivisaItalicLight
			font-size 32px 
			line-height 73px
			letter-spacing 1px
			text-transform none
			display block
			margin-right 15px
	.img-banner 
		width 226px
		font-size 0
		float left
		img 
			width 100%
			height 100%
			object-fit cover
	.liveStreaming 
		position absolute
		bottom 2px
		left 0
		p 
			margin 1rem 0 .5rem
.subtitleBold 
	font-size 60px 
	line-height 70px
	color marine
	margin 0
.contentPartidos 
	margin 3.5rem 0 9.7rem
	> ul 
		width 100%
		padding-bottom 3.5rem
		display flex
		flex-wrap wrap
		justify-content center
		> li 
			align-self center
			&:first-child 
				margin-right 28px 
				margin-bottom 0
		.img 
			max-width 125px
			font-size 0
			img 
				width 100%
				height 100%
				object-fit cover
.sliderAguilas 
	padding 10px 0 0 5px
	.slick-list 
		width 2000px
	.slide 
		max-width 250px
		position relative
	.slick-slide 
		margin 0 15px
.sliderPartidos,
.sliderGrupos 
	.slick-list 
		width 2000px
	.slide 
		max-width 300px
	.slick-slide 
		margin 0 10px
.sliderGrupos 
	margin 4rem 0 4.7rem
.notaMedallas 
	width 100%
	height 100%
	position relative
	overflow hidden
	.imgNota 
		height 600px
		position relative
		top 0
		left 0
		font-size 0
		a 
			display block
			width 100%
			height 100%
			font-size 0
		img 
			width 100%
			height 100%
			object-fit cover
		&:before 
			content ''
			display block
			width 100%
			height 100%
			position absolute
			bottom 0
			background linear-gradient(to bottom, rgba(0,0,0,0) 30%, #000 135%)
	.container 
		height 100%
		position relative
	.infoNota 
		position absolute
		left 0
		bottom 16px
		padding 0 4rem 0 1.4rem
		h3 
			span 
				display block
				width 60px
				height 25px
				font-family indivisaItalicLight
				font-size 16px
				line-height 24px
				color white
				text-align center
				background lightBlue
				border-radius 18px
				margin-bottom .5rem
			a 
				font-size 30px
				line-height 36px
				color white 
				&:before 
					transform translateY(0)
.aniversarioAguilas 
	padding 6.8em 0 9rem
	width 100%
	display grid
	grid-template-columns 50% 41%
	grid-gap 9%
	.colum 
		align-self center
		margin-bottom 0
		h3 
			font-size 30px
			line-height 36px
			color marine
			padding-right 1rem
			margin-bottom 1.4rem
		.btn 
			padding 14px 51px
	.imgAniversario 
		position relative
		font-size 0
		img 
			width 100%
			height 100%
			object-fit cover
	.bg-aniversario 
		display block
		width 99%
		height 97.8%
		background marine
		position absolute
		top 28px
		left -20px
		z-index -1
.porraAguilas 
	width 100%
	text-align center
	.videoIframe 
		padding-bottom 3.5rem
	iframe 
		width 956px
		height 538px
.columsPorra
	text-align center
	display grid
	grid-template-columns 15% 42% 15%
	grid-gap 7.5%
	justify-content center
	div 
		align-self center
		margin-bottom 0
		&:last-child 
			img 
				transform scaleX(-1)
		h3 
			font-family indivisaRegular
			font-size 21px
			line-height 60px
			color marine
			margin 0
		p 
			font-family indivisaBold
			font-size 24px
			line-height 36px
			color marine
			margin .2rem 0 .5rem
		img 
			width 100%
			height 100%
			object-fit cover
.contentGrupos 
	padding 6.5rem 0 4rem
.cardGrupo 
	position relative
	max-width 295px
	margin 0 auto
	.img 
		display block
		width 100%
		height 100%
		overflow hidden
		position relative
		font-size 0
		img 
			width 100%
			height 100%
			object-fit cover
			transition 0.6s all ease
			position relative
		&:before  
			content ''
			display block
			width 100%
			height 100%
			position absolute
			bottom 0
			background linear-gradient(to bottom, rgba(0,0,0,0) 50%, #000 100%)
			z-index 1
		&:hover 
			img 
				transform scale(1.1)
	.image
		display block
		width 100%
		height 100%
		overflow hidden
		position relative
		font-size 0
		img.js-link
			cursor pointer
			width 100%
			height 100%
			object-fit cover
			transition 0.6s all ease
			position relative
		&:hover 
			img 
				transform scale(1.1)
	.liveLogo 
		width 95px
		position absolute
		top 16px
		right 5px
		z-index 3
		.icon 
			font-size 18px
			padding 4px
			border 2px solid white
		.imgVivo 
			width 75px 
			margin-left -14px
	.infoGrupo 
		color white
		position absolute
		bottom 26px
		left 25px
		padding-right 30px
		z-index 1
		&.withBg
			bottom 0
			left 0
			padding 20px 20px 26px
			width 100%
			box-sizing border-box
			background linear-gradient(to bottom, rgba(0,0,0,0) 0%, #000 100%)
		h2
			font-size 18px
			line-height 24px
			margin 0 0 0.5rem
			span 
				display block
				font-weight regular
				font-size 16px
				line-height 24px
			~ p 
				margin 0 0 .5rem
		h3 
			font-family indivisaItalicLight
			font-weight regular
			font-size 28px
			margin 0 0 .7rem 
			a 
				font-family indivisaItalicLight
		a 
			color white
			&:after 
				display none
		h5 
			font-family indivisaItalicLight
			font-size 16px
			line-height 24px
			text-align center
			padding 1px 11px
			display inline-block
			position relative
			left -2px
			border-radius 18px
			margin 0 0 .6rem
			&.blue 
				background blue
			&.green 
				background green 
			&.orange 
				background orange
			&.red 
				background red
			a 
				font-family indivisaItalicLight
		.icon 
			font-size 17px
			position relative
			top 2px
			margin-right 5px
			&.deportivo,
			&.alumno-regular  
				font-size 18px
			&.marcador 
				font-size 14px
			&.cultural,
			&.alumno-regular 
				top 3px
		// h6 
		// 	font-size 18px
		// 	line-height 24px
		// 	margin 0 0 .5rem
		// 	span 
		// 		display block
		// 		font-weight regular
		// 		font-size 16px
		// 		line-height 24px
		// 	~ p 
		// 		margin 0 0 .5rem
		p 
			font-size 18px
			margin 0
		.arrow 
			display inline-block
			color white
	&.center  
		position unset
		.infoGrupo 
			width 100%
			left unset
			padding-right 0
			h5 
				left 50%
				transform translateX(-50%)
.seeEverything
	text-align center
	.btn 
		padding 13.5px 54px
.horizontalTabs 
	.menuMobile 
		font-family indivisaSerif
		font-size 16px
		color marine
		border 2px solid medgray
		border-left-color red
		padding 10px 30px 10px 10px
		position relative
		cursor pointer
		display none
		&:after 
			content '\e902'
			font-family 'lasalle'
			font-size 20px
			color red
			position absolute
			top 50%
			right 5px
			transform translateY(-50%)
			transition 0.6s all ease
	.tabsOptions 
		> ul 
			display flex 
			flex-wrap wrap
			> li 
				padding 16px 45px 16px 0
				margin 0 
				&:last-child 
					margin-right 0
				a 
					font-family indivisaSerif
					font-size 16px
					// line-height 45px
					color medgray
					&:hover 
						color marine
				&.active 
					a 
						color marine
						&:after 
							width 60%
	.tabsContent 
		margin 2rem 0 4rem
		// > ul 
		// 	> li 
		// 		display none
		// 		&.active 
		// 			display block
.listGrupos 
	display grid
	grid-template-columns repeat(3, 1fr)
	grid-gap 1.55rem 3%
	li 
		margin 0
.descriptionGroup 
	margin 6.5rem 0 4rem
	.socialMedia-list 
		margin 2.7rem 0 1.5rem
	h2 
		font-size 24px
		line-height 21px
		margin-bottom 20px
		color darkmarine
	p 
		line-height 21px
		color darkmarine
.addCalendar 
	margin 1.3rem 0
	ul 
		width 100%
		display flex
		flex-wrap wrap
		li 
			border-right 1px solid darkmarine
			align-self center
			margin-bottom 0
			&:first-child 
				border 0
				margin-right 8px
			&:last-child 
				border 0
		p 
			margin 0
		a 
			font-family indivisaRegular
			line-height 14px
			color darkmarine
			border-bottom 1px solid darkmarine
			margin 0 5.5px
			&:after 
				display none
.contentMoreInfo 
	position relative
	margin 6.5rem 0
	overflow hidden
	.moreInfoGroup 
		max-width 100%
		width 58.7%
		background marine
		color white
		position relative
		padding 59px 0px 55px 0px
		margin 0 auto 0 0
		box-sizing border-box
		z-index 1
		h2 
			font-size 24px
			line-height 30px
			margin-bottom 50px
		p 
			line-height 21px
			margin 0
			strong 
				display block
				font-family indivisaBold
		a 
			font-family indivisaRegular
			color white
		ul 
			li 
				margin-bottom 20px
				&:last-child 
					p 
						strong 
							margin-bottom 22px
		&:before 
			content ''
			display block
			width 100%
			height 100%
			background marine
			position absolute
			top 0
			right 100%
			margin-right -1px
	.imgMoreInfo 
		width 46%
		height 360px
		position absolute
		right 0
		top 0
		font-size 0
		img 
			width 100%
			height 100%
			object-fit cover
	&.left
		margin 40px 0 100px
		.imgMoreInfo
			left 0
		.moreInfoGroup
			margin-right 0
			margin-left auto
			padding-left 100px
			&:before
				right unset
				left 100%

.contentClasificaciones 
	margin 4.5rem 0 .5rem
	h2 
		font-size 30px
		line-height 45px
		color marine
		margin 0 0 1rem
	.tabsContent 
		margin 1.5rem 0 3.5rem
	.listCards-info 
		padding 0

@media(max-width tablet-landscape) 
	.porraAguilas 
		.videoIframe 
			width 650px
			margin 0 auto
		iframe 
			width 650px
			height 365px
@media(max-width tablet-portrait) 
	.bannerAguilas 
		ul 
			grid-template-columns 70% 30%
			grid-gap 0
		h1 
			font-size 80px
			line-height 53px
			strong 
				font-size 125px
				line-height 130px
				margin-right 34px
			span 
				font-size 25px
				line-height 60px
				margin-right 0
		.img-banner 
			width 200px
	.sliderAguilas 
		.slide 
			max-width 200px
	.aniversarioAguilas 
		.colum 
			h3 
				padding-right 0
@media(max-width tablet) 
	.bannerAguilas 
		.container 
			position relative
			top 0
			transform translate(-50%, 0)
		ul 
			position relative
			grid-template-columns 100%
		h1 
			padding-top 3.5rem
		.img-banner 
			width 226px
			position absolute
			top 2rem
			right 0
			z-index -1
		.liveStreaming 
			position relative
			bottom -20px
			margin-bottom 2rem
	.contentPartidos 
		margin 3.5rem 0 7.7rem
	.notaMedallas 
		.imgNota 
			height 470px
		.infoNota 
			padding 0 1rem 0 1.4rem
	.aniversarioAguilas 
		grid-template-columns 100%
		.colum 
			justify-self center
			text-align center
		.imgAniversario 
			width 475px
	.porraAguilas 
		.videoIframe 
			width 520px
		iframe 
			width 520px
			height 295px
	.columsPorra 
		grid-template-columns 15% 55% 15%
		grid-gap 6.5%
	.contentMoreInfo 
		background marine
		.imgMoreInfo 
			width 100%
			height auto
			position relative
		.moreInfoGroup 
			width 100%
			padding 50px 0
			&:before 
				display none
	.cardGrupo 
		.infoGrupo 
			padding-right 10px
@media(max-width mobile) 
	.bannerAguilas 
		h1 
			font-size 70px
			line-height 48px
			strong 
				font-size 105px
				line-height 110px
				margin-right 29px
			span 
				font-size 22px
		.img-banner 
			width 200px
			right -15px
	.subtitleBold 
		font-size 40px
		line-height 46px
	.horizontalTabs 
		.menuMobile 
			display block 
			&.open 
				&:after 
					transform translateY(-50%) rotate(180deg)
			&.open 
				+ ul 
					height auto
					border 2px solid medgray
					border-top none
		.tabsOptions 
			> ul 
				display block
				border none
				height 0
				overflow hidden
				> li 
					display block
					padding 0 
					a 
						display block
						padding 12px 10px
						border-bottom 1px solid gray
						&:after 
							display none
					&:last-child 
						a 
							border none
	.listGrupos 
		grid-template-columns 100%
		grid-gap 1.55rem
	.aniversarioAguilas  
		.imgAniversario 
			width 100%
	.porraAguilas 
		margin-bottom 1rem
		.videoIframe  
			width 435px
		iframe 
			width 435px
			height 245px
	.columsPorra 
		grid-template-columns 100%
		div 
			justify-self center
			img 
				width 138px
	.addCalendar 
		ul 
			li
				margin-bottom 12px
	.contentMoreInfo
		&.left 
			.moreInfoGroup
				padding-left 0
	.notaMedallas 
		.infoNota 
			h3 
				a 
					&:before 
						transform translateY(50%)
@media(max-width mobile-landscape) 
	.porraAguilas 
		.videoIframe 
			width 335px
		iframe 
			width 335px
			height 190px
@media(max-width mobile-landscape - 9) 
	.contentPartidos 
		> ul 
			> li
				&:first-child 
					margin-right 0
					margin-bottom 15px
	.notaMedallas 
		.imgNota 
			height 420px
@media(max-width 381px) 
	.porraAguilas 
		.videoIframe 
			width 275px
		iframe 
			width 275px
			height 155px
@media(max-width mobile-portrait) 
	.bannerAguilas 
		.img-banner 
			width 120px
	.sliderPartidos,
	.sliderGrupos 
		.slide 
			max-width 280px
	.notaMedallas 
		.infoNota 
			h3 
				a 
					&:before 
						transform translateY(150%)