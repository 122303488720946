.studentGroups-detail 
	.info-topic 
		h3 
			color #061250
.facultyDetail 
	.info-topic 
		padding-right 1rem
		margin 5.5rem 0 1.5rem
.containerConsejo 
	ul 
		padding 2rem 0 0
		display grid
		grid-template-columns 32% 66%
		grid-gap 2%
		li 
			align-self center
			margin-bottom 0
		.img 
			max-width 291px
			margin-top 1.1rem
			border 1px solid lightgray
			img
				width 100%
				height 100%
				object-fit cover
		h4 
			font-size 36px
			line-height 30px
			color marine
			margin 0 0 25px
		p 
			line-height 21px
			color marine
			margin-bottom 1.2rem
		.arrow 
			font-size 18px
			line-height 21px
			margin 1rem 0
.listSocialMedia 
	ul 
		padding 0
		grid-template-columns 38% 40%
		grid-gap 0
	p 
		font-family indivisaBold
		font-size 24px
		margin-top 2.2rem
	a 
		font-size 20px
		line-height 24px
		display inline-block
		vertical-align middle
	.icon 
		font-size 26px
		color white
		background marine
		padding 1px
		border-radius 50%
		position relative
		display inline-block
		vertical-align middle
		margin-right 9px
		&.fb 
			width 24px
			height 24px
			&:before 
				position absolute
				bottom -4px
				left 50%
				transform translateX(-50%)
.container-linkCards 
	margin 6.55rem 0 7.3rem
	.subtitle 
		padding-right 25rem
		margin 0 0 110px
	ul 
		display grid
		grid-template-columns repeat(3, 1fr)
		grid-gap 2.4rem 2.4%
		li 
			margin-bottom 0
	.linkCards 
		.info 
			min-height 65px
			text-align center
			padding 25px 15px 15px
		.arrow 
			font-size 20px
			line-height 24px
			&:after 
				display none
.targetTitle 
	font-size 24px
	line-height 30px
	color marine
.listEach-static 
	margin 4.2rem 0 5.2rem
	display grid
	grid-template-columns repeat(4, 1fr)
	grid-gap 2rem 10px
	.cardPeople 
		margin 0 24px
		.text 
			display block
.cardStudent 
	max-width 150px
	a, .js-link 
		display block
		width 100%
		height 100%
		font-size 0
		transition 0.3s all ease
		&:after 
			display none
		img 
			width 100%
			height 100%
			object-fit cover
		&:hover
			transform scale(1.1)
			box-shadow 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) 
.listStudent-groups 
	margin 1rem 0 .7rem
	display grid
	grid-template-columns repeat(4, 1fr)
	grid-gap 4.5rem 25px
	li 
		margin-bottom 0
		justify-self center
.flatText 
	padding 2rem 0
	li 
		&:before 
			content ''
			display inline-block
			width 4px
			height 4px
			background darkmarine
			border-radius 50%
			margin 0 5px 3px 0
.descriptionImage-full 
	position relative
	margin 1.5rem 0 4rem
	.img 
		width 50%
		height 346px
		position absolute
		top 0
		right 0
		font-size 0
		img 
			width 100%
			height 100%
			object-fit cover
	.info 
		width 50%
		height 346px
		position relative
		background marine
		box-sizing border-box
		color white
		padding 90px 20px 40px 0
		margin 0 auto 0 0
		z-index 1
		&:before 
			content ''
			display block
			width 600px
			height 100%
			background marine
			position absolute
			top 0
			right 100%
			margin-right -1px
		h4 
			font-size 24px
			line-height 30px
			margin 0
		h5 
			font-family indivisaBold
			font-size 16px
			line-height 21px
			margin 1.15rem 0
		p 
			line-height 21px
			margin 1.15rem 0
			strong 
				font-family indivisaBold
		ul 
			li 
				line-height 21px
				margin-bottom 0	
				a 
					font-family indivisaRegular
					font-size 16px
					color white
.groupDescription 
	color marine
	padding 45px 0
	margin 11rem 0 7.5rem
	h3 
		font-size 21px
		line-height 36px
		margin 0
	h4 
		font-size 16px
		line-height 24px
	ul 
		li 
			line-height 24px
			margin-bottom 0
			&:before 
				content ''
				display inline-block
				width 4px
				height 4px
				background marine
				border-radius 50%
				margin 0 5px 3px 0
.bannerGroup 
	width 100%
	height 100%
	position relative
	.container 
		margin-bottom 6rem
	.img 
		max-width 100%
		height 260px
		position absolute
		top 0
		left 0
		font-size 0
		overflow hidden
		img 
			width 100%
			height 100%
			object-fit cover
		&:before 
			content ''
			display block
			width 100%
			height 100%
			background-color rgba(9,25,65,0.85)
			position absolute
			top 0
	h1 
		color white
		margin 40px 0 60px
	#formGroups 
		width 65%
		margin 0 auto 55px
	.customSelect-label 
		font-family indivisaBold
	.customSelect 
		background transparent
		border 1px solid white
		color white
		.customSelect-arrow 
			&:after 
				color white
.infoGroup-student 
	color marine
	text-align center
	margin 5.6rem 0 5.8rem
	.img 
		max-width 180px
		margin 0 auto .5rem 
		img 
			width 100%
			height 100%
			object-fit cover
	h2 
		font-size 36px
		line-height 45px
	p 
		font-size 18px
		line-height 28px
		margin 0 0 1.7rem
	ul 
		display grid
		grid-template-columns repeat(2, 1fr)
		li 
			font-size 24px
			line-height 24px
			padding 0 20px 0 0
			justify-self right
			&:last-child 
				border-left 2px solid red
				padding 0 0 0 20px
				justify-self left
.floatingSlider-right 
	position relative
	.imgBg 
		width 55.5%
		height 400px
		position absolute
		top 43px
		left 0
		img 
			width 100%
			height 100%
			object-fit cover
	.info 
		width 58%
		height 400px
		color white
		background marine 
		padding 115px 10px 100px 79px
		margin 0 0 0 auto
		box-sizing border-box
		position relative
		&:before 
			content ''
			display block
			width 600px
			height 100%
			background marine
			position absolute
			top 0
			left 100%
			margin-left -1px
		h3 
			font-size 21px
			line-height 36px
			margin 0
		p 
			line-height 24px

@media(max-width tablet-portrait) 
	.listEach-static 
		grid-template-columns repeat(3, 1fr)
	.facultyDetail 
		.info-topic 
			padding-right 0
@media(max-width tablet) 
	.container-linkCards 
		ul 
			grid-template-columns repeat(2, 1fr)
		.subtitle 
			padding-right 11rem
	.listSocialMedia 
		ul 
			grid-template-columns 55% 45%
	.listStudent-groups 
		grid-template-columns repeat(3, 1fr)
	.listEach-static 
		grid-template-columns repeat(2, 1fr)
	.imagesWithText
		&.titleOnly 
			.textBlue 
				top 0
				margin-top -185px
	.bannerGroup 
		#formGroups 
			width 90%
	.floatingSlider-right 
		.imgBg 
			width 100%
			height auto
			position relative
			top 0
		.info 
			width 75%
			margin-top -250px

@media(max-width mobile) 
	.containerConsejo 
		ul 
			grid-template-columns 100%
			grid-gap 2.5rem
			li 
				&:first-child 
					justify-self center
	.listSocialMedia 
		ul 
			grid-template-columns 100%
			grid-gap 1.5rem
			li 
				&:first-child 
					justify-self left
	.container-linkCards 
		ul 
			grid-template-columns 100%
			grid-gap 2.4rem
			li 
				justify-self center
	.listEach-static 
		grid-template-columns 100%
		grid-gap 2.4rem
	.imagesWithText
		&.titleOnly 
			.textBlue 
				padding 40px 121px 70px 0
				margin-top 0
				h1 
					font-size 30px
					line-height 40px
	.descriptionImage-full 
		background marine
		.img 
			width 100%
			height auto
			position relative
		.info 
			width 100%
			height auto
			padding 75px 0
			&:before 
				display none
	.floatingSlider-right 
		background marine
		.info 
			width 100%
			height auto
			padding 60px 0
			margin-top 0
			&:before 
				display none
	.groupDescription 
		margin 6rem 0
	.studentGroups-detail 
		.fichaTecnica 
			margin 2.65rem 0 6.5rem
	.infoGroup-student 
		h2 
			font-size 28px
			line-height 38px
@media(max-width mobile-landscape) 
	.listStudent-groups 
		grid-template-columns repeat(2, 1fr) 
	.container-linkCards 
		.subtitle 
			padding-right 0
			margin 0 0 80px
	.imagesWithText
		&.titleOnly 
			.textBlue 
				padding 40px 40px 70px 0
	.bannerGroup 
		#formGroups 
			width 100%
@media(max-width mobile-portrait) 
	.listStudent-groups 
		grid-template-columns 100%
		grid-gap 3rem
	.imagesWithText
		&.titleOnly 
			.textBlue 
				padding 40px 0 70px
	.bannerGroup 
		#formGroups 
			.customSelect-label 
				font-size 15px
	.infoGroup-student 
		ul 
			grid-template-columns 100%
			li 
				padding 0
				justify-self center
				&:last-child 
					border-left 0
					padding 0
					justify-self center